// Assets
import { Assets } from '../../../../../assets';

// Libraries
import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format';
import { Table } from 'antd';

// Styles
import './headquarters-expenses.component.scss';

const HeadquartersExpensesComponent = (props) => {

  const {
    headquarters
  } = props;

  const INITIAL_STATE = {
    total: 0
  }

  const [state, setState] = useState(INITIAL_STATE)

  const {
    total
  } = state

  useEffect(() => {
    const totalGeneral = headquarters?.reduce((acc, category) => acc + category.price, 0);
    setState((prevState) => ({
      ...prevState,
      total: totalGeneral
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headquarters])

  const uniqueCategories = [...new Set(headquarters.map(item => item.category))]; 
  const categoryFilters = uniqueCategories.map(category => ({
    text: category,
    value: category
  }));

  const uniqueSubCategories = [...new Set(headquarters.map(item => item.subCategory))]; 
  const subCategoryFilters = uniqueSubCategories.map(subCategory => ({
    text: subCategory,
    value: subCategory
  }));

  const uniqueConcept = [...new Set(headquarters.map(item => item.concept))]; 
  const conceptFilters = uniqueConcept.map(concept => ({
    text: concept,
    value: concept
  }));
  
  const columns = [
    {
      title: 'Categoría',
      dataIndex: 'category',
      with: '20%',
      filters: categoryFilters,
      onFilter: (value, record) => {
        return record.category === value;
      },
      render: (category) => (
        <span className='headquarters-expenses__content-tables__content-info__description'>
          {category}
        </span>
      )
    },
    {
      title: 'Sub-categoría',
      dataIndex: 'subCategory',
      with: '20%',
      filters: subCategoryFilters,
      onFilter: (value, record) => record.subCategory === value,
      render: (subCategory) => (
        <span className='headquarters-expenses__content-tables__content-info__description'>
          {subCategory}
        </span>
      )
    },
    {
      title: 'Concepto',
      dataIndex: 'concept',
      with: '20%',
      filters: conceptFilters,
      onFilter: (value, record) => record.concept === value,
      render: (concept) => (
        <span className='headquarters-expenses__content-tables__content-info__description'>
          {concept}
        </span>
      )
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      with: '20%',
      render: (date) => (
        <span className='headquarters-expenses__content-tables__content-info__description'>
          {date}
        </span>
      )
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      with: '20%',
      render: (price) => (
        <span className='headquarters-expenses__content-tables__content-info__description'>
            <NumericFormat
              disabled
              type='text'
              prefix={"$ "}
              placeholder="$ 0"
              thousandSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              className='input-price'
              thousandsGroupStyle="thousand"
              value={price}
            />
        </span>
      )
    }
  ];

  const onChange = (pagination, filters) => {
    const filteredData = headquarters.filter(item => 
      (!filters.category || filters.category.includes(item.category)) &&
      (!filters.subCategory || filters.subCategory.includes(item.subCategory)) &&
      (!filters.concept || filters.concept.includes(item.concept))
    );
    const totalSum = filteredData.reduce((acc, item) => acc + item.price, 0);
    setState((prevState) => ({
      ...prevState,
      total: totalSum
    }))
  };

  return (
    <div className="headquarters-expenses__content-tables__table">
      <div className="grid-x align-middle align-right headquarters-expenses__content-tables__content-values">
        <div className="grid-x align-middle">
          <span className="headquarters-expenses__content-tables__title">
            Total:
          </span>
          <div className="grid-x headquarters-expenses__content-tables__values--mod">
            <div className="headquarters-expenses__content-tables__values__content-icon">
              <img
                alt="icon_egress"
                src={Assets.SharedIcons.icon_egress}
                className="headquarters-expenses__content-tables__values__content-icon__icon"
              />
            </div>
            <NumericFormat
              disabled
              value={total}
              type="text"
              prefix={"$ "}
              placeholder="$ 0"
              thousandSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              className="input-price"
              thousandsGroupStyle="thousand"
            />
          </div>
        </div>
      </div>
      <div className="headquarters-expenses__content-tables__content-info">
        <div className="headquarters-expenses__content-tables__content-info__content-scroll">
          <Table
            columns={columns}
            dataSource={headquarters}
            pagination={false}
            tableLayout='fixed'
            onChange={onChange}
            className='headquarters-expenses__table-content'
          />
        </div>
      </div>
    </div>
  );
}

export default HeadquartersExpensesComponent