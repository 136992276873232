export const validateCreationOfBanks = (values) => {
    const errors = {};
    if (!values.bankName) {
        errors.bankName = 'Este campos es obligatorio';
    }
    if (!values.typeAccount) {
        errors.typeAccount = 'Este campos es obligatorio';
    }
    if (!values.numberAccount) {
        errors.numberAccount = 'Este campos es obligatorio';
    }else if (!/^\d+$/.test(values.numberAccount)) {
        errors.numberAccount = 'Solo debe contener números';
    }    
    if (!values.identificationCode) {
        errors.identificationCode = 'Este campos es obligatorio';
    }else if (!/^\d+$/.test(values.identificationCode)) {
        errors.identificationCode = 'Solo debe contener números';
    } 
    if (!values.associatedOperation) {
        errors.associatedOperation = 'Este campos es obligatorio';
    }
    if (!values.initialBalance && values.initialBalance !== 0) {
        errors.initialBalance = 'Este campos es obligatorio';
    }
    if (!values.dateOfCreation) {
        errors.dateOfCreation = 'Este campos es obligatorio';
    }
    return errors;
  };