// Actions
import { setStateAdminHeadquarterReducer } from "../../../storage/reducers/admin-headquarter/admin-headquarter.actions";

// Assets
import { Assets } from "../../../assets";

// Components - shared
import ErrorAlertComponent from "../../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../../shared/components/alerts/success-alert/success-alert.component";
import InformationAlertComponent from "../../../shared/components/alerts/information-alert/information-alert.component";

// Components
import PreviewHeadquarterComponent from "../admin-panel/components/business-headquarters/components/preview-headquarter/preview-headquarter.component";

// Libraries
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Input, Select, Space, Tag, Tooltip, } from "antd";
import { NumericFormat } from "react-number-format";
import { useFormik } from "formik";
import { connect } from "react-redux";

// Services
import { getUserAvailableHeadquarterService } from '../../../services/shared.services';
import { createHeadquarterService, 
    getHeadquarterByIdService, 
    getListManagerHeadquarterService, 
    updateHeadquarterService, 
    updateStoreHeadquarterService, 
    updateUserHeadquarterService 
} from "../../../services/administration-panel.services";

// Styles
import "./headquarter-create-edit.page.scss"

// Utils
import { headquarterSchema } from "../../../utils/headquarters-creation.utils";

const HeadquarterCreateEditPage = (props) => {

  const navigate = useNavigate();
  let { state: { idHeadquarter, titleHeadquarter } } = useLocation();

  const  {
    // Actions
    setStateAdminHeadquarterReducer
  } = props;

  const { values, errors, setFieldValue, handleBlur, touched, setValues, setTouched } = useFormik({
    initialValues: {
      name: "",
      initialBalance: null,
      address: "",
      store: [],
      userChargeHeadQuarter: null,
      managersSelected: [],
    },
    initialErrors: {
      name: "",
      initialBalance: "",
      address: "",
      store: "",
      userChargeHeadQuarter: "",
      managersSelected: ""
    },
    validationSchema: headquarterSchema,
    onSubmit: () => { },
  });

  const INITIAL_STATE = {
    listUserAvailable: [],
    headquarterDataOriginal: {},
    nameHeadquarter: '',
    listOfManagers: []
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { listUserAvailable, headquarterDataOriginal, nameHeadquarter, listOfManagers } = state;

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    if (titleHeadquarter) {
      setState((prevState) => ({
        ...prevState,
        nameHeadquarter: titleHeadquarter
      }));
    }
  }, [titleHeadquarter]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  useEffect(() => {
    getListUsersManagersHeadquarter();
    getUserAvailableHeadquarter(idHeadquarter);
    return () => {
      setStateAdminHeadquarterReducer("adminHeadquarterSelected", undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListUsersManagersHeadquarter = async () => {
    try {
      const listOfManagers = await getListManagerHeadquarterService();
      setState((prevState) => ({
        ...prevState,
        listOfManagers
      }));
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const getUserAvailableHeadquarter = async (idHeadquarter) => {
    try {
      const listUserAvailable = await getUserAvailableHeadquarterService();
      setState((prevState) => ({
        ...prevState,
        listUserAvailable
      }));
      if (idHeadquarter) {
        getDataAdminHeadquarter(listUserAvailable, idHeadquarter);
      }
    } catch {
      ErrorAlertComponent()
    }
  };

  const getDataAdminHeadquarter = async(listUserAvailable, idHeadquarter) => {
    try {
      const getInfoHeadquarter = await getHeadquarterByIdService(idHeadquarter)
      setStateAdminHeadquarterReducer("adminHeadquarterSelected", getInfoHeadquarter)
      setState((prevState) => ({
        ...prevState,
        nameHeadquarter: getInfoHeadquarter?.name
      }));
      const userCharge = getInfoHeadquarter?.userChargeHeadQuarter || null;
      const deposits = getInfoHeadquarter?.store.map(item => item.code);
      const userChargeHeadQuarter = { value: userCharge?.id, label: userCharge?.name };
      const managersSelected = getInfoHeadquarter.responsible.map(item => ({ value: item.idUser }));

      setState((prevState) => ({
        ...prevState,
        listUserAvailable: [...listUserAvailable, userChargeHeadQuarter],
      }));

      setTouched({
        ...touched,
        name: !!getInfoHeadquarter?.name,
        initialBalance: !!getInfoHeadquarter?.initialBalance,
        address: !!getInfoHeadquarter?.address,
        store: !!deposits,
        userChargeHeadQuarter: !!userChargeHeadQuarter,
        managersSelected: !!managersSelected
      })
      setValues({
        ...values,
        name: getInfoHeadquarter?.name || '',
        initialBalance: Number(getInfoHeadquarter?.initialBalance) || undefined,
        address: getInfoHeadquarter?.address || '',
        store: deposits || [],
        userChargeHeadQuarter: userChargeHeadQuarter || null,
        id: getInfoHeadquarter?.id || '',
        accounts: getInfoHeadquarter?.accounts || [],
        managersSelected: managersSelected || [],
      });
      setState((prevState) => ({
        ...prevState,
        headquarterDataOriginal: {
          name: getInfoHeadquarter?.name || '',
          initialBalance: getInfoHeadquarter?.initialBalance || undefined,
          address: getInfoHeadquarter?.address || '',
          store: deposits || [],
          userChargeHeadQuarter: userChargeHeadQuarter || null,
          id: getInfoHeadquarter?.id || '',
          managersSelected: managersSelected || [],
        }
      }));

    } catch {
      ErrorAlertComponent()
    }
  }

  const onChangeField = (target, data) => {
    let value = data?.target ? data.target.value || '' : data;
    if (target === "initialBalance") {
      value = value.floatValue || null
    } 
    setFieldValue(target, value)
  }

  const handleClose = (removedTag) => {
    const newDeposits = values?.store.filter((tag) => tag !== removedTag);
    setFieldValue('store', newDeposits);
  };

  const handleInputConfirm = (value) => {
    if (inputValue && !values?.store.includes(inputValue)) {
      setFieldValue('store', [...values.store, inputValue]);
    }
    if(!inputValue){
      handleBlur('store')(value)
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputConfirm = () => {
    const newTags = [...(values?.store || [])];
    if (editInputIndex !== null && editInputIndex < newTags.length) {
      const editedValue = editInputValue.trim();

      if (editedValue && !newTags.includes(editedValue)) {
        newTags[editInputIndex] = editedValue;
        setFieldValue('store', newTags);
      } 
    }
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const createHeadquarter = async () => {
    try {
      let data = {
        name: values?.name,
        address: values?.address,
      }
      if (values?.initialBalance) {
        data.initialBalance = values?.initialBalance?.toString();
      };

      if (values?.managersSelected) {
        data.responsible = values?.managersSelected.map(manager => manager.value);
      };

      const createdHeadquarterId = await createHeadquarterService(data);
      await updateUserHeadquarterService({ idHeadquarter: createdHeadquarterId, idUser: values?.userChargeHeadQuarter?.value });
      await updateStoreHeadquarterService({ idHeadquarter: createdHeadquarterId, store: values?.store });
      SuccessAlertComponent(undefined, 'Sede creada exitosamente!');
      getUserAvailableHeadquarter(createdHeadquarterId)
    } catch {
      ErrorAlertComponent();
    }
  };

const findChanges = (object1, object2) => {
  return Object.keys(object2).reduce((changes, key) => {
    if (object1.hasOwnProperty(key) && object1[key] !== object2[key]) {
      changes[key] = object2[key];
    }
    return changes;
  }, {});
}

const getModifiedStoreItems = (originalStore, updatedStore) => {
  const eliminatedItems = originalStore.filter(item => !updatedStore.includes(item));
  const addedItems = updatedStore.filter(item => !originalStore.includes(item));
  return [...eliminatedItems, ...addedItems];
};

const buildHeadquarterData = (data) => ({
  name: data?.name,
  address: data?.address,
  initialBalance: data?.initialBalance?.toString() || "0",
  responsible: data?.managersSelected.map(manager => manager.value)
});

const buildUserChargeData = (data) => ({
  userChargeHeadQuarter: data?.userChargeHeadQuarter,
});

const updateHeadquarter = async () => {
  const storeHeadquarters = getModifiedStoreItems(headquarterDataOriginal.store, values.store);
  
  const dataUpdateHeadquarter = buildHeadquarterData(values);
  const dataOriginalHeadquarter = buildHeadquarterData(headquarterDataOriginal);

  const dataUpdateUserCharge = buildUserChargeData(values);
  const dataOriginalUserCharge = buildUserChargeData(headquarterDataOriginal);

  const updateDataHeadquarter = findChanges(dataOriginalHeadquarter, dataUpdateHeadquarter);
  const updateDataUserCharge = findChanges(dataOriginalUserCharge, dataUpdateUserCharge);

  if (Object.keys(updateDataHeadquarter).length > 0 || Object.keys(updateDataUserCharge).length > 0 || storeHeadquarters.length) {
    try {
      if (Object.keys(updateDataHeadquarter).length > 0) {
        await updateHeadquarterService({ ...updateDataHeadquarter, idHeadquarter: values?.id });
      }
      if (Object.keys(updateDataUserCharge).length > 0) {
        await updateUserHeadquarterService({ idHeadquarter: values?.id, idUser: values?.userChargeHeadQuarter?.value });
      }
      if (storeHeadquarters.length) {
        await updateStoreHeadquarterService({ idHeadquarter: values?.id, store: storeHeadquarters });
      }
      SuccessAlertComponent(undefined, 'Sede actualizada exitosamente!');
      getUserAvailableHeadquarter(values?.id);
    } catch {
      ErrorAlertComponent();
    }
  } else {
    InformationAlertComponent();
  }
};

  return (
    <div className="headquarter-create-edit__content">
      <div className="grid-x align-middle">
        <Link
          replace={true}
          to="/adminPanel/1"
          className="banks-detail__title"
        >
          Sedes o licoreras
        </Link>
        <img
          alt="icon_arrow"
          src={Assets.SharedIcons.icon_arrow}
          className="banks-detail__icon-arrow"
        />
        <span className="banks-detail__subtitle">{nameHeadquarter}</span>
      </div>
      <div className="grid-x headquarter-create-edit__box">
        <div className="small-4">
          <PreviewHeadquarterComponent />
        </div>
        <div className="small-7 headquarter-create-edit__data-headquarter justify-content-between">
          <div className="grid-x">
            <label className="small-12 headquarter-create-edit__title">Detalles de la sede</label>
            <div className="grid-x small-12 justify-content-between">
              <div className='headquarter-create-edit__data-headquarter__content headquarter-create-edit__data-headquarter__content--mod'>
                <span className='headquarter-create-edit__data-headquarter__label'>
                  Nombre de la sede <span style={{ color: '#F85F61' }}>*</span>
                </span>
                <Input
                  value={values.name}
                  placeholder='Escribe el nombre de la sede'
                  onBlur={(value) => handleBlur('name')(value)}
                  onChange={(value) => onChangeField("name", value)}
                  className='headquarter-create-edit__data-headquarter__input'
                  status={errors.name && touched.name ? 'error' : ''}
                />
                <span className="headquarter-create-edit__data-headquarter__error">{touched.name ? errors.name : null}</span>
              </div>
              <div className='headquarter-create-edit__data-headquarter__content headquarter-create-edit__data-headquarter__content--mod'>
                <span className='headquarter-create-edit__data-headquarter__label'>
                  Inversión inicial <span style={{ color: '#F85F61' }}>*</span>
                </span>
                <NumericFormat
                  defaultValue={0}
                  type='text'
                  prefix={"$ "}
                  placeholder='$ 0'
                  isAllowed={(valueField) => {
                    const { floatValue } = valueField;
                    return floatValue !== undefined || values.initialBalance !== 0;
                  }}
                  thousandSeparator=","
                  value={values?.initialBalance || 0}
                  thousandsGroupStyle="thousand"
                  onValueChange={(e) => onChangeField('initialBalance', e)}
                  onBlur={(value) => handleBlur('initialBalance')(value)}
                  className={errors.initialBalance && touched.initialBalance ? 'input-price-error' : 'input-price'}
                />
                <span className="headquarter-create-edit__data-headquarter__error">{touched.initialBalance ? errors.initialBalance : null}</span>
              </div>
            </div>
            <div className='headquarter-create-edit__data-headquarter__content'>
              <span className='headquarter-create-edit__data-headquarter__label'>
                Dirección del establecimiento, sede o negocio<span style={{ color: '#F85F61' }}>*</span>
              </span>
              <Input
                value={values.address}
                placeholder='Escribe la dirección del establecimiento'
                onBlur={(value) => handleBlur('address')(value)}
                onChange={(value) => onChangeField("address", value)}
                className='headquarter-create-edit__data-headquarter__input'
                status={errors.address && touched.address ? 'error' : ''}
              />
              <span className="headquarter-create-edit__data-headquarter__error">{touched.address ? errors.address : null}</span>
            </div>
            <div className="grid-x small-12 justify-content-between">
              <div className='headquarter-create-edit__data-headquarter__content headquarter-create-edit__data-headquarter__content--mod'>
                <span className='headquarter-create-edit__data-headquarter__label'>
                  Asignación de bodega <span style={{ color: '#F85F61' }}>*</span>
                </span>
                <Space
                  className="headquarter-create-edit__data-headquarter__space"
                  size={[0, 8]}
                  wrap>
                  {values?.store.map((tag, index) => {
                    if (editInputIndex === index) {
                      return (
                        <Input
                          ref={editInputRef}
                          key={`edit-${index}`}
                          size="small"
                          className='headquarter-create-edit__data-headquarter__input'
                          value={editInputValue}
                          onChange={(e) => setEditInputValue(e.target.value)}
                          onBlur={handleEditInputConfirm}
                          onPressEnter={handleEditInputConfirm}
                          status={errors.store && touched.store ? 'error' : ''}
                        />
                      );
                    }
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                      <Tag
                        key={tag}
                        closable
                        style={{
                          userSelect: 'none',
                        }}
                        onClose={() => handleClose(tag)}
                        className="headquarter-create-edit__data-headquarter__tag"
                      >
                        <span
                          onDoubleClick={(e) => {
                            setEditInputIndex(index);
                            setEditInputValue(tag);
                            e.preventDefault();
                          }}
                        >
                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                  {inputVisible ? (
                    <Input
                      ref={inputRef}
                      type="text"
                      key="input"
                      size="small"
                      className='headquarter-create-edit__data-headquarter__input'
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onBlur={(value) => handleInputConfirm(value)}
                      onPressEnter={(value) => handleInputConfirm(value)}
                      status={errors.store && touched.store ? 'error' : ''}
                    />
                  ) : (
                    <Tag 
                      icon={<img
                        alt="icon_add_plus"
                        src={Assets.SharedIcons.icon_add_plus}
                      />} 
                      onClick={() => setInputVisible(true)}
                      className='headquarter-create-edit__data-headquarter__add-tag'
                      key="add-tag"
                    >
                      Agregar Bodega
                    </Tag>
                  )}
                </Space>
                <span className="headquarter-create-edit__data-headquarter__error">{touched.store ? errors.store : null}</span>
              </div>
              <div className='headquarter-create-edit__data-headquarter__content headquarter-create-edit__data-headquarter__content--mod'>
                <span className='headquarter-create-edit__data-headquarter__label'>
                  Responsable de la sede <span style={{ color: '#F85F61' }}>*</span>
                </span>
                <Select
                  options={listUserAvailable}
                  popupMatchSelectWidth={false}
                  placeholder='Selecciona un usuario'
                  onBlur={(value) => handleBlur('userChargeHeadQuarter')(value)}
                  onChange={(e, event) => onChangeField("userChargeHeadQuarter", event)}
                  value={values?.userChargeHeadQuarter ? values?.userChargeHeadQuarter?.value : undefined}
                  className='headquarter-create-edit__select'
                  status={errors.userChargeHeadQuarter && touched.userChargeHeadQuarter ? 'error' : ''}
                  popupClassName='headquarter-create-edit__select__popup'
                  suffixIcon={
                    <div className='headquarter-create-edit__select__icon'>
                      <img
                        alt="icon_blue_arrow"
                        src={Assets.SharedIcons.icon_blue_arrow}
                      />
                    </div>
                  }
                />
                <span className="headquarter-create-edit__data-headquarter__error">{touched.userChargeHeadQuarter ? errors.userChargeHeadQuarter : null}</span>
              </div>
              <div className="grid-x small-12 justify-content-between">
              <div className='headquarter-create-edit__data-headquarter__content headquarter-create-edit__data-headquarter__content--mod'>
                <span className='headquarter-create-edit__data-headquarter__label'>
                  Responsable de cuadre de sede <span style={{ color: '#F85F61' }}>*</span>
                </span>
                  <Select
                    mode="multiple"
                    options={listOfManagers}
                    popupMatchSelectWidth={false}
                    value={values.managersSelected}
                    placeholder="Selecciona un responsable"
                    className='headquarter-create-edit__select-multiple'
                    onBlur={(value) => handleBlur('managersSelected')(value)}
                    popupClassName='headquarter-create-edit__select-multiple__popup'
                    onChange={(e, select) => onChangeField("managersSelected", select)}
                    status={errors.managersSelected && touched.managersSelected ? 'error' : ''}
                    suffixIcon={<img alt="icon_blue_arrow" src={Assets.SharedIcons.icon_blue_arrow} />}
                  />
                <span className="headquarter-create-edit__data-headquarter__error">{touched.managersSelected ? errors.managersSelected : null}</span>
              </div>
              </div>
            </div>
            <div className="grid-x small-12 justify-content-end headquarter-create-edit__buttons">
              <button
                className='headquarter-create-edit__cancel'
                onClick={() => navigate('/adminPanel/1')}
              >
                Cancelar
              </button>
              <button
                className='headquarter-create-edit__save'
                disabled={Object.keys(errors).length > 0}
                onClick={() => values?.id ? updateHeadquarter() : createHeadquarter()}
              >
                Guardar Cambios
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToPropsActions = {
  setStateAdminHeadquarterReducer
};

export default connect(null, mapStateToPropsActions)(HeadquarterCreateEditPage);
