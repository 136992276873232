// Libraries
import React from 'react'
import { DatePicker, Modal } from 'antd';
import dayjs from 'dayjs';

//Styles
import './modal-download-excel.component.scss';

const { RangePicker } = DatePicker;

export const ModalDownloadExcelComponent = (props) => {

    const {
        isOpen,
        onClose= () => null,
        onGenerate= () => null,
        onDateChange= () => null,
        title,
        month
    } = props;
    
    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    }

    const disabledMonth = (current) => {
        return current && current > dayjs().endOf('month');
    }

  return (
    <div className='modal-download-excel__container'>
            <Modal
            width={500}
            open={isOpen}
            onCancel={onClose}
            footer={
                <div className='grid-x justify-content-end'>
                <button
                    className='modal-download-excel__button-generate'
                    onClick={() => onGenerate()}
                >
                    General excel
                </button>
                </div>
            }
            >
                {month ? (
                    <div className='grid-y modal-download-excel__content-modal'>
                        <span className='modal-download-excel__content-modal__title'>{title}</span>
                        <RangePicker
                            picker='month'
                            disabledDate={disabledMonth}
                            onChange={(dates) => onDateChange(dates)}
                        />
                    </div>
                ) : (
                    <div className='grid-y modal-download-excel__content-modal'>
                        <span className='modal-download-excel__content-modal__title'>{title}</span>
                        <RangePicker
                            disabledDate={disabledDate}
                            onChange={(dates) => onDateChange(dates)}
                        />
                    </div>
                )}
            </Modal>
    </div>
  )
}
