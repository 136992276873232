const DailyMovementsEndPoints =  {
    GET_DAILY_MOVEMENTS: `/api/dailyTransactionTreasury/:date`,
    CREATE_DAILY_MOVEMENTS: `/api/dailyTransactionTreasury`,
    UPDATE_DAILY_MOVEMENTS: `/api/dailyTransactionTreasury`,
    PUBLISH_DAILY_MOVEMENTS: `/api/dailyTransactionTreasury/publish`,
    GET_DAILY_TRANSACTION_CASH_CLOSING: `/api/dailyTransactionTreasury/cashClosing/:date`,
    DELETE_DAILY_MOVEMENT_RECORD: `/api/dailyTransactionTreasury/`,
    GET__DOWNLOAD_EXCEL: `/api/dailyTransactionTreasury/download/:startDate/:endDate`,
}

export default DailyMovementsEndPoints