const PortfolioEndPoints =  {
    GET_ALL_PORTFOLIO: `/api/shared/headQuarter/all`,
    GET_SUMMARY_BY_HEADQUARTER: `/api/walletTreasury/walletHeadquarter`,
    GET_HISTORICAL_PORTFOLIOS: `/api/walletTreasury/:date`,
    GET_ALL_PORTFOLIO_WALLET: `/api/walletInventoryControl/:date`,
    GET_DOWNLOAD_PORTFOLIO_WALLET: `/api/walletTreasury/walletHeadquarter/download/:startDate/:endDate`,
    GET_SUMMARY_BY_HEADQUARTER_WALLET:`/api/walletInventoryControl/walletHeadquarter`,
    PATCH_WALLET_INVENTORY_CONTROL:`/api/walletInventoryControl`,

}

export default PortfolioEndPoints