//Constants
import DailyMovementsEndPoints from "./constants/daily-movements.end-points"

//Infrastructures
import Http from "./infrastructure/http.infrastructure"

//Services
import { getTokenService } from "../services/authentication.services"

export async function getFindOneDailyMovementsService(date) {
    const token = getTokenService();
    try {
        const urlWithParams = DailyMovementsEndPoints.GET_DAILY_MOVEMENTS.replace(":date", date)
        const { data: responseGetFindOneDailyMovements, error } = await Http.get(urlWithParams, null, token)
        if (responseGetFindOneDailyMovements && responseGetFindOneDailyMovements.status) {
            return responseGetFindOneDailyMovements.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};


export async function createDailyMovementsService(data) {
    const token = getTokenService()
    try {
        const { data: responseCreateDailyMovements, error } = await Http.post(DailyMovementsEndPoints.CREATE_DAILY_MOVEMENTS, data, token)
        if (responseCreateDailyMovements && responseCreateDailyMovements.status) {
            return responseCreateDailyMovements.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateDailyMovementsService(data) {
    try {
        const token = getTokenService()
        const { data: responseCreateDailyMovements, error } = await Http.patch(DailyMovementsEndPoints.UPDATE_DAILY_MOVEMENTS, data, token)
        if (responseCreateDailyMovements && responseCreateDailyMovements.status) {
            return responseCreateDailyMovements.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createPublishDailyMovementsService(data) {
    try {
        const token = getTokenService()
        const { data: responseCreateDailyMovements, error } = await Http.patch(DailyMovementsEndPoints.PUBLISH_DAILY_MOVEMENTS, data, token)
        if (responseCreateDailyMovements && responseCreateDailyMovements.status) {
            return responseCreateDailyMovements.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDailyTransactionCashClosingService(date) {
    const token = getTokenService();
    try {
        const urlWithParams = DailyMovementsEndPoints.GET_DAILY_TRANSACTION_CASH_CLOSING.replace(":date", date)
        const { data: responseGetDailyTransactionCashClosing, error } = await Http.get(urlWithParams, null, token)
        if (responseGetDailyTransactionCashClosing && responseGetDailyTransactionCashClosing.status) {
            return responseGetDailyTransactionCashClosing.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function deletedDailyMovementRecordService(body) {
    const token = getTokenService();
    try {
        const { data: responseDeletedDailyMovementRecord, error } = await Http.delete(DailyMovementsEndPoints.DELETE_DAILY_MOVEMENT_RECORD, body, token)
        if (responseDeletedDailyMovementRecord && responseDeletedDailyMovementRecord.status) {
            return responseDeletedDailyMovementRecord.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDownloadExcelService(startDate, endDate) {
    const token = getTokenService();
    try {
        const urlWithParams = DailyMovementsEndPoints.GET__DOWNLOAD_EXCEL.replace(":startDate", startDate).replace(":endDate", endDate)
        const { data: responseGetFindOneDailyMovements, error } = await Http.get(urlWithParams, null, token)
        if (responseGetFindOneDailyMovements && responseGetFindOneDailyMovements.status) {
            return responseGetFindOneDailyMovements.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};