import * as ExcelJS from 'exceljs';
import dayjs from 'dayjs';

export const excelGeneratorCashClosing = (dataArray,name) => {
    const workbook = new ExcelJS.Workbook();
    dataArray.forEach((data,index) => {
        const worksheet = workbook.addWorksheet(`${dayjs(data.date).format('DD-MM-YYYY')}`);

        const dataTotalTable1 = ((data?.cashRegisterCashExpenseReturns?.length) > (data?.cashRegisterCashReceiptCardReader?.length)) ? (data.cashRegisterCashExpenseReturns.length) : (data.cashRegisterCashReceiptCardReader.length);
        const dataTotalTable2 = ((data?.cashRegisterCashExpense?.length) > (data.cashRegisterCashReceiptMoneyTransfer.length)) ? data.cashRegisterCashExpense.length : data.cashRegisterCashReceiptMoneyTransfer.length;
        const dataTotalTable3 = ((data?.cashRegisterCashReceiptPaymentOfBondsmen?.length) > (data.cashRegisterCashReceiptPaymentOfBondsmen.length)) ? data.cashRegisterCashReceiptPaymentOfBondsmen.length : data.cashRegisterCashReceiptPaymentOfBondsmen.length;
        const dataTotalTable4 = ((data?.cashRegisterCashReceiptBottleControl?.length) > (data.cashRegisterCashExpenseProvider.length)) ? data.cashRegisterCashReceiptBottleControl.length : data.cashRegisterCashExpenseProvider.length;

        const borderStyle = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };

        worksheet.getCell('A1').value = 'Saldo inicial';
        worksheet.getCell('A1').font = {
            bold: true,
            size: 12,
        };
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('A1').border = borderStyle;
        worksheet.getCell('A2').value = data.closingCashRegisterCashRegister.initialBalance;
        worksheet.getCell('A2').numFmt = '$#,##0';
        worksheet.getCell('A2').border = borderStyle;

        worksheet.getCell('B1').value = 'Ventas Nube';
        worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('B1').font = {
            bold: true,
            size: 12,
        };
        worksheet.getCell('B1').border = borderStyle;
        worksheet.getCell('B2').value = data.closingCashRegisterCashRegister.salesTNSCloud;
        worksheet.getCell('B2').numFmt = '$#,##0';
        worksheet.getCell('B2').border = borderStyle;

        worksheet.getCell('C1').value = 'Ventas Punto Frío';
        worksheet.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('C1').font = {
            bold: true,
            size: 12,
        };
        worksheet.getCell('C1').border = borderStyle;
        worksheet.getCell('C2').value = data.closingCashRegisterCashRegister.salesPF;
        worksheet.getCell('C2').numFmt = '$#,##0';
        worksheet.getCell('C2').border = borderStyle;

        worksheet.getCell('D1').value = 'Ventas de Cocina';
        worksheet.getCell('D1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('D1').font = {
            bold: true,
            size: 12,
        };
        worksheet.getCell('D1').border = borderStyle;
        worksheet.getCell('D2').value = data.closingCashRegisterCashRegister.kitchen;
        worksheet.getCell('D2').numFmt = '$#,##0';
        worksheet.getCell('D2').border = borderStyle;

        worksheet.getCell('E1').value = 'Total Efectivo';
        worksheet.getCell('E1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('E1').font = {
            bold: true,
            size: 12,
        };
        worksheet.getCell('E1').border = borderStyle;
        worksheet.getCell('E2').value = data.totalCash;
        worksheet.getCell('E2').numFmt = '$#,##0';
        worksheet.getCell('E2').border = borderStyle;

        worksheet.getCell('F1').value = 'Efectivo real';
        worksheet.getCell('F1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('F1').font = {
            bold: true,
            size: 12,
        };
        worksheet.getCell('F1').border = borderStyle;
        worksheet.getCell('F2').value = data.realCash;
        worksheet.getCell('F2').numFmt = '$#,##0';
        worksheet.getCell('F2').border = borderStyle;

        worksheet.getCell('G1').value = 'Diferencia';
        worksheet.getCell('G1').alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell('G1').font = {
            bold: true,
            size: 12,
        };
        worksheet.getCell('G1').border = borderStyle;
        worksheet.getCell('G2').value = data.cashDifference;
        worksheet.getCell('G2').numFmt = '$#,##0';
        worksheet.getCell('G2').border = borderStyle;



        worksheet.getRow(5).height = 30;
        worksheet.getRow(6).height = 30;

        worksheet.getCell('A5').value = 'Datáfonos';
        worksheet.mergeCells('A5:B5');
        worksheet.getCell('A5').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }
        worksheet.getCell('A5').font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }
        worksheet.getCell('A5').alignment = { vertical: 'middle', horizontal: 'center' }
        worksheet.getCell('A6').value = 'Numero de voucher';
        worksheet.getCell('A6').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }
        worksheet.getCell('A6').font = { bold: true, color: { argb: 'FFFFFFF' } }
        worksheet.getCell('B6').value = 'Valor';
        worksheet.getCell('B6').font = { bold: true, color: { argb: 'FFFFFFF' } }
        worksheet.getCell('B6').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }

        let dataphonesTotal = 0;
        data.cashRegisterCashReceiptCardReader.forEach((item, index) => {
            worksheet.getCell(`A${index + 7}`).value = item.voucherNumber;
            worksheet.getCell(`B${index + 7}`).value = item.price;
            worksheet.getCell(`B${index + 7}`).numFmt = '$#,##0'
            dataphonesTotal += item.price;
        });
        worksheet.getCell(`A${dataTotalTable1 + 8}`).value = "Total";
        worksheet.getCell(`A${dataTotalTable1 + 8}`).font = { bold: true, color: { argb: 'FFFFFFF' } }
        worksheet.getCell(`A${dataTotalTable1 + 8}`).alignment = { vertical: 'middle', horizontal: 'center' }
        worksheet.getCell(`A${dataTotalTable1 + 8}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }
        worksheet.getCell(`B${dataTotalTable1 + 8}`).value = dataphonesTotal;
        worksheet.getCell(`B${dataTotalTable1 + 8}`).font = { bold: true }
        worksheet.getCell(`B${dataTotalTable1 + 8}`).numFmt = '$#,##0'

        for (let index = 0; index < dataTotalTable1 + 4; index++) {
            worksheet.getCell(`A${index + 5}`).border = {
                top: { style: 'medium', color: { argb: 'FF0E7D80' } },
                left: { style: 'medium', color: { argb: 'FF0E7D80' } },
                bottom: { style: 'medium', color: { argb: 'FF0E7D80' } },
                right: { style: 'medium', color: { argb: 'FF0E7D80' } }
            };

            worksheet.getCell(`B${index + 5}`).border = {
                top: { style: 'medium', color: { argb: 'FF0E7D80' } },
                left: { style: 'medium', color: { argb: 'FF0E7D80' } },
                bottom: { style: 'medium', color: { argb: 'FF0E7D80' } },
                right: { style: 'medium', color: { argb: 'FF0E7D80' } }
            };

        }

        // Tabla de Transferencias
        const transferStartRow = dataTotalTable1 + 10;
        worksheet.getRow(transferStartRow).height = 30;
        worksheet.getCell(`A${transferStartRow}`).value = 'Transferencias';
        worksheet.getCell(`A${transferStartRow}`).alignment = { vertical: 'middle', horizontal: 'center' }
        worksheet.mergeCells(`A${transferStartRow}:B${transferStartRow}`);
        worksheet.getCell(`A${transferStartRow}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }
        worksheet.getCell(`A${transferStartRow}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }
        worksheet.getCell(`A${transferStartRow + 1}`).value = 'N° de Factura';
        worksheet.getCell(`A${transferStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }
        worksheet.getCell(`A${transferStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }
        worksheet.getCell(`B${transferStartRow + 1}`).value = 'Valor';
        worksheet.getCell(`B${transferStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }
        worksheet.getCell(`B${transferStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }

        let transfersTotal = 0;
        data.cashRegisterCashReceiptMoneyTransfer.forEach((item, index) => {
            worksheet.getCell(`A${transferStartRow + index + 2}`).value = item.invoiceNumber;
            worksheet.getCell(`B${transferStartRow + index + 2}`).value = item.price;
            worksheet.getCell(`B${transferStartRow + index + 2}`).numFmt = '$#,##0'
            transfersTotal += item.price;
        });

        worksheet.getCell(`A${transferStartRow + dataTotalTable2 + 3}`).value = "Total";
        worksheet.getCell(`A${transferStartRow + dataTotalTable2 + 3}`).font = { bold: true, color: { argb: 'FFFFFFF' } }
        worksheet.getCell(`A${transferStartRow + dataTotalTable2 + 3}`).alignment = { vertical: 'middle', horizontal: 'center' }
        worksheet.getCell(`A${transferStartRow + dataTotalTable2 + 3}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }
        worksheet.getCell(`B${transferStartRow + dataTotalTable2 + 3}`).value = transfersTotal;
        worksheet.getCell(`B${transferStartRow + dataTotalTable2 + 3}`).font = { bold: true }
        worksheet.getCell(`B${transferStartRow + dataTotalTable2 + 3}`).numFmt = '$#,##0'

        for (let index = 0; index < dataTotalTable2 + 4; index++) {
            worksheet.getCell(`A${transferStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF0E7D80' } },
                left: { style: 'medium', color: { argb: 'FF0E7D80' } },
                bottom: { style: 'medium', color: { argb: 'FF0E7D80' } },
                right: { style: 'medium', color: { argb: 'FF0E7D80' } }
            };

            worksheet.getCell(`B${transferStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF0E7D80' } },
                left: { style: 'medium', color: { argb: 'FF0E7D80' } },
                bottom: { style: 'medium', color: { argb: 'FF0E7D80' } },
                right: { style: 'medium', color: { argb: 'FF0E7D80' } }
            };

        }


        // Tabla de Pagos de fiados
        const paymentOfBondsmenRow = transferStartRow + dataTotalTable2 + 5;
        worksheet.getRow(paymentOfBondsmenRow).height = 30;
        worksheet.getCell(`A${paymentOfBondsmenRow}`).value = 'Pagos de fiados';
        worksheet.getCell(`A${paymentOfBondsmenRow}`).alignment = { vertical: 'middle', horizontal: 'center' }
        worksheet.getCell(`A${paymentOfBondsmenRow}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }
        worksheet.mergeCells(`A${paymentOfBondsmenRow}:D${paymentOfBondsmenRow}`);
        worksheet.getCell(`A${paymentOfBondsmenRow}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        };
        worksheet.getCell(`A${paymentOfBondsmenRow + 1}`).value = 'Número de identificación';
        worksheet.getCell(`A${paymentOfBondsmenRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }
        worksheet.getCell(`A${paymentOfBondsmenRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        worksheet.getCell(`B${paymentOfBondsmenRow + 1}`).value = 'Autorizado por';
        worksheet.getCell(`B${paymentOfBondsmenRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }
        worksheet.getCell(`B${paymentOfBondsmenRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }

        worksheet.getCell(`C${paymentOfBondsmenRow + 1}`).value = 'N° de la factura';
        worksheet.getCell(`C${paymentOfBondsmenRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }
        worksheet.getCell(`C${paymentOfBondsmenRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }

        worksheet.getCell(`D${paymentOfBondsmenRow + 1}`).value = 'Valor';
        worksheet.getCell(`D${paymentOfBondsmenRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }
        worksheet.getCell(`D${paymentOfBondsmenRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }

        let paymentOfBondsmenTotal = 0;
        data.cashRegisterCashReceiptPaymentOfBondsmen.forEach((item, index) => {
            worksheet.getCell(`A${paymentOfBondsmenRow + index + 2}`).value = item.concept;
            worksheet.getCell(`B${paymentOfBondsmenRow + index + 2}`).value = item.chargeCashReceiptPaymentOfBondsmen.name;
            worksheet.getCell(`C${paymentOfBondsmenRow + index + 2}`).value = item.invoiceNumber;
            worksheet.getCell(`D${paymentOfBondsmenRow + index + 2}`).value = item.price;
            worksheet.getCell(`D${paymentOfBondsmenRow + index + 2}`).numFmt = '$#,##0';
            paymentOfBondsmenTotal += item.price;
        });

        worksheet.getCell(`A${paymentOfBondsmenRow + dataTotalTable3 + 3}`).value = "Total";
        worksheet.getCell(`A${paymentOfBondsmenRow + dataTotalTable3 + 3}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '330E7D80' },
        }
        worksheet.getCell(`A${paymentOfBondsmenRow + dataTotalTable3 + 3}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }
        worksheet.getCell(`A${paymentOfBondsmenRow + dataTotalTable3 + 3}`).alignment = { vertical: 'middle', horizontal: 'center' }

        worksheet.getCell(`D${paymentOfBondsmenRow + dataTotalTable3 + 3}`).value = paymentOfBondsmenTotal;
        worksheet.getCell(`D${paymentOfBondsmenRow + dataTotalTable3 + 3}`).font = {
            bold: true,
        }
        worksheet.getCell(`D${paymentOfBondsmenRow + dataTotalTable3 + 3}`).numFmt = '$#,##0';

        for (let index = 0; index < data.cashRegisterCashReceiptPaymentOfBondsmen.length + 4; index++) {
            worksheet.getCell(`A${paymentOfBondsmenRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF0E7D80' } },
                left: { style: 'medium', color: { argb: 'FF0E7D80' } },
                bottom: { style: 'medium', color: { argb: 'FF0E7D80' } },
                right: { style: 'medium', color: { argb: 'FF0E7D80' } }
            };

            worksheet.getCell(`B${paymentOfBondsmenRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF0E7D80' } },
                left: { style: 'medium', color: { argb: 'FF0E7D80' } },
                bottom: { style: 'medium', color: { argb: 'FF0E7D80' } },
                right: { style: 'medium', color: { argb: 'FF0E7D80' } }
            };

            worksheet.getCell(`C${paymentOfBondsmenRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF0E7D80' } },
                left: { style: 'medium', color: { argb: 'FF0E7D80' } },
                bottom: { style: 'medium', color: { argb: 'FF0E7D80' } },
                right: { style: 'medium', color: { argb: 'FF0E7D80' } }
            };

            worksheet.getCell(`D${paymentOfBondsmenRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF0E7D80' } },
                left: { style: 'medium', color: { argb: 'FF0E7D80' } },
                bottom: { style: 'medium', color: { argb: 'FF0E7D80' } },
                right: { style: 'medium', color: { argb: 'FF0E7D80' } }
            };

        }



        // Tabla de Devoluciones
        const startCol = 'G';
        worksheet.getCell(`${startCol}5`).value = 'Devolución';
        worksheet.mergeCells(`${startCol}5:${String.fromCharCode(startCol.charCodeAt(0) + 1)}5`);
        worksheet.getCell(`${startCol}5`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        };
        worksheet.getCell(`${startCol}5`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' },
            size: 12
        };
        worksheet.getCell(`${startCol}5`).alignment = { vertical: 'middle', horizontal: 'center' }

        worksheet.getCell(`${startCol}6`).value = 'N° de Factura';
        worksheet.getCell(`${startCol}6`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${startCol}6`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }


        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}6`).value = 'Valor';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}6`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}6`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        let cashExpenseTotal = 0;
        data.cashRegisterCashExpenseReturns.forEach((item, index) => {
            worksheet.getCell(`${startCol}${index + 7}`).value = item.invoiceNumber;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${index + 7}`).value = item.price;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${index + 7}`).numFmt = '$#,##0';
            cashExpenseTotal += item.price;
        });

        worksheet.getCell(`${startCol}${dataTotalTable1 + 8}`).value = "Total";
        worksheet.getCell(`${startCol}${dataTotalTable1 + 8}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${startCol}${dataTotalTable1 + 8}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }
        worksheet.getCell(`${startCol}${dataTotalTable1 + 8}`).alignment = { vertical: 'middle', horizontal: 'center' }

        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${dataTotalTable1 + 8}`).value = cashExpenseTotal;
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${dataTotalTable1 + 8}`).numFmt = '$#,##0';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${dataTotalTable1 + 8}`).font = { bold: true }


        for (let index = 0; index < dataTotalTable1 + 4; index++) {
            worksheet.getCell(`${startCol}${index + 5}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            };
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${index + 5}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            };
        }


        // Tabla de Gastos
        const expenseStartRow = dataTotalTable1 + 10;
        worksheet.getCell(`${startCol}${expenseStartRow}`).value = 'Gastos';
        worksheet.mergeCells(`${startCol}${expenseStartRow}:${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow}`);
        worksheet.getCell(`${startCol}${expenseStartRow}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        };
        worksheet.getCell(`${startCol}${expenseStartRow}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' },
            size: 12
        };
        worksheet.getCell(`${startCol}${expenseStartRow}`).alignment = { vertical: 'middle', horizontal: 'center' }

        worksheet.getCell(`${startCol}${expenseStartRow + 1}`).value = 'Categoría';
        worksheet.getCell(`${startCol}${expenseStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${startCol}${expenseStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${expenseStartRow + 1}`).value = 'Subcategoría';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${expenseStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${expenseStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${expenseStartRow + 1}`).value = 'N° de la factura';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${expenseStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${expenseStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow + 1}`).value = 'Valor';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        let expensesTotal = 0;
        data.cashRegisterCashExpense.forEach((item, index) => {
            worksheet.getCell(`${startCol}${expenseStartRow + index + 2}`).value = item.expenseSubcategoryCashExpense.expenseCategoryExpenseSubcategory.name;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${expenseStartRow + index + 2}`).value = item.expenseSubcategoryCashExpense.name;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${expenseStartRow + index + 2}`).value = item.invoiceNumber;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow + index + 2}`).value = item.price;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow + index + 2}`).numFmt = '$#,##0';
            expensesTotal += item.price;
        });

        worksheet.getCell(`${startCol}${expenseStartRow + dataTotalTable2 + 3}`).value = "Total";
        worksheet.getCell(`${startCol}${expenseStartRow + dataTotalTable2 + 3}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${startCol}${expenseStartRow + dataTotalTable2 + 3}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }
        worksheet.getCell(`${startCol}${expenseStartRow + dataTotalTable2 + 3}`).alignment = { vertical: 'middle', horizontal: 'center' }

        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow + dataTotalTable2 + 3}`).value = expensesTotal;
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow + dataTotalTable2 + 3}`).numFmt = '$#,##0';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow + dataTotalTable2 + 3}`).font = { bold: true }


        for (let index = 0; index < dataTotalTable2 + 4; index++) {
            worksheet.getCell(`${startCol}${expenseStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            };
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${expenseStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            }

            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${expenseStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            }

            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${expenseStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            }
        }


        //Tabla de Fiados
        const trustStartRow = expenseStartRow + dataTotalTable2 + 5;
        worksheet.getCell(`${startCol}${trustStartRow}`).value = 'Fiados';
        worksheet.mergeCells(`${startCol}${trustStartRow}:${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow}`);
        worksheet.getCell(`${startCol}${trustStartRow}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        };
        worksheet.getCell(`${startCol}${trustStartRow}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        };
        worksheet.getCell(`${startCol}${trustStartRow}`).alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.getCell(`${startCol}${trustStartRow + 1}`).value = 'Número de identificación';
        worksheet.getCell(`${startCol}${trustStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${startCol}${trustStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${trustStartRow + 1}`).value = 'Autorizado por';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${trustStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${trustStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${trustStartRow + 1}`).value = 'N° de la factura';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${trustStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${trustStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow + 1}`).value = 'Valor';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow + 1}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }

        let expensesTrustTotal = 0;
        data.cashRegisterCashExpenseTrust.forEach((item, index) => {
            worksheet.getCell(`${startCol}${trustStartRow + index + 2}`).value = item.concept;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${trustStartRow + index + 2}`).value = item.chargeCashExpenseTrust.name;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${trustStartRow + index + 2}`).value = item.invoiceNumber;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow + index + 2}`).value = item.price;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow + index + 2}`).numFmt = '$#,##0';
            expensesTrustTotal += item.price;
        });

        worksheet.getCell(`${startCol}${trustStartRow + dataTotalTable3 + 3}`).value = "Total";
        worksheet.getCell(`${startCol}${trustStartRow + dataTotalTable3 + 3}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF85F61' },
        }
        worksheet.getCell(`${startCol}${trustStartRow + dataTotalTable3 + 3}`).font = {
            bold: true,
            color: { argb: 'FFFFFFF' }
        }
        worksheet.getCell(`${startCol}${trustStartRow + dataTotalTable3 + 3}`).alignment = { vertical: 'middle', horizontal: 'center' };

        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow + dataTotalTable3 + 3}`).value = expensesTrustTotal;
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow + dataTotalTable3 + 3}`).numFmt = '$#,##0';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow + dataTotalTable3 + 3}`).font = { bold: true };


        for (let index = 0; index < dataTotalTable3 + 4; index++) {
            worksheet.getCell(`${startCol}${trustStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            };
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${trustStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            }

            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${trustStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            }

            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${trustStartRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FFF85F61' } },
                left: { style: 'medium', color: { argb: 'FFF85F61' } },
                bottom: { style: 'medium', color: { argb: 'FFF85F61' } },
                right: { style: 'medium', color: { argb: 'FFF85F61' } }
            }
        }

        // Tabla de Control de envases
        const bottleControlRow = trustStartRow + dataTotalTable3 + 5;
        worksheet.getRow(bottleControlRow).height = 30;
        worksheet.getCell(`A${bottleControlRow}`).value = 'Control de envases';
        worksheet.mergeCells(`A${bottleControlRow}:D${bottleControlRow}`);
        worksheet.getCell(`A${bottleControlRow}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        };
        worksheet.getCell(`A${bottleControlRow}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        };
        worksheet.getCell(`A${bottleControlRow}`).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell(`A${bottleControlRow + 1}`).value = 'Envase';
        worksheet.getCell(`A${bottleControlRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`A${bottleControlRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }
        worksheet.getCell(`B${bottleControlRow + 1}`).value = 'Valor de la caja';
        worksheet.getCell(`B${bottleControlRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`B${bottleControlRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }

        worksheet.getCell(`C${bottleControlRow + 1}`).value = 'Cantidad';
        worksheet.getCell(`C${bottleControlRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`C${bottleControlRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }

        worksheet.getCell(`D${bottleControlRow + 1}`).value = 'Valor';
        worksheet.getCell(`D${bottleControlRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`D${bottleControlRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }

        let bottleControlTotal = 0;
        data.cashRegisterCashReceiptBottleControl.forEach((item, index) => {
            worksheet.getCell(`A${bottleControlRow + index + 2}`).value = item.type;
            worksheet.getCell(`B${bottleControlRow + index + 2}`).value = item.price;
            worksheet.getCell(`B${bottleControlRow + index + 2}`).numFmt = '$#,##0';
            worksheet.getCell(`C${bottleControlRow + index + 2}`).value = item.amount;
            worksheet.getCell(`D${bottleControlRow + index + 2}`).value = item.price * item.amount;
            worksheet.getCell(`D${bottleControlRow + index + 2}`).numFmt = '$#,##0';
            bottleControlTotal += (item.price * item.amount);
        });

        worksheet.getCell(`A${bottleControlRow + dataTotalTable4 + 3}`).value = "Total";
        worksheet.getCell(`A${bottleControlRow + dataTotalTable4 + 3}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }
        worksheet.getCell(`A${bottleControlRow + dataTotalTable4 + 3}`).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell(`A${bottleControlRow + dataTotalTable4 + 3}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`D${bottleControlRow + dataTotalTable4 + 3}`).value = bottleControlTotal;
        worksheet.getCell(`D${bottleControlRow + dataTotalTable4 + 3}`).font = {
            bold: true,
            size: 12
        }
        worksheet.getCell(`D${bottleControlRow + dataTotalTable4 + 3}`).numFmt = '$#,##0';

        for (let index = 0; index < data.cashRegisterCashReceiptBottleControl.length + 4; index++) {
            worksheet.getCell(`A${bottleControlRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

            worksheet.getCell(`B${bottleControlRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

            worksheet.getCell(`C${bottleControlRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

            worksheet.getCell(`D${bottleControlRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

        }


        // Tabla de Consumo interno
        const domesticConsumptionRow = bottleControlRow + data.cashRegisterCashReceiptBottleControl.length + 5;
        worksheet.getRow(domesticConsumptionRow).height = 30;
        worksheet.getCell(`A${domesticConsumptionRow}`).value = 'Consumo interno';
        worksheet.mergeCells(`A${domesticConsumptionRow}:D${domesticConsumptionRow}`);
        worksheet.getCell(`A${domesticConsumptionRow}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        };
        worksheet.getCell(`A${domesticConsumptionRow}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }
        worksheet.getCell(`A${domesticConsumptionRow}`).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell(`A${domesticConsumptionRow + 1}`).value = 'Categoría';
        worksheet.getCell(`A${domesticConsumptionRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`A${domesticConsumptionRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }
        worksheet.getCell(`B${domesticConsumptionRow + 1}`).value = 'Productos';
        worksheet.getCell(`B${domesticConsumptionRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`B${domesticConsumptionRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }

        worksheet.getCell(`C${domesticConsumptionRow + 1}`).value = 'Hora';
        worksheet.getCell(`C${domesticConsumptionRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`C${domesticConsumptionRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }

        worksheet.getCell(`D${domesticConsumptionRow + 1}`).value = 'Cantidad';
        worksheet.getCell(`D${domesticConsumptionRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`D${domesticConsumptionRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }

        let domesticConsumptionTotal = 0;
        data.cashRegisterDomesticConsumption.forEach((item, index) => {
            worksheet.getCell(`A${domesticConsumptionRow + index + 2}`).value = item.productDomesticConsumption.categoryDomesticConsumptionProduct.name;
            worksheet.getCell(`B${domesticConsumptionRow + index + 2}`).value = item.productDomesticConsumption.name;
            worksheet.getCell(`C${domesticConsumptionRow + index + 2}`).value = item.hour;
            worksheet.getCell(`D${domesticConsumptionRow + index + 2}`).value = item.price;
            worksheet.getCell(`D${domesticConsumptionRow + index + 2}`).numFmt = '$#,##0';
            domesticConsumptionTotal += item.price;
        });

        worksheet.getCell(`A${domesticConsumptionRow + data.cashRegisterDomesticConsumption.length + 3}`).value = "Total";
        worksheet.getCell(`A${domesticConsumptionRow + data.cashRegisterDomesticConsumption.length + 3}`).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell(`A${domesticConsumptionRow + data.cashRegisterDomesticConsumption.length + 3}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`A${domesticConsumptionRow + data.cashRegisterDomesticConsumption.length + 3}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }
        worksheet.getCell(`D${domesticConsumptionRow + data.cashRegisterDomesticConsumption.length + 3}`).value = domesticConsumptionTotal;
        worksheet.getCell(`D${domesticConsumptionRow + data.cashRegisterDomesticConsumption.length + 3}`).numFmt = '$#,##0';
        worksheet.getCell(`D${domesticConsumptionRow + data.cashRegisterDomesticConsumption.length + 3}`).font = {
            bold: true,
            size: 12
        }

        for (let index = 0; index < data.cashRegisterDomesticConsumption.length + 4; index++) {
            worksheet.getCell(`A${domesticConsumptionRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

            worksheet.getCell(`B${domesticConsumptionRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

            worksheet.getCell(`C${domesticConsumptionRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

            worksheet.getCell(`D${domesticConsumptionRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

        }


        //Tabla de Proveedores

        const providerRow = trustStartRow + dataTotalTable3 + 5;
        worksheet.getCell(`${startCol}${providerRow}`).value = 'Proveedores';
        worksheet.mergeCells(`${startCol}${providerRow}:${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow}`);
        worksheet.getCell(`${startCol}${providerRow}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        };
        worksheet.getCell(`${startCol}${providerRow}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        };
        worksheet.getCell(`${startCol}${providerRow}`).alignment = { vertical: 'middle', horizontal: 'center' };


        worksheet.getCell(`${startCol}${providerRow + 1}`).value = 'Nombre del proveedor';
        worksheet.getCell(`${startCol}${providerRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`${startCol}${providerRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }


        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${providerRow + 1}`).value = 'N° de factura';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${providerRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${providerRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }


        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${providerRow + 1}`).value = 'Forma de pago';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${providerRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${providerRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }


        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow + 1}`).value = 'Valor';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow + 1}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow + 1}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }

        let providerTotal = 0;
        data.cashRegisterCashExpenseProvider.forEach((item, index) => {
            worksheet.getCell(`${startCol}${providerRow + index + 2}`).value = item.providerCashExpenseProvider.name;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${providerRow + index + 2}`).value = item.invoiceNumber;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${providerRow + index + 2}`).value = item.paymentMethodCashExpenseProvider.name;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow + index + 2}`).value = item.price;
            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow + index + 2}`).numFmt = '$#,##0';

            providerTotal += item.price;
        });

        worksheet.getCell(`${startCol}${providerRow + dataTotalTable4 + 3}`).value = "Total";
        worksheet.getCell(`${startCol}${providerRow + dataTotalTable4 + 3}`).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        }
        worksheet.getCell(`${startCol}${providerRow + dataTotalTable4 + 3}`).font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        }
        worksheet.getCell(`${startCol}${providerRow + dataTotalTable4 + 3}`).alignment = { vertical: 'middle', horizontal: 'center' };


        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow + dataTotalTable4 + 3}`).value = providerTotal;
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow + dataTotalTable4 + 3}`).numFmt = '$#,##0';
        worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow + dataTotalTable4 + 3}`).font = { bold: true }

        for (let index = 0; index < dataTotalTable4 + 4; index++) {
            worksheet.getCell(`${startCol}${providerRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 1)}${providerRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 2)}${providerRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

            worksheet.getCell(`${String.fromCharCode(startCol.charCodeAt(0) + 3)}${providerRow + index}`).border = {
                top: { style: 'medium', color: { argb: 'FF1499F2' } },
                left: { style: 'medium', color: { argb: 'FF1499F2' } },
                bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
                right: { style: 'medium', color: { argb: 'FF1499F2' } }
            };

        }

        //Observaciones

        worksheet.getCell('M5').value = 'Observaciones';
        worksheet.getRow(trustStartRow + dataTotalTable3 + 5).height = 30;
        worksheet.getCell('M5').font = {
            bold: true,
            size: 12,
            color: { argb: 'FFFFFFF' },
        };
        worksheet.getCell('M5').alignment = { vertical: 'middle' };
        worksheet.getCell('M5').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1499F2' },
        };
        worksheet.getCell('M5').border = {
            top: { style: 'medium', color: { argb: 'FF1499F2' } },
            left: { style: 'medium', color: { argb: 'FF1499F2' } },
            bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
            right: { style: 'medium', color: { argb: 'FF1499F2' } }
        }
        worksheet.getCell('M6').value = data?.closingCashRegisterCashRegister?.observation;
        worksheet.getCell('M6').border = {
            top: { style: 'medium', color: { argb: 'FF1499F2' } },
            left: { style: 'medium', color: { argb: 'FF1499F2' } },
            bottom: { style: 'medium', color: { argb: 'FF1499F2' } },
            right: { style: 'medium', color: { argb: 'FF1499F2' } }
        }


        worksheet.columns.forEach((column, index) => {
            let maxLength = 0;
            if (index === 10 || index === 11) {
                column.width = 10;
            } else {
                column.eachCell({ includeEmpty: true }, cell => {
                    const cellValue = cell.value ? cell.value.toString() : '';
                    maxLength = Math.max(maxLength, cellValue.length);
                });
                column.width = maxLength + 2;
            }
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
