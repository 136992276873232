// Actions
import { setStateAuthorizedManagementReducer } from "../../../storage/reducers/authorized-management/authorized-management.actions";

// Assets
import { Assets } from "../../../assets";

// Components - shared
import ErrorAlertComponent from "../../../shared/components/alerts/error-alert/error-alert.component";

// Components
import PreviewAuthorizedComponent from "../components/preview-authorized/preview-authorized.component";

// Libraries
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import dayjs from 'dayjs';
import { Collapse } from "antd";

//Services
import { getAuthorizedManagementByIdService } from "../../../services/authorized-management.services";

// Styles
import "./authorization-history.page.scss"

const AuthorizationHistoryPage = (props) => {
    const { state: routeState } = useLocation();
    const history = useNavigate();
    const  {
      // Actions
      setStateAuthorizedManagementReducer,
      // Variables
      userAuthorizedSelected
    } = props;

    const [inventoryAdjustment, setInventoryAdjustment] = useState(false)
    const [totalExpense, setTotalExpense] = useState(0)
    const [totalReceipt, setTotalReceipt] = useState(0)

    const handleEdith = () => {
      history(`/authorizedManagement/createEdit`,{ state: { type: 2  } });
    }

    useEffect(() => {
      !userAuthorizedSelected && getDataAuthorizedManagement()
      if (routeState?.type === 1) {
        setInventoryAdjustment({
          inventoryAdjustment: true
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const getDataAuthorizedManagement = async() => {
      try {
        const getAuthorizedManagement = await getAuthorizedManagementByIdService(routeState?.idAuthorized, routeState?.type)
        setTotalExpense({
          totalExpense: getAuthorizedManagement?.LoanHistoryRecords?.expenseList?.reduce((acc, expense) => acc + expense?.price, 0)
        })
        setTotalReceipt({
          totalReceipt: getAuthorizedManagement?.LoanHistoryRecords?.receiptList?.reduce((acc, receipt) => acc + receipt.price, 0)
        })
        setStateAuthorizedManagementReducer("userAuthorizedSelected", getAuthorizedManagement)
      } catch (error) {
        ErrorAlertComponent()
      }
    }
    
  return (
    <div className="authorization-history__container">
      <div className="grid-x align-middle">
        <Link
          replace={true}
          to="/authorizedManagement"
          className="authorization-history__title"
        >
          Fiados
        </Link>
        <img
          alt="icon_arrow"
          src={Assets.SharedIcons.icon_arrow}
          className="authorization-history__icon-arrow"
        />
        <span className="authorization-history__subtitle">{userAuthorizedSelected?.name}</span>
      </div>
      <div className="grid-x authorization-history__box">
        <div className="small-4">
          <PreviewAuthorizedComponent handleEdith={() => handleEdith()}/>
        </div>
        {inventoryAdjustment?.inventoryAdjustment ? (
          <div className="small-8">
            <div className="authorization-history__inventory-adjustment">
              <div className="grid-y">
                <span className="authorization-history__inventory-adjustment__title">Historial de prestamos</span>
                <span className="authorization-history__inventory-adjustment__text">Histórico de prestamos y abonos del usuario</span>
              </div>
              <div className="grid-x authorization-history__inventory-adjustment__table">
                <label className="small-2 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle">Fecha</label>
                <label className="small-2 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle">Sede</label>
                <label className="small-2 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle">Entradas</label>
                <label className="small-2 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle">Fecha</label>
                <label className="small-2 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle">Sede</label>
                <label className="small-2 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle">Salidas</label>
              </div>
              <div className="grid-x authorization-history__container-inv-adjust">
                <div className="authorization-history__content-columns">
                  {userAuthorizedSelected?.LoanHistoryRecords?.receiptList?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map((item, index) => (
                    <div key={index} className="grid-x align-middle authorization-history__inventory-adjustment__content-item">
                      <label className="small-4 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle">{dayjs(item?.date)?.format('DD-MM-YYYY')}</label>
                      <label className="small-4 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle authorization-history__inventory-adjustment__title">{item?.price?.toLocaleString()}</label>
                      <label className="small-4 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle authorization-history__inventory-adjustment__title--red">${item?.price?.toLocaleString()}</label>
                    </div>
                  ))}
                </div>
                <div className="authorization-history__content-columns">
                  {userAuthorizedSelected?.LoanHistoryRecords?.expenseList?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map((item, index) => (
                    <div key={index} className="grid-x align-middle authorization-history__inventory-adjustment__content-item">
                      <label className="small-4 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle">{dayjs(item?.date)?.format('DD-MM-YYYY')}</label>
                      <label className="small-4 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle authorization-history__inventory-adjustment__title">{item?.nameHeadquarter?.toLocaleString()}</label>
                      <label className="small-4 authorization-history__inventory-adjustment__title authorization-history__inventory-adjustment__title--subtitle authorization-history__inventory-adjustment__title--red">${item?.price?.toLocaleString()}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="grid-x justify-content-between authorization-history__totals-inv-adjust__content">
              <div className="align-middle authorization-history__totals-inv-adjust">
                <label className="authorization-history__totals-inv-adjust__text">Saldo permitido</label>
                <div className="grid-x align-middle justify-content-end authorization-history__totals-inv-adjust__box">
                  <span className="authorization-history__totals-inv-adjust__text">${userAuthorizedSelected?.authorizedCash?.toLocaleString()}</span>
                </div>
              </div>
              <div className="align-middle authorization-history__totals-inv-adjust">
                <label className="authorization-history__totals-inv-adjust__text authorization-history__totals-inv-adjust__text--green">Total entradas</label>
                <div className="grid-x align-middle justify-content-end authorization-history__totals-inv-adjust__box authorization-history__totals-inv-adjust__box--green">
                  <span className="authorization-history__totals-inv-adjust__text authorization-history__totals-inv-adjust__text--green">${totalReceipt?.totalReceipt?.toLocaleString()}</span>
                </div>
              </div>
              <div className="align-middle authorization-history__totals-inv-adjust">
                <label className="authorization-history__totals-inv-adjust__text authorization-history__totals-inv-adjust__text--red">Total salidas</label>
                <div className="grid-x align-middle justify-content-end authorization-history__totals-inv-adjust__box authorization-history__totals-inv-adjust__box--mod">
                  <span className="authorization-history__totals-inv-adjust__text authorization-history__totals-inv-adjust__text--red">${(totalExpense?.totalExpense?.toLocaleString())}</span>
                </div>
              </div>
              <div className="align-middle authorization-history__totals-inv-adjust">
                <label className="authorization-history__totals-inv-adjust__text">TOTAL</label>
                <div className="grid-x align-middle justify-content-end authorization-history__totals-inv-adjust__box">
                  <span className="authorization-history__totals-inv-adjust__text">${(totalReceipt?.totalReceipt - (totalExpense?.totalExpense)).toLocaleString()}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="small-8">
            <div className="authorization-history__loans">
              <div className="grid-y">
                <span className="authorization-history__loans__title">Historial de prestamos</span>
                <span className="authorization-history__loans__text">Histórico de prestamos y abonos del usuario</span>
              </div>
              <div className="grid-x authorization-history__loans__table">
                <label className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle">Número de identidad</label>
                <label className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle">Valor de préstamo</label>
                <label className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle">Pagado a la fecha</label>
                <label className="small-3 authorization-history__loans__title authorization-history__loans__title--subtitle">Deuda a la fecha</label>
              </div>
              <div className="authorization-history__registration__container">
                <Collapse
                  accordion
                  expandIconPosition="end"
                  items= { userAuthorizedSelected?.lendingHistory?.map((item,index)=>{
                    const debtDate = (item?.totalPriceCashExpenseTrust - item?.totalPriceCashReceiptPaymentOfBondsmen)
                    return ({
                      key: index,
                      label: (
                        <div className="grid-x">
                          <div className="grid-x authorization-history__title-number">
                            <img src={Assets.SharedIcons.icon_document} alt="icon_document" className="authorization-history__total__icon authorization-history__total__icon--mod"/>
                            <label className="authorization-history__loans__text">{item?.concept}</label>
                          </div>
                          <label 
                            className="authorization-history__loans__title authorization-history__loans__title--subtitle authorization-history__loans__title--red"
                          >
                            ${(item?.totalPriceCashExpenseTrust)?.toLocaleString()}
                          </label>
                          <label 
                            className="authorization-history__loans__title authorization-history__loans__title--subtitle authorization-history__loans__title--green"
                          >
                            ${(item?.totalPriceCashReceiptPaymentOfBondsmen)?.toLocaleString()}
                          </label>
                          <label 
                            className="authorization-history__loans__title authorization-history__loans__title--subtitle"
                          >
                            ${debtDate?.toLocaleString()}
                          </label>
                        </div>
                      ),
                      children: (
                        <div className="grid-x authorization-history__registration__children">
                          <div className="authorization-history__registration__children__loan">
                            <div className="authorization-history__registration__children__content-titles">
                              <span className="authorization-history__registration__children__titles__title-date">Fecha</span>
                              <span className="authorization-history__registration__children__titles__title-cost">Sede</span>
                              <span className="authorization-history__registration__children__titles__title-cost">Salidas</span>
                            </div>
                            <div className="authorization-history__registration__children__content-info">
                              {item.expenseList?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map((item, dataIndex) => (
                                <div key={dataIndex} className="authorization-history__registration__children__content-titles">
                                  <span className="authorization-history__registration__children__titles__title-date authorization-history__registration__children__titles__title-date--mod">
                                    {dayjs(item?.date)?.format('DD-MM-YYYY')}
                                  </span>
                                  <span className="authorization-history__registration__children__titles__title-cost authorization-history__registration__children__titles__title-cost--mod">
                                    {item?.nameHeadquarter?.toLocaleString()}
                                  </span>
                                  <span className="authorization-history__registration__children__titles__title-cost authorization-history__registration__children__titles__title-cost--mod">
                                    ${item?.price?.toLocaleString()}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="authorization-history__registration__children__loan">
                            <div className="authorization-history__registration__children__content-titles">
                              <span className="authorization-history__registration__children__titles__title-date">Fecha</span>
                              <span className="authorization-history__registration__children__titles__title-cost">Sede</span>
                              <span className="authorization-history__registration__children__titles__title-cost">Entradas</span>
                            </div>
                            <div className="authorization-history__registration__children__content-info">
                              {item.receiptList?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map((item, dataIndex) => (
                                <div key={dataIndex} className="authorization-history__registration__children__content-titles">
                                  <span className="authorization-history__registration__children__titles__title-date authorization-history__registration__children__titles__title-date--mod">
                                    {dayjs(item?.date)?.format('DD-MM-YYYY')}
                                  </span>
                                  <span className="authorization-history__registration__children__titles__title-cost authorization-history__registration__children__titles__title-cost--mod">
                                    {item?.nameHeadquarter?.toLocaleString()}
                                  </span>
                                  <span className="authorization-history__registration__children__titles__title-cost authorization-history__registration__children__titles__title-cost--mod">
                                    ${item?.price?.toLocaleString()}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  })}
                />
              </div>
            </div>
            <div className="grid-x justify-content-between authorization-history__total__content">
              <div className="grid-x justify-content-between align-middle authorization-history__total">
                <label className="authorization-history__total__text">Saldo permitido</label>
                <div className="grid-x align-middle authorization-history__total__box justify-content-end">
                  <span className="authorization-history__total__text">${userAuthorizedSelected?.authorizedCash?.toLocaleString()}</span>
                </div>
              </div>
              <div className="grid-x justify-content-between align-middle authorization-history__total">
                <label className="authorization-history__total__text">Deuda actual</label>
                <div className="grid-x justify-content-between authorization-history__total__box align-middle authorization-history__total__box--mod">
                  <img
                    alt="icon_egress"
                    src={Assets.SharedIcons.icon_egress}
                    className="authorization-history__total__icon"
                  />
                  <span className="authorization-history__total__text authorization-history__total__text--red">${(routeState?.currentDebtAuthorized)?.toLocaleString()}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToPropsActions = {
  setStateAuthorizedManagementReducer
};

const mapStateToProps = ({ AuthorizedManagementReducer }) => {
  const { userAuthorizedSelected } = AuthorizedManagementReducer;
  return { userAuthorizedSelected };
};

export default connect(mapStateToProps, mapStateToPropsActions)(AuthorizationHistoryPage);