// Actions
import { setStateGeneralAdjustmentReducer } from "../../../../../storage/reducers/headquarters/headquarters.actions";

// Assets
import { Assets } from "../../../../../assets";

// Libraries
import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { connect } from "react-redux";
import { NumericFormat } from "react-number-format";

// Styles
import "./industry-and-commerce.component.scss";

const IndustryAndCommerceComponent = (props) => {

  const {
    // Actions
    setStateGeneralAdjustmentReducer,
    // Variables
    generalAdjustment
  } = props;


  const INITIAL_STATE = {
    infoIndustryAndCommerce: {
      total: 0,
      listOfItems: [],
      title: 'Industria y Comercio',
    }
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { infoIndustryAndCommerce } = state;

  useEffect(() => {
    setState({
      ...state,
      infoIndustryAndCommerce: {
        total: generalAdjustment?.organizeTaxesByType[0]?.data?.reduce((acc, industry) => acc + industry.tax, 0),
        title: generalAdjustment?.organizeTaxesByType[0]?.title,
        listOfItems: generalAdjustment?.organizeTaxesByType[0]?.data,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalAdjustment?.organizeTaxesByType]);

  const onChangeInput = (data, index, name) => {
    let value = data && data.target ? data.target.value || '' : data;

    if (/^\d*\.?\d*$/.test(value)) {

      const copyIndustryAndCommerce = JSON.parse(JSON.stringify(infoIndustryAndCommerce?.listOfItems));
      copyIndustryAndCommerce[index][name] = value;
      setState({
        ...state,
        infoIndustryAndCommerce: {
          ...infoIndustryAndCommerce,
          listOfItems: copyIndustryAndCommerce
        }
      });
      setStateGeneralAdjustmentReducer('changeGeneralAdjustment', 'industryAndCommerce', copyIndustryAndCommerce);

    }
  };

  return (
    <div className="industry-and-commerce__content-tables__table">
      <div className="grid-x align-middle justify-content-between industry-and-commerce__content-tables__content-values">
        <div className="grid-x align-middle">
          <img alt="icon_information" src={Assets.SharedIcons.icon_information} />
          <span className="industry-and-commerce__content-tables__title">
            {infoIndustryAndCommerce.title}
          </span>
        </div>
        <div className="grid-x align-middle">
          <span className="industry-and-commerce__content-tables__title">
            Total:
          </span>
          <div className="grid-x industry-and-commerce__content-tables__values--mod">
            <div className="industry-and-commerce__content-tables__values__content-icon">
              <img
                alt="icon_egress"
                src={Assets.SharedIcons.icon_egress}
                className="industry-and-commerce__content-tables__values__content-icon__icon"
              />
            </div>
            <NumericFormat
              disabled
              type="text"
              prefix={"$ "}
              placeholder="$ 0"
              thousandSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              className="input-price"
              thousandsGroupStyle="thousand"
              value={ Number.isInteger(infoIndustryAndCommerce.total) ? infoIndustryAndCommerce.total : infoIndustryAndCommerce.total.toFixed(2) }
            />
          </div>
        </div>
      </div>
      <div className="grid-x small-12 industry-and-commerce__content-tables__content-info">
        <div className="grid-x align-content-end industry-and-commerce__content-tables__content-info__content-title">
          <span className="small-2 industry-and-commerce__content-tables__content-info__title">
            Mes
          </span>
          <span className="small-3 industry-and-commerce__content-tables__content-info__title">
            Concepto
          </span>
          <span className="small-3 industry-and-commerce__content-tables__content-info__title">
            Ventas del mes
          </span>
          <span className="small-1 industry-and-commerce__content-tables__content-info__title">
            %
          </span>
          <span className="grid-x justify-content-end small-3 industry-and-commerce__content-tables__content-info__title">
            Valor
          </span>
        </div>
        <div className="industry-and-commerce__content-tables__content-info__content-scroll">
          {infoIndustryAndCommerce.listOfItems.map((item, index) => {
            return (
              <div
                key={index}
                className="grid-x align-middle industry-and-commerce__content-tables__content-info__information-row"
              >
                <div className='small-2 industry-and-commerce__content-tables__content-input'>
                  <Input
                    disabled
                    prefix={null}
                    placeholder="Mes"
                    value={item?.month}
                  />
                </div>
                <div className='small-3 industry-and-commerce__content-tables__content-input'>
                  <Input
                    disabled
                    prefix={null}
                    value={item?.concept}
                    placeholder="Descripción"
                  />
                </div>
                <div className='small-3 industry-and-commerce__content-tables__content-input'>
                  <Input
                    disabled
                    prefix={null}
                    placeholder="Ventas del mes"
                    value={item?.total?.toLocaleString()}
                  />
                </div>
                <div className={`small-1 industry-and-commerce__content-tables__content-input${!item?.edit ? "" : "__disabled"}`}>
                  <Input
                    prefix={null}
                    placeholder="%"
                    disabled={!item?.edit}
                    value={item?.taxPorcentage}
                    onChange={(value) => onChangeInput(value, index, "taxPorcentage")}
                  />
                </div>
                <div className='grid-x small-3 justify-content-end industry-and-commerce__content-tables__content-input'>
                  <NumericFormat
                    disabled
                    type="text"
                    prefix={"$ "}
                    placeholder="$ 0"
                    value={Number.isInteger(item?.tax) ? item?.tax : item?.tax?.toFixed(2)}
                    thousandSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className="input-price"
                    thousandsGroupStyle="thousand"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ HeadquartersReducer }) => {
  const { generalAdjustment } = HeadquartersReducer;

  return {
    generalAdjustment
  };
};

const mapStateToPropsActions = {
  setStateGeneralAdjustmentReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(IndustryAndCommerceComponent);

