import * as ExcelJS from 'exceljs';

//Libraries
import dayjs from 'dayjs';

//Utils
import { formattedNumber } from '../utils/shared.utils';

export function dailyMovementsExcelUtils(props) {
  const {
    transactions, dateRange
  } = props;

  const workbook = new ExcelJS.Workbook();

  transactions.forEach((transactionData, index) => {

    const transactionDate = transactionData.dailyTransaction.date;
    const formattedDate = dayjs(transactionDate).format('YYYY-MM-DD');
    const worksheet = workbook.addWorksheet(`Movimientos ${formattedDate}`);

    function setCellBorder(row) {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF888888' } },
          left: { style: 'thin', color: { argb: 'FF888888' } },
          bottom: { style: 'thin', color: { argb: 'FF888888' } },
          right: { style: 'thin', color: { argb: 'FF888888' } },
        };
      });
    }

    worksheet.getCell('A1').value = 'Totalizadores';
    worksheet.getCell('A1').font = { bold: true };
    setCellBorder(worksheet.getRow(1));

    const totalizerTitles = [
      { title: 'Saldo del día anterior', value: transactionData.dailyTransaction.previousBalance },
      { title: 'Efectivo final', value: transactionData.dailyTransaction.cashReceived - transactionData.dailyTransaction.cashOutflow + transactionData.dailyTransaction.previousBalance },
      { title: 'Total de efectivo recibido', value: transactionData.dailyTransaction.cashReceived },
      { title: 'Total de salidas de efectivo', value: transactionData.dailyTransaction.cashOutflow },
    ];

    totalizerTitles.forEach((totalizer, index) => {
      const rowNumber = index + 2;
      const row = worksheet.getRow(rowNumber);

      const formattedValue = formattedNumber(totalizer.value);
      const cellA = worksheet.getCell(`A${rowNumber}`);
      const cellB = worksheet.getCell(`B${rowNumber}`);

      cellA.value = totalizer.title;
      cellA.font = { bold: true };
      cellB.value = formattedValue;

      let fillColor;
      if (totalizer.title === 'Total de salidas de efectivo') {
        fillColor = totalizer.value >= 0 ? 'ffcbc8' : 'c8ffd8';
      } else {
        fillColor = totalizer.value >= 0 ? 'c8ffd8' : 'ffcbc8';
      }

      cellB.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: fillColor },
      };

      setCellBorder(row);
    });

    const startRow = totalizerTitles.length + 5;
    const headers = ['Sede', 'Concepto', 'Categoria', 'Subcategoria', 'Tipo de pago', 'Valor'];
    headers.forEach((header, index) => {
      const cell = worksheet.getCell(`${String.fromCharCode(65 + index)}${startRow}`);
      cell.value = header;
      cell.font = { bold: true };
    });

    setCellBorder(worksheet.getRow(startRow));

    transactionData.transactions.forEach((transaction, index) => {
      const rowNumber = index + startRow + 1;

      worksheet.getCell(`A${rowNumber}`).value = transaction?.headQuarterTransaction?.name || 'Nombre de sede no disponible';
      worksheet.getCell(`B${rowNumber}`).value = transaction?.concept || 'Concepto no disponible';
      worksheet.getCell(`C${rowNumber}`).value = transaction?.expenseSubcategoryTransaction?.expenseCategoryExpenseSubcategory?.name || '';
      worksheet.getCell(`D${rowNumber}`).value = transaction?.expenseSubcategoryTransaction?.name || '';
      worksheet.getCell(`E${rowNumber}`).value = transaction?.paymentMethodDailyTransactionTransaction?.name || 'Tipo de pago no disponible';
      worksheet.getCell(`F${rowNumber}`).value = transaction?.price || 0;

      const isCredit = transaction?.isCredit;
      const conceptoCell = worksheet.getCell(`B${rowNumber}`);
      const valorCell = worksheet.getCell(`F${rowNumber}`);

      if (isCredit === 1) {
        conceptoCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'c8ffd8' }
        };
        valorCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'c8ffd8' }
        };
      } else {
        conceptoCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffcbc8' }
        };
        valorCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ffcbc8' }
        };
      }

      setCellBorder(worksheet.getRow(rowNumber));
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellLength = cell.value ? cell.value.toString().length : 10;
        if (cellLength > maxLength) {
          maxLength = cellLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const formattedStartDate = dateRange.startDate
    const formattedEndDate = dateRange.endDate
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${formattedStartDate} - ${formattedEndDate}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
}
