import * as ExcelJS from 'exceljs';

export function finalBalanceExcelUtils(fileExcelData, startDate, endDate) {

    const workbook = new ExcelJS.Workbook();

    fileExcelData.forEach((fileExcel, index) => {

        const reportDate = fileExcel.date;
        const sheet = workbook.addWorksheet(`Reporte ${reportDate}`);

        const defaultColumnWidths = {
            1: 20,
            2: 20,
            3: 20,
            4: 20,
            5: 20,
            6: 20,
            7: 30,
            8: 20
        };

        Object.entries(defaultColumnWidths).forEach(([col, width]) => {
            const columnIndex = parseInt(col, 10);
            sheet.getColumn(columnIndex).width = width;
        });

        let currentRowTotalizers = 1;
        const colors = ['0e7d80', 'f85f61', '1499F2'];

        fileExcel.totalizers.forEach((item, index) => {

            const titleCell = sheet.getCell(currentRowTotalizers, index * 2 + 1);
            titleCell.value = item.title;
            titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
            titleCell.font = { bold: true, color: { argb: 'FFFFFF' } };
            titleCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: colors[index] } };
            titleCell.border = {
                top: { style: 'medium', color: { argb: colors[index] } },
                left: { style: 'medium', color: { argb: colors[index] } },
                bottom: { style: 'medium', color: { argb: colors[index] } },
                right: { style: 'medium', color: { argb: colors[index] } }
            };

            const valueCell = sheet.getCell(currentRowTotalizers + 1, index * 2 + 1);
            valueCell.value = item.total;
            valueCell.alignment = { horizontal: 'center', vertical: 'middle' };
            valueCell.font = { bold: true };
            valueCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } };
            valueCell.border = {
                top: { style: 'medium', color: { argb: colors[index] } },
                left: { style: 'medium', color: { argb: colors[index] } },
                bottom: { style: 'medium', color: { argb: colors[index] } },
                right: { style: 'medium', color: { argb: colors[index] } }
            };
        });

        currentRowTotalizers += 2;

        const addConceptData = (concept, colStart) => {
            let currentRow = 5;

            const conceptTitleCell = sheet.getCell(currentRow, colStart);
            conceptTitleCell.value = concept.conceptTitle;
            conceptTitleCell.font = { bold: true };
            conceptTitleCell.alignment = { horizontal: 'center', vertical: 'middle' };
            conceptTitleCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F4F6' } };

            sheet.mergeCells(currentRow, colStart, currentRow, colStart + 2);
            sheet.getRow(currentRow).height = 30;
            currentRow += 2;

            const mergedRange = sheet.getCell(currentRow - 2, colStart);
            mergedRange.alignment = { horizontal: 'center', vertical: 'middle' };

            concept.tables.forEach(table => {
                const tableTitleCell = sheet.getCell(currentRow, colStart);
                tableTitleCell.value = table.title;
                tableTitleCell.font = { bold: true };
                tableTitleCell.alignment = { horizontal: 'center' };
                sheet.mergeCells(currentRow, colStart, currentRow, (table.rowTableTitles.length === 3) ? colStart + 2 : colStart + 1);
                currentRow++;

                table.rowTableTitles.forEach((title, index) => {
                    const cell = sheet.getCell(currentRow, colStart + index);
                    cell.value = title;
                    cell.font = { bold: true, color: { argb: 'FFFFFF' } };
                    cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1499F2' } };

                    if (index === 0) {
                        cell.alignment = { horizontal: 'left', vertical: 'middle' };
                    } else if (index === table.rowTableTitles.length - 1) {
                        cell.alignment = { horizontal: 'right', vertical: 'middle' };
                    } else {
                        cell.alignment = { horizontal: 'center', vertical: 'middle' };
                    };

                    cell.border = {
                        top: { style: 'medium', color: { argb: '1499F2' } },
                        left: { style: 'medium', color: { argb: '1499F2' } },
                        bottom: { style: 'medium', color: { argb: '1499F2' } },
                        right: { style: 'medium', color: { argb: '1499F2' } }
                    };

                });

                sheet.getRow(currentRow).height = 24;
                currentRow++;

                table.tableList.forEach(item => {
                    if (table.rowTableTitles.length === 3) {
                        const headQuarterCell = sheet.getCell(currentRow, colStart);
                        const conceptCell = sheet.getCell(currentRow, colStart + 1);
                        const totalPriceCell = sheet.getCell(currentRow, colStart + 2);

                        headQuarterCell.value = item.headQuarter;
                        conceptCell.value = item.concept || item.bank;
                        totalPriceCell.value = item.totalPrice;

                        [headQuarterCell, conceptCell, totalPriceCell].forEach(cell => {
                            cell.border = {
                                top: { style: 'medium', color: { argb: '1499F2' } },
                                left: { style: 'medium', color: { argb: '1499F2' } },
                                bottom: { style: 'medium', color: { argb: '1499F2' } },
                                right: { style: 'medium', color: { argb: '1499F2' } }
                            };
                        });

                    } else {
                        const headQuarterCell = sheet.getCell(currentRow, colStart);
                        const totalPriceCell = sheet.getCell(currentRow, colStart + 1);

                        headQuarterCell.value = item.headQuarter;
                        totalPriceCell.value = item.totalPrice;

                        [headQuarterCell, totalPriceCell].forEach(cell => {
                            cell.border = {
                                top: { style: 'medium', color: { argb: '1499F2' } },
                                left: { style: 'medium', color: { argb: '1499F2' } },
                                bottom: { style: 'medium', color: { argb: '1499F2' } },
                                right: { style: 'medium', color: { argb: '1499F2' } }
                            };
                        });
                    };

                    currentRow++;
                });

                if (table.rowTableTitles.length === 3) {
                    const totalCell = sheet.getCell(currentRow, colStart);
                    totalCell.value = table.total;
                    sheet.mergeCells(currentRow, colStart, currentRow, colStart + 1);
                    totalCell.alignment = { horizontal: 'right', vertical: 'middle' };
                    totalCell.font = { bold: true, color: { argb: 'FFFFFF' } };
                    totalCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '1499F2' } };

                    totalCell.border = {
                        top: { style: 'medium', color: { argb: '1499F2' } },
                        left: { style: 'medium', color: { argb: '1499F2' } },
                        bottom: { style: 'medium', color: { argb: '1499F2' } },
                        right: { style: 'medium', color: { argb: '1499F2' } }
                    };

                    const totalTableCell = sheet.getCell(currentRow, colStart + 2);
                    totalTableCell.value = table.totalTable;
                    totalTableCell.alignment = { vertical: 'middle' };
                    totalTableCell.font = { bold: true };

                    totalTableCell.border = {
                        top: { style: 'medium', color: { argb: '1499F2' } },
                        left: { style: 'medium', color: { argb: '1499F2' } },
                        bottom: { style: 'medium', color: { argb: '1499F2' } },
                        right: { style: 'medium', color: { argb: '1499F2' } }
                    };

                    sheet.getRow(currentRow).height = 20;
                } else {
                    const totalCell = sheet.getCell(currentRow, colStart);
                    totalCell.value = table.total;
                    totalCell.alignment = { horizontal: 'right', vertical: 'middle' };
                    totalCell.font = { bold: true, color: { argb: 'FFFFFF' } };
                    totalCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '1499F2' }
                    };

                    totalCell.border = {
                        top: { style: 'medium', color: { argb: '1499F2' } },
                        left: { style: 'medium', color: { argb: '1499F2' } },
                        bottom: { style: 'medium', color: { argb: '1499F2' } },
                        right: { style: 'medium', color: { argb: '1499F2' } }
                    };

                    const totalTableCell = sheet.getCell(currentRow, colStart + 1);
                    totalTableCell.value = table.totalTable;
                    totalTableCell.alignment = { vertical: 'middle' };
                    totalTableCell.font = { bold: true };

                    totalTableCell.border = {
                        top: { style: 'medium', color: { argb: '1499F2' } },
                        left: { style: 'medium', color: { argb: '1499F2' } },
                        bottom: { style: 'medium', color: { argb: '1499F2' } },
                        right: { style: 'medium', color: { argb: '1499F2' } }
                    };

                    sheet.getRow(currentRow).height = 20;

                    currentRow++;
                    currentRow += 1;

                    const titleRealCashCell = sheet.getCell(currentRow, colStart);
                    titleRealCashCell.value = table.realCash;
                    titleRealCashCell.alignment = { horizontal: 'right', vertical: 'middle' };
                    titleRealCashCell.font = { bold: true, color: { argb: 'FFFFFF' } };
                    titleRealCashCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '1499F2' }
                    };

                    titleRealCashCell.border = {
                        top: { style: 'medium', color: { argb: '1499F2' } },
                        left: { style: 'medium', color: { argb: '1499F2' } },
                        bottom: { style: 'medium', color: { argb: '1499F2' } },
                        right: { style: 'medium', color: { argb: '1499F2' } }
                    };

                    const totalRealCashCell = sheet.getCell(currentRow, colStart + 1);
                    totalRealCashCell.value = table.totalRealCash;
                    totalRealCashCell.alignment = { vertical: 'middle' };
                    totalRealCashCell.font = { bold: true };

                    totalRealCashCell.border = {
                        top: { style: 'medium', color: { argb: '1499F2' } },
                        left: { style: 'medium', color: { argb: '1499F2' } },
                        bottom: { style: 'medium', color: { argb: '1499F2' } },
                        right: { style: 'medium', color: { argb: '1499F2' } }
                    };

                    sheet.getRow(currentRow).height = 30;
                }

                currentRow += 2;
            });
        };

        addConceptData(fileExcel.fileExcel[0], 1);

        addConceptData(fileExcel.fileExcel[1], 5);

    });

    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${startDate} - ${endDate}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}