// Assets
import { Assets } from '../../../assets';

// Components
import UsersPermissionsComponent from './components/users-permissions/users-permissions.component';
import BusinessHeadquartersComponent from './components/business-headquarters/business-headquarters.component';
import { ConstantsPanelComponent } from './components/constants-panel/constants-panel.component';
import { ExpenseAccountComponent } from './components/expense-account/expense-account.component';
import { WalletRuleComponent } from './components/wallet-rule/wallet-rule.component';

// Libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Styles
import './admin-panel.page.scss';

const AdminPanelPage = () => {

    const { tab } = useParams();

    const INITIAL_STATE = {
        hoverIndex: null,
        optionSelected: 0,
    };

    const [state, setState] = useState(INITIAL_STATE);
    const { hoverIndex, optionSelected } = state;

    useEffect(() => {
        const tabValue = parseInt(tab, 10); 
        if (!isNaN(tabValue)) {
            setState((prevState) => ({
                ...prevState,
                optionSelected: tabValue,
            }));
        }
    }, [tab]);

    const tabs = [
        {
            label: 'Usuarios y permisos',
            iconAlt: { active: 'icon_summary', inactive: 'icon_summary_inactive', hover: 'icon_summary_active' },
            iconSrc: { active: Assets.SharedIcons.icon_summary, inactive: Assets.SharedIcons.icon_summary_inactive, hover: Assets.SharedIcons.icon_summary_active },
        },
        {
            label: 'Sedes o negocios',
            iconAlt: { active: 'icon_headquarters', inactive: 'icon_headquarters_inactive', hover: 'icon_headquarters_active' },
            iconSrc: { active: Assets.SharedIcons.icon_headquarters, inactive: Assets.SharedIcons.icon_headquarters_inactive, hover: Assets.SharedIcons.icon_headquarters_active },
        },
        {
            label: 'Constantes',
            iconAlt: { active: 'icon_headquarters', inactive: 'icon_headquarters_inactive', hover: 'icon_headquarters_active' },
            iconSrc: { active: Assets.SharedIcons.icon_headquarters, inactive: Assets.SharedIcons.icon_headquarters_inactive, hover: Assets.SharedIcons.icon_headquarters_active },
        },
        {
            label: 'Gastos',
            iconAlt: { active: 'icon_headquarters', inactive: 'icon_headquarters_inactive', hover: 'icon_headquarters_active' },
            iconSrc: { active: Assets.SharedIcons.icon_headquarters, inactive: Assets.SharedIcons.icon_headquarters_inactive, hover: Assets.SharedIcons.icon_headquarters_active },
        },
        {
            label: 'Carteras',
            iconAlt: { active: 'icon_headquarters', inactive: 'icon_headquarters_inactive', hover: 'icon_headquarters_active' },
            iconSrc: { active: Assets.SharedIcons.icon_headquarters, inactive: Assets.SharedIcons.icon_headquarters_inactive, hover: Assets.SharedIcons.icon_headquarters_active },
        }
    ];

    const validatePropsHoverTab = (index, typeIcon, typeAlt) => {
        const states = {
            hover: { alt: typeAlt.hover, src: typeIcon.hover, typeText: '__hover' },
            active: { alt: typeAlt.active, src: typeIcon.active, typeText: '__active' },
            inactive: { alt: typeAlt.inactive, src: typeIcon.inactive, typeText: '__inactive' }
        };
    
        const state = hoverIndex === index ? 'hover' : optionSelected === index ? 'active' : 'inactive';
    
        return states[state];
    };

    return (
        <div className='admin-panel__container'>
            <div className='grid-x align-middle justify-content-between admin-panel__content-options'>
                <div className='grid-x align-middle justify-content-between admin-panel__content-options__content-buttons'>
                    {tabs.map((tab, index) => {
                        return (
                            <button
                                key={index}
                                onMouseOver={() => setState({ ...state, hoverIndex: index })}
                                onMouseOut={() => setState({ ...state, hoverIndex: null })}
                                onClick={() => setState({ ...state, optionSelected: index })}
                                className={`admin-panel__content-options__buttons${optionSelected === index ? '__active' : ''}`}
                            >
                                <img
                                    alt={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).alt}
                                    src={validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).src}
                                    className='admin-panel__content-options__icon-button'
                                />
                                <span className={`admin-panel__content-options__buttons__text${validatePropsHoverTab(index, tab.iconSrc, tab.iconAlt).typeText}`}>
                                    {tab.label}
                                </span>
                            </button>
                        )
                    })}
                </div>
            </div>
            <div className='admin-panel__content-info'>
                {optionSelected === 0 && <UsersPermissionsComponent />}
                {optionSelected === 1 && <BusinessHeadquartersComponent />}
                {optionSelected === 2 && <ConstantsPanelComponent />}
                {optionSelected === 3 && <ExpenseAccountComponent />}
                {optionSelected === 4 && <WalletRuleComponent />}
            </div>
        </div>
    )
}

export default AdminPanelPage;