// Components
import IndustryAndCommerceComponent from "../industry-and-commerce/industry-and-commerce.component";
import RetentionComponent from "../retention/retention.component";
import ProvisionForConsumptionComponent from "../provision-for-consumption/provision-for-consumption.component";
import ProvisionForVatComponent from "../provision-for-vat/provision-for-vat.component";
import HeadquartersExpensesComponent from "../headquarters-expenses/headquarters-expenses.component";

// Libraries
import React from "react";
import { connect } from "react-redux";

// Styles
import "./general-adjustment.component.scss";

const GeneralAdjustmentComponent = ( props ) => {

  const {
    // Variables
    generalAdjustment,
  } = props;

  return (
    <div className="grid-x" style={{ marginTop: "20px" }}>
      <div className="grid-x align-left align-content-start small-6">
        <div className="grid-x align-center-middle general-adjustment__box">
          <span className="general-adjustment__title">GASTOS DE LA SEDE</span>
        </div>
        <div className="general-adjustment__content">
        <HeadquartersExpensesComponent headquarters={generalAdjustment.generalAdjustmentInfo}/>
        </div>
      </div>
      <div className="grid-x align-right align-content-start small-6">
        <div className="grid-x align-center-middle general-adjustment__box">
          <span className="general-adjustment__title">
            PROVISIONES DE IMPUESTOS Y RETENCIONES
          </span>
        </div>
        <div className="general-adjustment__content">
          <IndustryAndCommerceComponent/>
          <RetentionComponent/>
          {generalAdjustment?.consumptionProvisions?.consumptionProvisionByMonths.map( value => <ProvisionForConsumptionComponent key={value.type} consumptionProvisionByMonths={value}/> ) }
          <ProvisionForVatComponent/>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ HeadquartersReducer }) => {
  const { generalAdjustment } = HeadquartersReducer;

  return {
    generalAdjustment
  };
};

export default connect(mapStateToProps, null)(GeneralAdjustmentComponent);
