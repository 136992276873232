//Actions
import { setStatePortfolioReducer } from "../../../../../storage/reducers/portfolio/portfolio.actions";

//Assets
import { Assets } from "../../../../../assets";

//Libraries
import { connect } from "react-redux";
import React from "react";
import { Input, Checkbox } from "antd";

//Styles
import "./tables-portfolio.component.scss";

//Utils
import { formattedNumber } from "../../../../../utils/shared.utils";

const TablesPortfolio = (props) => {
  const {
    filterInput,
    setStatePortfolioReducer,
    titleTable,
    idTable,
    headquartersList,
    handleCheckboxChange,
    isItemChecked,
    viewPortfolio,
  } = props;

  const handlePortfolioFilter = (value) => {
    setStatePortfolioReducer("filterInput", {
      ...filterInput,
      [`${idTable}`]: value,
    });
  };

  const dataFilter = (filter) => {
    const filteredData = headquartersList?.filter((item) =>
      item?.name?.toLowerCase().includes(filter?.toLowerCase())
    );
    return filteredData || [];
  };

  return (
    <div className="grid-x tables-portfolio__container-headquarters">
      <div className="grid-x small-12 tables-portfolio__content-search">
        <span className="tables-portfolio__content-search__title">
          {`${titleTable}`}
        </span>
        <Input
          onChange={(e) => handlePortfolioFilter(e.target.value)}
          placeholder="Buscar por sede o negocio"
          className="tables-portfolio__content-search__input"
          prefix={
            <img
              src={Assets.SharedIcons.icon_search_magnifier}
              alt="icon_search_magnifier"
            />
          }
          value={filterInput[`${idTable}`] || ""}
        />
      </div>
      <div className="grid-x small-12 tables-portfolio__content-result">
        <div className="grid-x align-middle tables-portfolio__content-result__container-title">
          <div className="grid-x justify-content-end tables-portfolio__content-result__title__content--mod">
            <span className="tables-portfolio__content-result__title--mod">
              Nombre de la sede o negocio
            </span>
          </div>
          <span className="tables-portfolio__content-result__title__content tables-portfolio__content-result__title">
            Estado
          </span>
          <div className="grid-x tables-portfolio__content-result__title__content">
            <span className="tables-portfolio__content-result__title">
              Total de Gastos
            </span>
            <img
              src={Assets.SharedIcons.icon_falling_graph}
              alt="icon_falling_graph"
            />
          </div>
          <div className="grid-x tables-portfolio__content-result__title__content">
            <span className="tables-portfolio__content-result__title">
              Total de Abonos
            </span>
            <img
              src={Assets.SharedIcons.icon_income_graph}
              alt="icon_income_graph"
            />
          </div>
          <span className="tables-portfolio__content-result__title__content tables-portfolio__content-result__title">
            Total a la fecha
          </span>
        </div>
        <div className="tables-portfolio__content-result__content-info">
          {dataFilter(filterInput[`${idTable}`] || "").map((headquarters, index) => (
            <div
              key={index}
              className={`grid-x align-middle tables-portfolio__content-result__content-info__information-row${isItemChecked(index) ? "__selected" : ""}`}
            >
              <div className="grid-x tables-portfolio__content-result__content-info__content-fragment--mod">
                <div className="grid-x align-middle justify-content-center tables-portfolio__content-result__content-info__content-checkbox">
                  <Checkbox
                    checked={isItemChecked(index)}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </div>
                <div className="grid-x align-middle tables-portfolio__content-result__content-info__content-location">
                  <span className="tables-portfolio__content-result__content-info__description">{headquarters?.name}</span>
                </div>
              </div>

              <div className="grid-x tables-portfolio__content-result__content-info__content-status tables-portfolio__content-result__content-info__content-fragment">
                <div
                  className={`grid-x align-middle justify-content-center tables-portfolio__content-result__content-info__box-status${headquarters?.isActive ? "__active" : "__inactive"}`}
                >
                  <div
                    className={`tables-portfolio__content-result__content-info__box-status__pin${headquarters?.isActive ? "__active" : "__inactive"}`}
                  />
                  <span>{headquarters?.isActive ? "Activo" : "Inactivo"}</span>
                </div>
              </div>

              <div className="grid-x tables-portfolio__content-result__content-info__content-fragment">
                <span className='tables-portfolio__content-result__content-info__description'>{formattedNumber(headquarters?.cashOutflow)}</span>
              </div>
              <div className="grid-x tables-portfolio__content-result__content-info__content-fragment">
                <span className='tables-portfolio__content-result__content-info__description'>{formattedNumber(headquarters?.cashReceived)}</span>
              </div>
              <div className="grid-x tables-portfolio__content-result__content-info__content-fragment">
                <span className="tables-portfolio__content-result__content-info__description">{formattedNumber(headquarters?.cashOutflow - headquarters?.cashReceived)}</span>
              </div>
              <div className="grid-x justify-content-end tables-portfolio__content-result__content-info__content-fragment--mod-second">
                <button
                  className='grid-x align-middle tables-portfolio__content-result__content-info__button' onClick={() => viewPortfolio(headquarters.id)}>
                  <span>Ver Cartera</span>
                  <img src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
                </button>
              </div>
            </div>
          )
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ PortfolioReducer }) => {
  const { filterInput } = PortfolioReducer;
  return { filterInput };
};

const mapStateToPropsActions = {
  setStatePortfolioReducer,
};
export const TablesPortfolioComponent  = connect(mapStateToProps, mapStateToPropsActions)(TablesPortfolio);