// Actions
import { setStateGeneralAdjustmentReducer } from '../../../../../storage/reducers/headquarters/headquarters.actions';

// Assets
import { Assets } from '../../../../../assets';

// Libraries
import React from 'react'
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { Input } from 'antd';

// Styles
import './projections.component.scss';

const ProjectionsComponent = (props) => {

  const {
    // Actions
    setStateGeneralAdjustmentReducer,
    // Variables
    projections 
  } = props
  
  const onChanges = (data, target) => {

    let value = data && data.target ? data.target.value || '' : data;
    setStateGeneralAdjustmentReducer("projections", target, value)
  }

  return (
    <div className="grid-x">
      <div className="grid-x justify-content-start small-4">
        <div className="projections__content">
          <div className="grid-x align-middle projections__target">
            <img
              src={Assets.HeadquartersIcons.icon_wallet}
              alt="icon_wallet"
              className="projections__icon"
            />
            <span className="projections__title">
              Comportamiento de <strong>Gastos</strong>
            </span>
          </div>
          <div className="projections__container">
            <div className="grid-x justify-content-between align-middle">
              <span className="projections__text">
                Porcentaje de ejecución a la fecha
              </span>
              <Input
                title={Math.ceil(projections?.percentageExpenditure) || 0}
                className="projections__input-percentage"
                disabled
                value={Math.ceil(projections?.percentageExpenditure) || 0}
                suffix="%"
              />
            </div>
            <div className="projections__line"></div>
            <div className="grid-x justify-content-between align-middle projections__payment__content">
              <span className="projections__text">Proyectados</span>
              <div className="grid-x small-5 projections__payment__content-input-price">
                <NumericFormat
                  type="text"
                  prefix={"$ "}
                  placeholder="$ 0"
                  thousandSeparator=","
                  className="input-price"
                  thousandsGroupStyle="thousand"
                  onValueChange={({floatValue}) => onChanges(floatValue, 'expenseProjected')}
                  value={projections?.expenseProjected}
                />
                <div className="projections__payment__content-icon-price">
                  <img
                    className="projections__payment__icon"
                    alt="icon_edit"
                    src={Assets.SharedIcons.icon_edit}
                  />
                </div>
              </div>
            </div>
            <div className="grid-x justify-content-between align-middle projections__payment__content">
              <span className="projections__text">Tesorería</span>
              <div className="grid-x small-5 projections__payment__content-input-price projections__payment__content-input-price--red">
                <NumericFormat
                  type="text"
                  prefix={"$ "}
                  placeholder="$ 0"
                  thousandSeparator=","
                  className="input-price"
                  thousandsGroupStyle="thousand"
                  disabled
                  value={projections?.cashOutflowDailyTransaction}
                />
                <div className="projections__payment__content-icon-price">
                  <img
                    className="projections__payment__icon"
                    alt="icon_egress"
                    src={Assets.SharedIcons.icon_egress}
                  />
                </div>
              </div>
            </div>
            <div className="grid-x justify-content-between align-middle projections__payment__content">
              <span className="projections__text">Cierre de Caja</span>
              <div className="grid-x small-5 projections__payment__content-input-price projections__payment__content-input-price--red">
                <NumericFormat
                  type="text"
                  prefix={"$ "}
                  placeholder="$ 0"
                  thousandSeparator=","
                  className="input-price"
                  thousandsGroupStyle="thousand"
                  disabled
                  value={projections?.cashOutflowReportCashClosing}
                />
                <div className="projections__payment__content-icon-price">
                  <img
                    className="projections__payment__icon"
                    alt="icon_egress"
                    src={Assets.SharedIcons.icon_egress}
                  />
                </div>
              </div>
            </div>
            <div className="grid-x justify-content-between align-middle">
              <span className="projections__text">Total de Gastos</span>
              <div className="grid-x small-5 projections__payment__content-input-price projections__payment__content-input-price--disabled">
                <NumericFormat
                  type="text"
                  prefix={"$ "}
                  placeholder="$ 0"
                  thousandSeparator=","
                  className="input-price"
                  thousandsGroupStyle="thousand"
                  disabled
                  value={projections?.totalExpense}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-x justify-content-center small-4">
        <div className="projections__content">
          <div className="grid-x align-middle projections__target">
            <img
              src={Assets.HeadquartersIcons.icon_sales}
              alt="icon_sales"
              className="projections__icon"
            />
            <span className="projections__title">
              Comportamiento de <strong>Ventas</strong>
            </span>
          </div>
          <div className="projections__container">
            <div className="grid-x justify-content-between align-middle projections__box">
              <span className="projections__text projections__text--mod">
                Porcentaje de cumplimiento a la fecha
              </span>
              <Input
                title={Math.ceil(projections?.percentageSales) || 0}
                className="projections__input-percentage"
                disabled
                value={Math.ceil(projections?.percentageSales) || 0}
                suffix="%"
              />
            </div>
            <div className="grid-x justify-content-between align-middle projections__box">
              <span className="projections__text">
                Porcentaje de desviación a la fecha
              </span>
              <Input
                title={Math.ceil(projections?.salesDeviation) || 0}
                className="projections__input-percentage"
                disabled
                value={Math.ceil(projections?.salesDeviation) || 0}
                suffix="%"
              />
            </div>
            <div className="projections__line"></div>
            <div className="grid-x justify-content-between align-middle projections__payment__content">
              <span className="projections__text">Proyectadas</span>
              <div className="grid-x small-5 projections__payment__content-input-price">
                <NumericFormat
                  type="text"
                  prefix={"$ "}
                  placeholder="$ 0"
                  thousandSeparator=","
                  className="input-price"
                  thousandsGroupStyle="thousand"
                  value={projections?.salesProjected}
                  onValueChange={({floatValue}) => onChanges(floatValue, "salesProjected")}
                />
                <div className="projections__payment__content-icon-price">
                  <img
                    className="projections__payment__icon"
                    alt="icon_edit"
                    src={Assets.SharedIcons.icon_edit}
                  />
                </div>
              </div>
            </div>
            <div className="grid-x justify-content-between align-middle">
              <span className="projections__text">
                Reportadas
              </span>
              <div className="grid-x small-5 projections__payment__content-input-price projections__payment__content-input-price--green">
                <NumericFormat
                  type="text"
                  prefix={"$ "}
                  placeholder="$ 0"
                  thousandSeparator=","
                  className="input-price"
                  thousandsGroupStyle="thousand"
                  disabled
                  value={projections?.cashReceivedReportCashClosing}
                />
                <div className="projections__payment__content-icon-price">
                  <img
                    className="projections__payment__icon"
                    alt="icon_income"
                    src={Assets.SharedIcons.icon_income}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-x justify-content-end small-4">
        <div className="projections__content">
          <div className="grid-x align-middle projections__target">
            <img
              src={Assets.HeadquartersIcons.icon_utils}
              alt="icon_utils"
              className="projections__icon"
            />
            <span className="projections__title">
              Comportamiento de <strong>Utilidades</strong>
            </span>
          </div>
          <div className="projections__container">
            <div className="grid-x justify-content-between align-middle projections__box">
              <span className="projections__text projections__text--mod">
                Porcentaje de cumplimiento a la fecha
              </span>
              <Input
                title={Math.ceil(projections?.percentageProfit) || 0}
                className="projections__input-percentage"
                disabled
                value={Math.ceil(projections?.percentageProfit) || 0}
                suffix="%"
              />
            </div>
            <div className="grid-x justify-content-between align-middle projections__box">
              <span className="projections__text projections__text--mod">
                Porcentaje de desviación a la fecha
              </span>
              <Input
                title={Math.ceil(projections?.percentageProfit) || 0}
                className="projections__input-percentage"
                disabled
                value={Math.ceil(projections?.percentageProfit) || 0}
                suffix="%"
              />
            </div>
            <div className="projections__line"></div>
            <div className="grid-x justify-content-between align-middle projections__payment__content">
              <span className="projections__text">Utilidad TNS Nube</span>
              <div className="grid-x small-5 projections__payment__content-input-price projections__payment__content-input-price--disabled">
                <NumericFormat
                  disabled
                  type="text"
                  prefix={"$ "}
                  placeholder="$ 0"
                  thousandSeparator=","
                  className="input-price"
                  thousandsGroupStyle="thousand"
                  value={projections?.profitTNS}
                />
              </div>
            </div>
            <div className="grid-x justify-content-between align-middle projections__payment__content">
              <span className="projections__text">Utilidad Punto Frio</span>
              <div className="grid-x small-5 projections__payment__content-input-price projections__payment__content-input-price--disabled">
                <NumericFormat
                  disabled
                  type="text"
                  prefix={"$ "}
                  placeholder="$ 0"
                  thousandSeparator=","
                  className="input-price"
                  thousandsGroupStyle="thousand"
                  value={projections?.profitPF}
                />
              </div>
            </div>
            <div className="grid-x justify-content-between align-middle">
              <span className="projections__text">Utilidad Proyectada</span>
              <div className="grid-x small-5 projections__payment__content-input-price">
                <NumericFormat
                  type="text"
                  prefix={"$ "}
                  placeholder="$ 0"
                  thousandSeparator=","
                  className="input-price"
                  thousandsGroupStyle="thousand"
                  value={projections?.profitProjected}
                  onValueChange={({floatValue}) => onChanges(floatValue, "profitProjected")}
                />
                <div className="projections__payment__content-icon-price">
                  <img
                    className="projections__payment__icon"
                    alt="icon_edit"
                    src={Assets.SharedIcons.icon_edit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ HeadquartersReducer }) => {
  const { projections } = HeadquartersReducer;

  return {
    projections
  };
};

const mapStateToPropsActions = {
  setStateGeneralAdjustmentReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ProjectionsComponent);