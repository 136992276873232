//Actions
import { setStateCashClosingReducer } from '../../../../storage/reducers/cash-closing/cash-closing.actions';

//Assets
import { Assets } from '../../../../assets';

//Libraries
import dayjs from "dayjs";
import { connect } from 'react-redux';
import { Select, TimePicker } from 'antd';
import { NumericFormat } from "react-number-format";
import React, { useEffect, useRef, useState } from 'react';

//Services
import { getAllInternalConsumptionCategoriesService, getAllInternalConsumptionProductsService } from '../../../../services/shared.services';
import { deletedRecordInternalConsumptionTableService } from '../../../../services/cash-closing.services';
import { deletedInventoryInternalConsumptionTableService } from '../../../../services/cash-closing-inventory.services';

//Styles
import '../../cash-closing.page.scss';

const InternalConsumptionComponent = (props) => {

  const {
    //Actions
    setStateCashClosingReducer,
    //Variables
    cashClosingData,
    dateInventory,
    //Functions
    getAllPortfolio
} = props

  const INITIAL_STATE = {
    scrollEnd: false,
    listOfCategories: [],
    internalConsumption: [
      { price: '', hour: '', category: { label: '', value: '' }, products: { label: '', value: '' }, listOfProducts: [], disabledProducts: true, disabled: true },
      { price: '', hour: '', category: { label: '', value: '' }, products: { label: '', value: '' }, listOfProducts: [], disabledProducts: true, disabled: true },
      { price: '', hour: '', category: { label: '', value: '' }, products: { label: '', value: '' }, listOfProducts: [], disabledProducts: true, disabled: true },
      { price: '', hour: '', category: { label: '', value: '' }, products: { label: '', value: '' }, listOfProducts: [], disabledProducts: true, disabled: true },
      { price: '', hour: '', category: { label: '', value: '' }, products: { label: '', value: '' }, listOfProducts: [], disabledProducts: true, disabled: true },
      { price: '', hour: '', category: { label: '', value: '' }, products: { label: '', value: '' }, listOfProducts: [], disabledProducts: true, disabled: true },
      { price: '', hour: '', category: { label: '', value: '' }, products: { label: '', value: '' }, listOfProducts: [], disabledProducts: true, disabled: true }
    ]
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { internalConsumption, scrollEnd, listOfCategories } = state;

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, internalConsumption.length]);

  useEffect(() => {
    getListOfCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashClosingData?.cashRegisterDomesticConsumption]);

  useEffect(() => {
    if (cashClosingData?.cashRegisterDomesticConsumption) {
      try {
        let arr1 = [...INITIAL_STATE.internalConsumption];
        let dataMod = [...cashClosingData.cashRegisterDomesticConsumption];
        let arr2 = dataMod.map(async (data) => {
          const respListProducts = await getListOfProducts(data?.productDomesticConsumption?.categoryDomesticConsumptionProduct?.id);
          const { productDomesticConsumption, ...restOfData } = data;
          const listOfProducts = await respListProducts;
          return {
            ...restOfData,
            category: {
              label: data?.productDomesticConsumption?.categoryDomesticConsumptionProduct?.name,
              value: data?.productDomesticConsumption?.categoryDomesticConsumptionProduct?.id,
            },
            products: {
              label: data?.productDomesticConsumption?.name,
              value: data?.productDomesticConsumption?.id
            },
            disabled: false,
            disabledProducts: false,
            listOfProducts
          };
        });

        Promise.all(arr2).then((resolvedArr2) => {
          const minLen = Math.min(arr1.length, resolvedArr2.length);

          for (let i = 0; i < minLen; i++) {
            if (arr1[i].id !== resolvedArr2[i].id) {
              arr1[i] = resolvedArr2[i];
            }
          }

          if (arr1.length < resolvedArr2.length) {
            for (let i = minLen; i < resolvedArr2.length; i++) {
              arr1.push(resolvedArr2[i]);
            }
          }

          setState((prevState) => ({
            ...prevState,
            scrollEnd: false,
            internalConsumption: arr1
          }));
        });
      } catch (error) {
        // TODO: Implement error alert with code error.
      }
    } else {
      setState(INITIAL_STATE)
    }
    formRef.current.scrollTop = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashClosingData?.cashRegisterDomesticConsumption]);

  const addInternalConsumption = () => {
    const objectInputDisabled = internalConsumption.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

    if (!objectInputDisabled) {
      const newArray = [
        ...internalConsumption,
        {
          hour: '',
          price: '',
          category: {
            label: '',
            value: ''
          },
          products: {
            label: '',
            value: ''
          },
          disabled: false,
          disabledProducts: true,
          listOfProducts: []
        }
      ];
      setState((prevState) => ({
        ...prevState,
        scrollEnd: true,
        internalConsumption: newArray
      }))
    } else {
      const newArray = internalConsumption.map(object => (object === objectInputDisabled ? { ...object, disabled: false } : object));
      setState((prevState) => ({
        ...prevState,
        internalConsumption: newArray
      }))
    };
  };

  const handleChange = async (e, index, name) => {
    let value = e && e.target ? e.target.value || '' : e;
    const newArray = JSON.parse(JSON.stringify(internalConsumption));

    if ((name === "category") || (name === "products")) {
      newArray[index][name] = {
        label: value.label,
        value: value.value
      }
      if (name === "category") {
        try {
          const listOfProducts = await getListOfProducts(value.value);
          newArray[index].listOfProducts = listOfProducts;
          newArray[index].disabledProducts = false;
        } catch (error) {
          // TODO: Implement error alert with code error.
        }
      }
    } else {
      newArray[index][name] = value
    };

    setState({
      ...state,
      internalConsumption: newArray
    })
    setStateCashClosingReducer('internalConsumptionForm', newArray);
  };

  const handleTotal = (data) => {
    let totalPrice = 0;
    data.forEach(object => {
      totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0);
    });
    totalPrice = parseFloat(totalPrice.toFixed(2));
    return totalPrice;
  };

  const getListOfCategories = async () => {
    try {
      const listOfCategories = await getAllInternalConsumptionCategoriesService();
      setState((prevState) => ({
        ...prevState,
        listOfCategories
      }))
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const getListOfProducts = async (idCategory) => {
    try {
      return await getAllInternalConsumptionProductsService({ idCategory });
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const removeDataInternalConsumption = async (item, index) => {
    let respService = false;
    const initialItem = {
      hour: '',
      price: '',
      category: {
        label: '',
        value: ''
      },
      products: {
        label: '',
        value: ''
      },
      disabled: true,
      disabledProducts: true,
      listOfProducts: []
    }
    const newArray = JSON.parse(JSON.stringify(internalConsumption));
    newArray[index] = initialItem;
    try {
        if (item.id) {
          if(dateInventory){
            respService = await deletedInventoryInternalConsumptionTableService({ id: item.id });
          }else{
            respService = await deletedRecordInternalConsumptionTableService({ id: item.id });
          }
        };
        newArray.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);
        setState({ ...state, internalConsumption: newArray });
        setStateCashClosingReducer('internalConsumptionForm', newArray);
        (item?.id && respService) && await getAllPortfolio();
    } catch (error) {
        // TODO: Implement error alert with code error.
    }
};
  
  return (
    <div className='cash-closing__payments__box-padding'>
      <div className='cash-closing__payments__container'>
        <div className='cash-closing__payments__content'>
          <div className='grid-y'>
            <div className='grid-x cash-closing__payments__content-titles__fist'>
              <div className='grid-x align-middle small-6'>
                <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                <span className='cash-closing__payments__titles cash-closing__payments__titles__space'>Consumo interno</span>
              </div>
              <div className='grid-x align-middle small-6 justify-content-end'>
                <span className='cash-closing__payments__titles'>Total:</span>
                <div className='grid-x align-middle cash-closing__summary__details'>
                  <div className='cash-closing__payments__table__content-icon-price'>
                    <img
                      alt="icon_income_blue"
                      src={Assets.SharedIcons.icon_income_blue}
                      className='cash-closing__summary__price__icon cash-closing__summary__price__icon__rotate'
                    />
                  </div>
                  <NumericFormat
                    type='text'
                    prefix={"$ "}
                    disabled={true}
                    placeholder='$ 0'
                    thousandSeparator=","
                    className='input-price'
                    thousandsGroupStyle="thousand"
                    value={handleTotal(internalConsumption)}
                  />
                </div>
              </div>
            </div>
            <div className='grid-x cash-closing__payments__content-titles__second'>
              <span className='grid-x cash-closing__payments__titles small-3'>Categoría</span>
              <span className='grid-x cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>Productos</span>
              <span className='grid-x cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>Hora</span>
              <span className='grid-x justify-content-center cash-closing__payments__titles small-2'>Cantidad</span>
            </div>
          </div>
          <div ref={formRef} className='cash-closing__payments__info'>
            {internalConsumption?.map((item, index) => {
              return (
                <div key={index} className='grid-x justify-content-between cash-closing__payments__table'>
                  <div className='small-3'>
                    <Select
                      optionLabelProp="label"
                      disabled={item.disabled}
                      options={listOfCategories}
                      popupMatchSelectWidth={false}
                      placeholder='Seleccione una categoría'
                      className='cash-closing__payments__select'
                      popupClassName='cash-closing__payments__select__popup'
                      value={item?.category?.value ? item?.category : undefined}
                      onChange={(e, event) => handleChange(event, index, 'category')}
                      suffixIcon={
                        <div className='cash-closing__payments__select__icon'>
                          <img
                            alt={item.disabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                            src={item.disabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className='grid-x small-3'>
                    <Select
                      optionLabelProp="label"
                      options={item.listOfProducts}
                      popupMatchSelectWidth={false}
                      disabled={item.disabledProducts}
                      placeholder='Selección de productos'
                      className='cash-closing__payments__select'
                      popupClassName='cash-closing__payments__select__popup'
                      value={item?.products?.value ? item?.products : undefined}
                      onChange={(e, event) => handleChange(event, index, 'products')}
                      suffixIcon={
                        <div className='cash-closing__payments__select__icon'>
                          <img
                            alt={item.disabledProducts ? "icon_disabled_arrow" : "icon_blue_arrow"}
                            src={item.disabledProducts ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className='grid-x small-3'>
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      allowClear={false}
                      placeholder="00:00"
                      disabled={item.disabled}
                      className='cash-closing__payments__time'
                      popupClassName='cash-closing__payments__time__select'
                      value={item?.hour ? dayjs(item.hour, 'h:mm a') : null}
                      onChange={(time, timeString) => handleChange(timeString, index, 'hour')}
                      suffixIcon={
                        <img
                          alt="icon_hour"
                          src={item.disabled ? Assets.SharedIcons.icon_hour_disabled : Assets.SharedIcons.icon_hour}
                        />
                      }
                    />
                  </div>
                  <div className='grid-x justify-content-between small-3'>
                    <div className='grid-x small-9 cash-closing__payments__table__content-inputs'>
                      <NumericFormat
                        type='text'
                        placeholder='0'
                        value={item?.price}
                        thousandSeparator=","
                        className='input-price'
                        disabled={item.disabled}
                        thousandsGroupStyle="thousand"
                        onValueChange={({floatValue}) => handleChange(floatValue, index, 'price')}
                      />
                      <div className='cash-closing__payments__table__content-icon-price'>
                        <img
                          alt="icon_add"
                          src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                        />
                      </div>
                    </div>
                    <div className='grid-x small-2 justify-content-end'>
                      <button
                        className='cash-closing__payments__button-delete'
                        disabled={item?.disabled || cashClosingData?.isPublish}
                        onClick={() => removeDataInternalConsumption(item, index)}
                      >
                        <img
                          className='cash-closing__payments__button-delete__icon'
                          alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                          src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='grid-x'>
            <div className='cash-closing__payments__line'></div>
            <button
              disabled={cashClosingData?.isPublish}
              onClick={() => addInternalConsumption()}
              className='grid-x align-middle cash-closing__payments__type cash-closing__payments__type--mod-second'
            >
              <img
                alt='icon_income_blue'
                src={Assets.SharedIcons.icon_income_blue}
                className='cash-closing__summary__price__icon__rotate'
              />
              <span className='cash-closing__payments__type__name'>Agregar pago</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = ({ CashClosingReducer }) => {
  const { cashClosingData } = CashClosingReducer;
  return {
    cashClosingData
  };
};

const mapStateToPropsActions = {
  setStateCashClosingReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(InternalConsumptionComponent);