//Assets
import { Assets } from '../../assets'

//Components
import WeekSelectorComponent from '../components/week-selector/week-selector.component'
import TheyOweUsTreasuryComponent from './components/they-owe-us-treasury/they-owe-us-treasury.component';
import CreditsOrWeOweTreasuryComponent from './components/credits-or-we-owe-treasury/credits-or-we-owe-treasury.component';
import ErrorAlertComponent from '../../shared/components/alerts/error-alert/error-alert.component';
import { ModalDownloadExcelComponent } from '../../shared/components/modals/modal-download-excel/modal-download-excel.component';
// TODO: This process is hidden because it is not required and would affect the system. CODE: FB-01
// import SuccessAlertComponent from '../../shared/components/alerts/success-alert/success-alert.component';
// import InformationAlertComponent from '../../shared/components/alerts/information-alert/information-alert.component';

//Libraries
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'

//Services
import { setModuleNameService } from '../../services/authentication.services';
import { 
  getDownloadExcelService,
  getFinalBalanceHeadquartersService,
  // TODO: This process is hidden because it is not required and would affect the system.
  // updateFinalBalanceHeadquartersService
} from '../../services/final-balance-headquarters.services';

//Styles
import "./final-balance.page.scss"

//Utils
import { formattedNumber } from '../../utils/shared.utils';
import { finalBalanceExcelUtils } from '../../utils/final-balance.utils';

const FinalBalanceTreasuryPage = () => {

  const INITIAL_STATE = {
    daySelected: dayjs().isSameOrAfter(dayjs().set('hour', 6)) ? dayjs() : dayjs().subtract(1, 'day'),
    dateSelected: dayjs().isSameOrAfter(dayjs().set('hour', 6)) ? dayjs() : dayjs().subtract(1, 'day'),
    summaryOfBalance: {
      totalOwedToUs: 0,
      TotalWeOwe: 0,
      difference: 0
    },
    listOfBanks: [],
    listOfPortfolios: [],
    listOfOutflowNotReported: [],
    listOfReceivedNotReported: [],
    realCash: 0,
    realCashCopy: 0,
    totalOutflow: 0,
    totalOfBanks: 0,
    totalOfPortfolios: 0,
    totalOfOutflowNotReported: 0,
    totalOfReceivedNotReported: 0,
    isHoverHandleSave: false,
    isViewModal: false
  }

  const [state, setState] = useState(INITIAL_STATE);
  const [selectedDates, setSelectedDates] = useState(null);

  const {
    dateSelected, daySelected,
    summaryOfBalance, listOfBanks,
    listOfPortfolios, listOfOutflowNotReported,
    listOfReceivedNotReported, totalOfBanks,
    totalOfPortfolios, totalOfOutflowNotReported,
    totalOfReceivedNotReported, totalOutflow,
    realCash,isViewModal
    // TODO: This process is hidden because it is not required and would affect the system. CODE: FB-01
    // isHoverHandleSave,
    // realCashCopy,
  } = state;

  useEffect(() => {
    //Send route name
    setModuleNameService('Cuadre Final');
  }, []);

  useEffect(() => {
    getFinalBalanceHeadquarters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected]);

  const getFinalBalanceHeadquarters = async () => {
    try {
      const resp = await getFinalBalanceHeadquartersService(dayjs(daySelected).format('YYYY-MM-DD'));
      setState({
        ...state,
        summaryOfBalance: {
          totalOwedToUs: resp?.totalOutflow || 0,
          TotalWeOwe: resp?.totalReceived || 0,
          difference: resp?.difference || 0
        },
        realCash: resp?.realCash || 0,
        realCashCopy: resp?.realCash || 0,
        totalOutflow: resp?.totalOutflow || 0,
        listOfBanks: resp?.paymentMethodDailyTransaction || [],
        totalOfBanks: resp?.totalPaymentMethodDailyTransaction || 0,
        listOfPortfolios: resp?.historicalPortfolios || [],
        totalOfPortfolios: resp?.totalHistoricalPortfolios || 0,
        listOfOutflowNotReported: resp?.outflowNotReported || [],
        totalOfOutflowNotReported: resp?.totalOutflowNotReported || 0,
        listOfReceivedNotReported: resp?.receivedNotReported || [],
        totalOfReceivedNotReported: resp?.totalReceivedNotReported || 0
      })
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const handleDate = (date) => {
    setState({
      ...state,
      dateSelected: date
    });
  }
  const previousDate = () => {
    const monthNew = dateSelected.subtract(1, 'month')
    setState({
      ...state,
      dateSelected: monthNew
    });
  }

  const nextDate = () => {
    const monthNew = dateSelected.add(1, 'month')
    setState({
      ...state,
      dateSelected: monthNew
    });
  }

  const previousWeek = () => {
    const week = dateSelected.subtract(1, 'week')
    setState({
      ...state,
      dateSelected: week
    });
  }

  const nextWeek = () => {
    const week = dateSelected.add(1, 'week')
    setState({
      ...state,
      dateSelected: week
    });
  }

  const selectDay = (day) => {
    setState({
      ...state,
      daySelected: day
    });
  };

  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  // TODO: This process is hidden because it is not required and would affect the system. CODE: FB-01
  // const handleSave = async () => {
  //   try {
  //     if (realCash === realCashCopy || realCash === 0) {
  //       InformationAlertComponent('Oops!', 'No hay cambios que guardar');
  //       return
  //     }
  //     await updateFinalBalanceHeadquartersService({ realCash, date: dayjs(daySelected).format('YYYY-MM-DD') });
  //     SuccessAlertComponent();
  //     getFinalBalanceHeadquarters();
  //   } catch (error) {
  //     ErrorAlertComponent()
  //   }
  // };

  const onDateChange = (dates) => {
    setSelectedDates(dates);
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      isViewModal: false,
      startSelected: undefined,
      endSelected: undefined
    }))
  }

  const handleDownloadFile = async () => {
    try {
      const startDate = selectedDates[0].format('YYYY-MM-DD');
      const endDate = selectedDates[1].format('YYYY-MM-DD');
  
      const dataExcel = await getDownloadExcelService(startDate, endDate);
  
      const excelSheets = dataExcel.map((item, index) => ({
        date: item.date,
        totalizers: [
          {
            title: 'Total nos deben',
            total: formattedNumber(item.totalOutflow),
          },
          {
            title: 'Total Debemos',
            total: formattedNumber(item.totalReceived),
          },
          {
            title: 'Cuadre final',
            total: formattedNumber(item.difference),
          }
        ],
        fileExcel: [
          {
            conceptTitle: 'NOS DEBEN A NOSOTROS',
            tables: [
              {
                title: 'Bancos',
                rowTableTitles: ['Sede o negocio', 'Nombre del banco', 'Valor'],

                tableList: item.paymentMethodDailyTransaction.map(subItem => ({
                  headQuarter: subItem?.headquarterPaymentMethodDailyTransaction?.name,
                  bank: subItem?.bankPaymentMethodDailyTransaction?.name,
                  totalPrice: formattedNumber(subItem?.totalPrice),
                })),

                total: 'Total',
                totalTable: formattedNumber(item.totalPaymentMethodDailyTransaction),
              },
              {
                title: 'Carteras',
                rowTableTitles: ['Sede o negocio', 'Valor'],

                tableList: item.historicalPortfolios.map(subItem => ({
                  headQuarter: subItem.name,
                  totalPrice: formattedNumber(subItem.total),
                })),

                total: 'Total',
                totalTable: formattedNumber(item.totalHistoricalPortfolios),
                realCash: 'Efectivo real',
                totalRealCash: formattedNumber(item.realCash),
              },
              {
                title: 'Otros',
                rowTableTitles: ['Sede o negocio', 'Concepto', 'Valor'],

                tableList: item.outflowNotReported.map(subItem => ({
                  headQuarter: subItem?.headQuarterTransaction?.name,
                  concept: subItem.concept,
                  totalPrice: formattedNumber(subItem.cashOutflow),
                })),

                total: 'Total',
                totalTable: formattedNumber(item.totalOutflowNotReported),
              },
            ],
          },
          {
            conceptTitle: 'CRÉDITOS O DEBEMOS',
            tables: [
              {
                title: 'No reportado',
                rowTableTitles: ['Sede o negocio', 'Concepto', 'Valor'],

                tableList: item.receivedNotReported.map(subItem => ({
                  headQuarter: subItem?.headQuarterTransaction?.name,
                  concept: subItem.concept,
                  totalPrice: formattedNumber(subItem.cashReceived),
                })),

                total: 'Total',
                totalTable: formattedNumber(item.totalReceivedNotReported),
              },
            ],
          }
        ]
      }));
  
      finalBalanceExcelUtils(excelSheets, startDate, endDate);
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  return (
    <div className="final-balance__container">
      <WeekSelectorComponent
        daySelected={daySelected}
        nextDate={() => nextDate()}
        nextWeek={() => nextWeek()}
        dateSelected={dateSelected}
        previousWeek={() => previousWeek()}
        previousDate={() => previousDate()}
        selectDay={(date) => selectDay(date)}
        handleDate={(date) => handleDate(date)}
        disabledDate={dayjs().isSameOrAfter(dayjs().set('hour', 6)) ? dayjs() : dayjs().subtract(1, 'day')}
      />
      <div className="grid-x final-balance__box">
        <div className="grid-x small-12 final-balance__summary">
          <div className='small-2'>
            <div className="final-balance__summary__box">
              <span className="final-balance__summary__text">
                Total nos deben
              </span>
              <div className="grid-x final-balance__summary__details align-middle justify-content-around">
                <img
                  src={Assets.SharedIcons.icon_income}
                  alt="icon_income"
                  height={"23px"}
                  width={"23px"}
                />
                <span className="grid-x small-10 align-right final-balance__summary__price">
                  {formattedNumber(summaryOfBalance?.totalOwedToUs)}
                </span>
              </div>
            </div>
          </div>
          <div className='small-2'>
            <div className="final-balance__summary__box">
              <span className="final-balance__summary__text">
                Total Debemos
              </span>
              <div className="grid-x final-balance__summary__details align-middle justify-content-around">
                <img
                  src={Assets.SharedIcons.icon_egress}
                  alt="icon_egress"
                  height={"23px"}
                  width={"23px"}
                />
                <span className="grid-x small-10 align-right final-balance__summary__price">
                  {formattedNumber(summaryOfBalance?.TotalWeOwe)}
                </span>
              </div>
            </div>
          </div>
          <div className='small-2'>
            <div className="final-balance__summary__box">
              <span className="final-balance__summary__text">
                Cuadre final
              </span>
              <div className="grid-x final-balance__summary__details align-middle justify-content-around">
                <img src={Assets.SharedIcons.icon_result} alt="icon_result" />
                <span className="grid-x small-10 align-right final-balance__summary__price">
                  {formattedNumber(summaryOfBalance?.difference)}
                </span>
              </div>
            </div>
          </div>
          <div className='grid-x small-6 align-center-middle justify-content-end'>
            <button
              className='final-balance__summary__button-file'
              onClick={() => setState((prevState) => ({ ...prevState, isViewModal: true }))}
            >
              <span>Generar excel</span>
              <img
              alt='icon_publish'
              src={Assets.SharedIcons.icon_publish}
              className='final-balance__summary__icon-publish'
              />
            </button>
            {/*
              TODO: This process is hidden because it is not required and would affect the system. CODE: FB-01
              <button
                onClick={() => handleSave()}
                className='final-balance__button-save'
                onMouseOver={() => setState((prevState) => ({ ...prevState, isHoverHandleSave: true }))}
                onMouseOut={() => setState((prevState) => ({ ...prevState, isHoverHandleSave: false }))}
              >
                <img
                  className='final-balance__button-save__icon-save'
                  alt={isHoverHandleSave ? 'icon_save_hover' : 'icon_save'}
                  src={isHoverHandleSave ? Assets.SharedIcons.icon_save_hover : Assets.SharedIcons.icon_save}
                />
                <span>Guardar y actualizar</span>
              </button>
            */}
          </div>
        </div>
        <div className='grid-x small-12 final-balance__content-tables'>
          <div className='grid-x small-6 align-content-start'>
            <div className='grid-x align-center-middle final-balance__content-title-table'>
              <span className='final-balance__title-table'>NOS DEBEN A NOSOTROS</span>
            </div>
            <TheyOweUsTreasuryComponent
              realCash={realCash}
              updateState={updateState}
              totalOutflow={totalOutflow}
              listOfBanks={listOfBanks}
              totalOfBanks={totalOfBanks}
              listOfPortfolios={listOfPortfolios}
              totalOfPortfolios={totalOfPortfolios}
              listOfOutflowNotReported={listOfOutflowNotReported}
              totalOfOutflowNotReported={totalOfOutflowNotReported}
            />
          </div>
          <div className='grid-x small-6 align-right align-content-start'>
            <div className='grid-x align-center-middle final-balance__content-title-table'>
              <span className='final-balance__title-table'>CRÉDITOS O DEBEMOS</span>
            </div>
            <CreditsOrWeOweTreasuryComponent
              listOfReceivedNotReported={listOfReceivedNotReported}
              totalOfReceivedNotReported={totalOfReceivedNotReported}
            />
          </div>
        </div>
      </div>
      {isViewModal && (
      <ModalDownloadExcelComponent
        isOpen={isViewModal}
        onClose={() => closeModal()}
        onGenerate={() => handleDownloadFile()}
        onDateChange={onDateChange}
        title={"Seleccione el rango de fechas"}
        month={true}
      />
      )}
    </div>
  );
};

export default FinalBalanceTreasuryPage;