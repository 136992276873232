//Actions
import { setStateCashClosingReducer } from '../../../../storage/reducers/cash-closing/cash-closing.actions';

//Assets
import { Assets } from '../../../../assets';

//Libraries
import { Input, Select } from 'antd';
import { connect } from 'react-redux';
import { NumericFormat } from "react-number-format";
import React, { useEffect, useRef, useState } from 'react';

// Services
import { getChargesService } from '../../../../services/shared.services';
import { deletedRecordLoansTableService } from '../../../../services/cash-closing.services';
import { deletedInventoryLoansTableService } from '../../../../services/cash-closing-inventory.services';

//Styles
import '../../cash-closing.page.scss';

const LoansComponent = (props) => {

    const {
        //Actions
        setStateCashClosingReducer,
        //Variables
        cashClosingData,
        dateInventory,
        //Functions
        getAllPortfolio
    } = props

    const INITIAL_STATE = {
        scrollEnd: false,
        listOfCharges: [],
        loansData: [
            { nameOfCharge: { label: "", value: "" }, concept: "", invoiceNumber: "", price: "", disabled: true },
            { nameOfCharge: { label: "", value: "" }, concept: "", invoiceNumber: "", price: "", disabled: true },
            { nameOfCharge: { label: "", value: "" }, concept: "", invoiceNumber: "", price: "", disabled: true },
            { nameOfCharge: { label: "", value: "" }, concept: "", invoiceNumber: "", price: "", disabled: true },
            { nameOfCharge: { label: "", value: "" }, concept: "", invoiceNumber: "", price: "", disabled: true },
            { nameOfCharge: { label: "", value: "" }, concept: "", invoiceNumber: "", price: "", disabled: true },
            { nameOfCharge: { label: "", value: "" }, concept: "", invoiceNumber: "", price: "", disabled: true }
        ],
    }

    const [state, setState] = useState(INITIAL_STATE)

    const { listOfCharges, loansData, scrollEnd } = state;

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current && scrollEnd) {
            const miDiv = formRef.current;
            miDiv.scrollTop = miDiv.scrollHeight;
        }
    }, [scrollEnd, loansData.length]);

    useEffect(() => {
        getAllCharges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashClosingData?.cashRegisterCashExpenseTrust]);

    useEffect(() => {
        if (cashClosingData?.cashRegisterCashExpenseTrust) {
            let arr1 = [...INITIAL_STATE.loansData];
            let dataMod = [...cashClosingData.cashRegisterCashExpenseTrust];
            let arr2 = dataMod.map((data) => {
                const { chargeCashExpenseTrust, ...restOfData } = data;
                return {
                    ...restOfData,
                    nameOfCharge: {
                        label: data?.chargeCashExpenseTrust?.name,
                        value: data?.chargeCashExpenseTrust?.id
                    },
                    disabled: false
                };
            });
            const minLen = Math.min(arr1.length, arr2.length);

            for (let i = 0; i < minLen; i++) {
                if (arr1[i].id !== arr2[i].id) {
                    arr1[i] = arr2[i];
                }
            }

            if (arr1.length < arr2.length) {
                for (let i = minLen; i < arr2.length; i++) {
                    arr1.push(arr2[i]);
                }
            }

            setState((prevState) => ({
                ...prevState,
                loansData: arr1,
                scrollEnd: false
            }))

        } else {
            setState(INITIAL_STATE)
        }
        formRef.current.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashClosingData?.cashRegisterCashExpenseTrust]);

    const getAllCharges = async () => {
        try {
            const listOfCharges = await getChargesService();
            setState((prevState) => ({
                ...prevState,
                listOfCharges
            }))
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const addLoans = () => {
        const objectDisabled = loansData.find(object => object.hasOwnProperty("disabled") && object.disabled === true);
        if (!objectDisabled) {
            const newArray = [
                ...loansData,
                {
                    price: "",
                    concept: "",
                    invoiceNumber: "",
                    nameOfCharge: {
                        label: "",
                        value: "",
                    },
                    disabled: false,
                },
            ]
            setState({
                ...state,
                loansData: newArray,
                scrollEnd: true
            })
        } else {
            const newArray = loansData.map(object => object === objectDisabled ? { ...object, disabled: false } : object);
            setState({
                ...state,
                loansData: newArray
            })
        }
    }

    const handleChange = (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        const newArray = JSON.parse(JSON.stringify(loansData));
        
        if (name === "nameOfCharge") {
            newArray[index][name] = {
                label: value.label,
                value: value.value
            }
        } else {
            newArray[index][name] = value
        }
        setState({
            ...state,
            loansData: newArray
        })
        setStateCashClosingReducer('loansDataFrom', newArray);
    }

    const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => {
          totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0);
        });
        totalPrice = parseFloat(totalPrice.toFixed(2));
        return totalPrice;
    };

    const removeDataLoans = async (item, index) => {
        let respService = false;
        const initialItem = {
            price: "",
            concept: "",
            invoiceNumber: "",
            nameOfCharge: {
                label: "",
                value: "",
            },
            disabled: true
        }
        const newArray = JSON.parse(JSON.stringify(loansData));
        newArray[index] = initialItem;
        try {
            if (item.id) {
                if (dateInventory) {
                    respService = await deletedInventoryLoansTableService({ id: item.id });
                }else{
                    respService = await deletedRecordLoansTableService({ id: item.id });
                }
            };
            newArray.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);
            setState({ ...state, loansData: newArray });
            setStateCashClosingReducer('loansDataFrom', newArray);
            (item?.id && respService) && await getAllPortfolio();
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    return (
        <div className='small-6'>
            <div className='cash-closing__payments__container'>
                <div className='cash-closing__payments__content'>
                    <div className='grid-y'>
                        <div className='grid-x cash-closing__payments__content-titles__fist'>
                            <div className='grid-x align-middle small-6'>
                                <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                                <span className='cash-closing__payments__titles cash-closing__payments__titles__space'>Fiados</span>
                            </div>
                            <div className='grid-x align-middle small-6 justify-content-end'>
                                <span className='cash-closing__payments__titles'>Total:</span>
                                <div className='grid-x align-middle cash-closing__summary__details'>
                                    <div className='cash-closing__payments__table__content-icon-price'>
                                        <img
                                            alt="icon_egress"
                                            src={Assets.SharedIcons.icon_egress}
                                            className='cash-closing__summary__price__icon'
                                        />
                                    </div>
                                    <NumericFormat
                                        type='text'
                                        prefix={"$ "}
                                        disabled={true}
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        value={handleTotal(loansData)}
                                        thousandsGroupStyle="thousand"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='grid-x cash-closing__payments__content-titles__second'>
                            <span className='cash-closing__payments__titles cash-closing__payments__titles__ellipsis small-3'>Número de identificación</span>
                            <span className='cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>Autorizado por</span>
                            <span className='cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>N° de la factura</span>
                            <span className='grid-x justify-content-center cash-closing__payments__titles small-2'>Valor</span>
                        </div>
                    </div>
                    <div ref={formRef} className='cash-closing__payments__info'>
                        {loansData.map((item, index) => {
                            return (
                                <div key={index} className='grid-x justify-content-between cash-closing__payments__table'>
                                    <div className='small-3'>
                                        <Input
                                            disabled={item.disabled}
                                            placeholder='Número de identificación'
                                            value={item?.concept}
                                            className='cash-closing__payments__input'
                                            onChange={(e) => handleChange(e, index, 'concept')}
                                            prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                        />
                                    </div>
                                    <div className='grid-x small-3'>
                                        <Select
                                            optionLabelProp="label"
                                            options={listOfCharges}
                                            disabled={item.disabled}
                                            popupMatchSelectWidth={false}
                                            placeholder='Nombre del encargado'
                                            className='cash-closing__payments__select'
                                            popupClassName='cash-closing__payments__select__popup'
                                            onChange={(e, event) => handleChange(event, index, 'nameOfCharge')}
                                            value={item?.nameOfCharge?.value ? item?.nameOfCharge : undefined}
                                            suffixIcon={
                                                <div className='cash-closing__payments__select__icon'>
                                                    <img
                                                        alt={item.disabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                        src={item.disabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className='grid-x small-3'>
                                        <Input
                                            placeholder='0000'
                                            disabled={item?.disabled}
                                            value={item?.invoiceNumber}
                                            className='cash-closing__payments__input'
                                            onChange={(e) => handleChange(e, index, "invoiceNumber")}
                                            prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                        />
                                    </div>
                                    <div className='grid-x justify-content-between small-3'>
                                        <div className='grid-x small-9 cash-closing__payments__table__content-inputs'>
                                            <NumericFormat
                                                type='text'
                                                prefix={"$ "}
                                                placeholder='$ 0'
                                                value={item?.price}
                                                thousandSeparator=","
                                                className='input-price'
                                                disabled={item.disabled}
                                                thousandsGroupStyle="thousand"
                                                onValueChange={({floatValue}) => handleChange(floatValue, index, 'price')}
                                            />
                                            <div className='cash-closing__payments__table__content-icon-price'>
                                                <img
                                                    alt="icon_add"
                                                    src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                />
                                            </div>
                                        </div>
                                        <div className='grid-x small-2 justify-content-end'>
                                            <button
                                                onClick={() => removeDataLoans(item, index)}
                                                className='cash-closing__payments__button-delete'
                                                disabled={item?.disabled || cashClosingData?.isPublish}
                                            >
                                                <img
                                                    className='cash-closing__payments__button-delete__icon'
                                                    alt={ item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='grid-x'>
                        <div className='cash-closing__payments__line'></div>
                        <button 
                            onClick={()=> addLoans()}
                            disabled={cashClosingData?.isPublish}
                            className='grid-x align-middle cash-closing__payments__type cash-closing__payments__type--mod'
                        >
                            <img
                                src={Assets.SharedIcons.icon_egress}
                                alt='icon_egress'
                            />
                            <span className='cash-closing__payments__type__name'>Agregar fiado</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ CashClosingReducer }) => {
    const { cashClosingData } = CashClosingReducer;
    return {
        cashClosingData
    };
};

const mapStateToPropsActions = {
    setStateCashClosingReducer
};
  
export default connect(mapStateToProps, mapStateToPropsActions)(LoansComponent);