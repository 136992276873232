import * as portfolioTypes from "./portfolio.types";

const INITIAL_STATE = {
  filterInput: {},
};

const PortfolioReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case portfolioTypes.SET_STATE_PORTFOLIO:
      return {
        ...state,
        [action.payload.prop]: action.payload.value,
      };
    default:
      return state;
  }
};

export default PortfolioReducer;
