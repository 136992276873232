//Actions
import { setStateCashClosingReducer } from '../../../../storage/reducers/cash-closing/cash-closing.actions';

//Libraries
import { Input, Select } from 'antd';
import { connect } from 'react-redux';
import { NumericFormat } from "react-number-format";
import React, { useEffect, useRef, useState } from 'react';

//Assets
import { Assets } from '../../../../assets';

//Services
import { getPaymentMethodService, getProvidersService } from '../../../../services/shared.services';
import { deletedRecordProvidersTableService } from '../../../../services/cash-closing.services';
import { deletedInventoryProvidersTableService } from '../../../../services/cash-closing-inventory.services';

//Styles
import '../../cash-closing.page.scss';

//Utils
import { dataProviders } from '../../../../utils/providers.utils';

const ProvidersComponent = (props) => {

    const {
        cashClosingData,
        setStateCashClosingReducer,
        //Functions
        getAllPortfolio,
        //Variables
        dateInventory
    } = props

    const INITIAL_STATE = {
        scrollEnd: false,
        listOfProviders: [],
        listOfPaymentMethods: [],
        payments: dataProviders
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { payments, scrollEnd, listOfProviders, listOfPaymentMethods } = state;

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current && scrollEnd) {
            const miDiv = formRef.current;
            miDiv.scrollTop = miDiv.scrollHeight;
        }
    }, [scrollEnd, payments.length]);

    useEffect(() => {
        getSelectorsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashClosingData?.cashRegisterCashExpenseProvider]);

    useEffect(() => {
        if (cashClosingData?.cashRegisterCashExpenseProvider) {
            let arr1 = [...INITIAL_STATE.payments];
            let dataMod = [...cashClosingData.cashRegisterCashExpenseProvider];
            let arr2 = dataMod.map((data) => {
                const { providerCashExpenseProvider, paymentMethodCashExpenseProvider, ...restOfData } = data;
                return {
                    ...restOfData,
                    provider: {
                        label: data?.providerCashExpenseProvider?.name,
                        value: data?.providerCashExpenseProvider?.id
                    },
                    paymentMethods: {
                        label: data?.paymentMethodCashExpenseProvider?.name,
                        value: data?.paymentMethodCashExpenseProvider?.id
                    },
                    disabled: false
                };
            });
            const minLen = Math.min(arr1.length, arr2.length);

            for (let i = 0; i < minLen; i++) {
                if (arr1[i].id !== arr2[i].id) {
                    arr1[i] = arr2[i];
                }
            };

            if (arr1.length < arr2.length) {
                for (let i = minLen; i < arr2.length; i++) {
                    arr1.push(arr2[i]);
                }
            };

            setState((prevState) => ({
                ...prevState,
                payments: arr1,
                scrollEnd: false,
            }))
        } else {
            setState(INITIAL_STATE)
        }
        formRef.current.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashClosingData?.cashRegisterCashExpenseProvider]);

    const getSelectorsList = async () => {
        try {
            const results = await Promise.allSettled([
                getProvidersService(),
                getPaymentMethodService(),
            ]);
            let listOfProviders = [];
            let listOfPaymentMethods = []
            for (let index = 0; index < results.length; index++) {
                const elementResponse = results[index];
                if (elementResponse.status === "fulfilled") {
                    switch (index) {
                        case 0:
                            listOfProviders = elementResponse?.value;
                            break;
                        case 1:
                            listOfPaymentMethods = elementResponse?.value;
                            break;
                        default:
                            break;
                    };
                };
            };
            setState((prevState) => ({
                ...prevState,
                listOfProviders,
                listOfPaymentMethods,
            }));
        } catch {
            // TODO: Implement error alert with code error.
        }
    };

    const addProvider = () => {
        const objectInputDisabled = payments.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

        if (!objectInputDisabled) {
            const newArray = [
                ...payments,
                {
                    price: "",
                    provider: {
                        label: "",
                        value: ""
                    },
                    paymentMethods: {
                        label: "",
                        value: ""
                    },
                    invoiceNumber: "",
                    disabled: false,
                },
            ];
            setState((prevState) => ({
                ...prevState,
                payments: newArray,
                scrollEnd: true
            }))
        } else {
            const newArray = payments.map(object => (object === objectInputDisabled ? { ...object, disabled: false } : object));
            setState((prevState) => ({
                ...prevState,
                payments: newArray
            }))
        }
    };

    const handleChange = (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        const newArray = JSON.parse(JSON.stringify(payments));

        if ((name === "paymentMethods") || (name === "provider")) {
            newArray[index][name] = {
                label: value.label,
                value: value.value
            }
        } else {
            newArray[index][name] = value
        }
        setState({
            ...state,
            payments: newArray
        })
        setStateCashClosingReducer('providersForm', newArray);
    };

    const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => {
            totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0);
        });
        totalPrice = parseFloat(totalPrice.toFixed(2));
        return totalPrice;
    };

    const removeDataProviders = async (item, index) => {
        let respService = false;
        const initialItem = {
            price: "",
            provider: {
                label: "",
                value: ""
            },
            paymentMethods: {
                label: "",
                value: ""
            },
            invoiceNumber: "",
            disabled: true,
        }
        const newArray = JSON.parse(JSON.stringify(payments));
        newArray[index] = initialItem;
        try {
            if (item.id) {
                if(dateInventory){
                    respService = await deletedInventoryProvidersTableService({ id: item.id});
                }else{
                    respService = await deletedRecordProvidersTableService({ id: item.id });
                }
            };
            newArray.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);
            setState({ ...state, payments: newArray });
            setStateCashClosingReducer('providersForm', newArray);
            (item?.id && respService) && await getAllPortfolio();
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    return (
        <div className='cash-closing__payments__container'>
            <div className='cash-closing__payments__content'>
                <div className='grid-y'>
                    <div className='grid-x cash-closing__payments__content-titles__fist'>
                        <div className='grid-x align-middle small-6'>
                            <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                            <span className='cash-closing__payments__titles cash-closing__payments__titles__space'>Proveedores</span>
                        </div>
                        <div className='grid-x align-middle small-6 justify-content-end'>
                            <span className='cash-closing__payments__titles'>Total:</span>
                            <div className='grid-x align-middle cash-closing__summary__details'>
                                <div className='cash-closing__payments__table__content-icon-price'>
                                    <img
                                        alt="icon_income_blue"
                                        src={Assets.SharedIcons.icon_income_blue}
                                        className='cash-closing__summary__price__icon cash-closing__summary__price__icon__rotate'
                                    />
                                </div>
                                <NumericFormat
                                    type='text'
                                    prefix={"$ "}
                                    disabled={true}
                                    placeholder='$ 0'
                                    thousandSeparator=","
                                    className='input-price'
                                    thousandsGroupStyle="thousand"
                                    value={handleTotal(payments)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='grid-x cash-closing__payments__content-titles__second'>
                        <span className='cash-closing__payments__titles cash-closing__payments__titles__ellipsis small-3'>Nombre del proveedor</span>
                        <span className='cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>N° de factura</span>
                        <span className='cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>Forma de pago</span>
                        <span className='grid-x justify-content-center cash-closing__payments__titles small-2'>Valor</span>
                    </div>
                </div>
                <div ref={formRef} className='cash-closing__payments__info'>
                    {payments.map((item, index) => {
                        return (
                            <div key={index} className='grid-x justify-content-between cash-closing__payments__table'>
                                <div className='small-3'>
                                    <Select
                                        optionLabelProp="label"
                                        disabled={item.disabled}
                                        options={listOfProviders}
                                        popupMatchSelectWidth={false}
                                        placeholder='Nombre del proveedor'
                                        className='cash-closing__payments__select'
                                        popupClassName='cash-closing__payments__select__popup'
                                        value={item?.provider?.value ? item?.provider : undefined}
                                        onChange={(e, event) => handleChange(event, index, 'provider')}
                                        suffixIcon={
                                            <div className='cash-closing__payments__select__icon'>
                                                <img
                                                    alt={item.disabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                    src={item.disabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                                <div className='grid-x small-3'>
                                    <Input
                                        placeholder='0000'
                                        disabled={item?.disabled}
                                        value={item?.invoiceNumber}
                                        className='cash-closing__payments__input'
                                        onChange={(e) => handleChange(e, index, "invoiceNumber")}
                                        prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                    />
                                </div>
                                <div className='grid-x small-3'>
                                    <Select
                                        optionLabelProp="label"
                                        disabled={item.disabled}
                                        popupMatchSelectWidth={false}
                                        options={listOfPaymentMethods}
                                        placeholder='Selección de pago'
                                        className='cash-closing__payments__select'
                                        popupClassName='cash-closing__payments__select__popup'
                                        onChange={(e, event) => handleChange(event, index, 'paymentMethods')}
                                        value={item?.paymentMethods?.value ? item?.paymentMethods : undefined}
                                        suffixIcon={
                                            <div className='cash-closing__payments__select__icon'>
                                                <img
                                                    alt={item.disabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                    src={item.disabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                                <div className='grid-x justify-content-between small-3'>
                                    <div className='grid-x small-9 cash-closing__payments__table__content-inputs'>
                                        <NumericFormat
                                            type='text'
                                            prefix={"$ "}
                                            placeholder='$ 0'
                                            value={item?.price}
                                            thousandSeparator=","
                                            className='input-price'
                                            disabled={item.disabled}
                                            thousandsGroupStyle="thousand"
                                            onValueChange={({floatValue}) => handleChange(floatValue, index, 'price')}
                                        />
                                        <div className='cash-closing__payments__table__content-icon-price'>
                                            <img
                                                alt="icon_add"
                                                src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                            />
                                        </div>
                                    </div>
                                    <div className='grid-x small-2 justify-content-end'>
                                        <button
                                            onClick={() => removeDataProviders(item, index)}
                                            className='cash-closing__payments__button-delete'
                                            disabled={item?.disabled || cashClosingData?.isPublish}
                                        >
                                            <img
                                                className='cash-closing__payments__button-delete__icon'
                                                alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='grid-x'>
                    <div className='cash-closing__payments__line'></div>
                    <button
                        onClick={() => addProvider()}
                        disabled={cashClosingData?.isPublish}
                        className='grid-x align-middle cash-closing__payments__type cash-closing__payments__type--mod-second'
                    >
                        <img
                            src={Assets.SharedIcons.icon_income_blue}
                            alt='icon_income_blue'
                            className='cash-closing__summary__price__icon__rotate'
                        />
                        <span className='cash-closing__payments__type__name'>Agregar pago</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ CashClosingReducer }) => {
    const { cashClosingData } = CashClosingReducer;
    return {
        cashClosingData
    };
};

const mapStateToPropsActions = {
    setStateCashClosingReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ProvidersComponent);