// Assets
import { Assets } from '../../../assets';

// Components - Shared
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from '../../../shared/components/alerts/success-alert/success-alert.component';
import InformationAlertComponent from '../../../shared/components/alerts/information-alert/information-alert.component';

// Libraries
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useFormik } from "formik";
import { DatePicker, Input, Select } from 'antd';
import { NumericFormat } from 'react-number-format';
import { Link, useNavigate, useLocation } from "react-router-dom";

// Services
import { createBankService, getAllBanksService, getBankByIdService, getTypeAccountService, updateBankService } from '../../../services/banks.services';
import { getAllHeadQuartersService } from '../../../services/shared.services';

// Style
import './banks-detail.page.scss';

// Utils
import { validateCreationOfBanks } from '../../../utils/validate-creation-of-banks.utils';

const BanksDetailPage = () => {

    let history = useNavigate();
    let { state: { idBank, isDisabled, titleBank } } = useLocation();

    const { values, errors, touched, handleBlur, setValues } = useFormik({
        initialValues: {
            bankName: undefined,
            typeAccount: undefined,
            numberAccount: "",
            identificationCode: "",
            associatedOperation: undefined,
            initialBalance: "0",
            dateOfCreation: dayjs(),
        },
        initialErrors: {
            bankName: "",
            typeAccount: "",
            numberAccount: "",
            identificationCode: "",
            associatedOperation: "",
            initialBalance: "",
            dateOfCreation: "",
        },
        validate: validateCreationOfBanks,
    });


    const onChange = async (data, target) => {
        let value = data && data.target ? data.target.value || '' : data;
        const objectValues = { ...values, [target]: value };
        if (target === "numberAccount") {
            objectValues.identificationCode = value.slice(-4)
        }
        setValues(objectValues);
    };

    useEffect(() => {
      let arrayServices = [
        getAllBanksService(),
        getAllHeadQuartersService(),
        getTypeAccountService()
      ]
      if (idBank) {
        arrayServices.push(getBankByIdService(idBank))
      }
      getServices(arrayServices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const INITIAL_STATE = {
        optionsBanks: [],
        optionsHeadquarters: [],
        optionsTypeAccount: [],
        originalDataBank: {},
        idBankCreated: undefined,
    }

    const [state, setState] = useState(INITIAL_STATE)

    const { 
        optionsBanks, optionsHeadquarters, 
        optionsTypeAccount, originalDataBank, idBankCreated
    } = state

    const getServices = async(arrayServices) => {
        try {
            const responseServices = await Promise.allSettled(arrayServices)
            let optionsBanksResp = []
            let optionsHeadquartersResp = []
            let optionsTypeAccountResp = []
            let dataBank = {}

            for (let index = 0; index < responseServices.length; index++) {
                const elementResponse = responseServices[index];
                if (elementResponse.status === "fulfilled") {
                    switch (index) {
                        case 0:
                            optionsBanksResp = elementResponse?.value
                        break;
                        case 1:
                            optionsHeadquartersResp = elementResponse?.value
                        break;
                        case 2:
                            optionsTypeAccountResp = elementResponse?.value
                        break;
                        case 3:
                            dataBank = {
                                bankName: {
                                    value: elementResponse?.value?.bankPaymentMethodDailyTransaction.id,
                                    label: elementResponse?.value?.bankPaymentMethodDailyTransaction.name
                                },
                                typeAccount:{
                                    value: elementResponse?.value?.typeAccountPaymentMethodDailyTransaction.id,
                                    label: elementResponse?.value?.typeAccountPaymentMethodDailyTransaction.name
                                },
                                numberAccount: elementResponse?.value?.accountNumber,
                                identificationCode: elementResponse?.value?.name,
                                associatedOperation: {
                                    value: elementResponse?.value?.headquarterPaymentMethodDailyTransaction.id,
                                    label: elementResponse?.value?.headquarterPaymentMethodDailyTransaction.name
                                },
                                initialBalance: elementResponse?.value?.initialBalance,
                                dateOfCreation: dayjs(elementResponse?.value?.date)
                            }
                        break;
                        default:
                        break;
                    }
                }else{
                    ErrorAlertComponent()
                }
            };
            if (idBank) {
                setValues({
                    ...values,
                    ...dataBank
                })
            }
            setState({
                ...state,
                optionsBanks: optionsBanksResp,
                optionsHeadquarters: optionsHeadquartersResp,
                optionsTypeAccount: optionsTypeAccountResp,
                originalDataBank: dataBank
            })
        } catch (error) {
            ErrorAlertComponent()
        }
    }

    const createOrUpdateBank = async(id) => {
        if (id) {
            const dataOriginal = {
                idBank: originalDataBank.bankName.value,
                idTypeAccount: originalDataBank.typeAccount.value,
                accountNumber: originalDataBank.numberAccount,
                name: originalDataBank.identificationCode,
                idHeadquarter: originalDataBank.associatedOperation.value,
                date: dayjs(originalDataBank.dateOfCreation).format('YYYY-MM-DD'),
                initialBalance: originalDataBank.initialBalance
            }
            const dataValues = {
                idBank: values.bankName.value,
                idTypeAccount: values.typeAccount.value,
                accountNumber: values.numberAccount,
                name: values.identificationCode,
                idHeadquarter: values.associatedOperation.value,
                date: dayjs(values.dateOfCreation).format('YYYY-MM-DD'),
                initialBalance: values.initialBalance
            }

            const changesData = findChanges(dataOriginal, dataValues)
            if (Object.keys(changesData).length > 0) {
                try {
                    await updateBankService({...changesData, id: id});
                    SuccessAlertComponent()
                    getDataBank(id)
                } catch (error) {
                    ErrorAlertComponent()
                }
            }else{
                InformationAlertComponent()
            }
        }else{
            try {
                const dataCreate = await createBankService({
                    idBank: values.bankName.value,
                    idTypeAccount: values.typeAccount.value,
                    accountNumber: values.numberAccount,
                    name: values.identificationCode,
                    idHeadquarter: values.associatedOperation.value,
                    date: dayjs(values.dateOfCreation).format('YYYY-MM-DD'),
                    initialBalance: values.initialBalance
                });
                SuccessAlertComponent(undefined, "Banco creado satisfactoriamente.")
                setValues({
                    ...values,
                    ...dataCreate[0]
                })
                setState({
                    ...state,
                    originalDataBank: dataCreate[0],
                    idBankCreated: dataCreate[1]
                })
            } catch (error) {
                ErrorAlertComponent()
            }
        }
    }


    const findChanges = (object1, object2) =>{
        return Object.keys(object2).reduce((changes, key) => {
          if (object1.hasOwnProperty(key) && object1[key] !== object2[key]) {
            changes[key] = object2[key];
          }
          return changes;
        }, {});
    }

    const getDataBank = async(id) => {
        try {
            const dataBankById = await getBankByIdService(id)
            const dataBank = {
                bankName: {
                    value: dataBankById?.bankPaymentMethodDailyTransaction.id,
                    label: dataBankById?.bankPaymentMethodDailyTransaction.name
                },
                typeAccount:{
                    value: dataBankById?.typeAccountPaymentMethodDailyTransaction.id,
                    label: dataBankById?.typeAccountPaymentMethodDailyTransaction.name
                },
                numberAccount: dataBankById?.accountNumber,
                identificationCode: dataBankById?.name,
                associatedOperation: {
                    value: dataBankById?.headquarterPaymentMethodDailyTransaction.id,
                    label: dataBankById?.headquarterPaymentMethodDailyTransaction.name
                },
                initialBalance: dataBankById?.initialBalance,
                dateOfCreation: dayjs(dataBankById?.date)
            }
            setValues({
                ...values,
                ...dataBank
            })
            setState({
                ...state,
                originalDataBank: dataBank
            })
        } catch (error) {
            ErrorAlertComponent()
        }
    }

    return (
        <div className='banks-detail__container'>
            <div className='grid-x align-middle'>
                <Link
                    replace={true}
                    to="/banksTreasury"
                    className='banks-detail__title'
                >
                    Bancos
                </Link>
                <img
                    alt='icon_arrow'
                    src={Assets.SharedIcons.icon_arrow}
                    className='banks-detail__icon-arrow'
                />
                <span className='banks-detail__subtitle'>{titleBank}</span>
            </div>
            <div className='grid-x banks-detail__container-box'>
                <div className='banks-detail__first-box'>
                    <div className='grid-x align-middle banks-detail__first-box__container-title'>
                        <span className='banks-detail__first-box__title'>
                            {originalDataBank?.bankName?.label || "Nombre del banco"}
                        </span>
                    </div>
                    <div className='banks-detail__first-box__container-info'>
                        <div className='grid-x align-content-end justify-content-between banks-detail__first-box__content-description'>
                            <span className='banks-detail__first-box__subtitle'>Nro. de cuenta</span>
                            <span className='banks-detail__first-box__description'>{originalDataBank?.numberAccount || "0000000000"}</span>
                        </div>
                        <div className='grid-x align-content-end justify-content-between banks-detail__first-box__content-description'>
                            <span className='banks-detail__first-box__subtitle'>Código</span>
                            <span className='banks-detail__first-box__description'>{originalDataBank?.identificationCode || "0000"}</span>
                        </div>
                        <div className='grid-x align-content-end justify-content-between banks-detail__first-box__content-description'>
                            <span className='banks-detail__first-box__subtitle'>Tipo de cuenta</span>
                            <span className='banks-detail__first-box__description'>{originalDataBank?.typeAccount?.label || "Sin definir"}</span>
                        </div>
                        <div className='grid-x align-content-end justify-content-between banks-detail__first-box__content-description'>
                            <span className='banks-detail__first-box__subtitle'>Sede o Licorera</span>
                            <span className='banks-detail__first-box__description'>{originalDataBank?.associatedOperation?.label || "Sin definir"}</span>
                        </div>
                        <div className='grid-x align-content-end justify-content-between banks-detail__first-box__content-description'>
                            <span className='banks-detail__first-box__subtitle'>Saldo inicial</span>
                            <span className='banks-detail__first-box__description'>{originalDataBank?.initialBalance || "0"}</span>
                        </div>
                    </div>
                </div>
                <div className='banks-detail__content-second-box'>
                    <div className='banks-detail__second-box'>
                        <div className='grid-x align-middle banks-detail__second-box__container-title'>
                            <span className='banks-detail__second-box__title'>
                                Agregar un nuevo banco
                            </span>
                        </div>
                        <div className='grid-x justify-content-between banks-detail__second-box__container-info'>
                            <div className='banks-detail__second-box__content-label'>
                                <span className='banks-detail__second-box__content-label__title'>
                                    Banco al que pertenece la cuenta <span style={{ color: '#F85F61' }}>*</span>
                                </span>
                                <Select
                                    options={optionsBanks}
                                    disabled={isDisabled}
                                    popupMatchSelectWidth={false}
                                    placeholder='Selecciona un banco'
                                    onBlur={(value) => handleBlur('bankName')(value)}
                                    onChange={(e, event) => onChange(event, 'bankName')}
                                    value={values?.bankName ? values?.bankName : undefined}
                                    className='banks-detail__second-box__content-label__select'
                                    status={errors.bankName && touched.bankName ? 'error' : ''}
                                    popupClassName='banks-detail__second-box__content-label__select__popup'
                                    suffixIcon={
                                        <div className='banks-detail__second-box__content-label__select__icon'>
                                            <img
                                                alt={isDisabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                src={isDisabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                            />
                                        </div>
                                    }
                                />
                                <span className="banks-detail__second-box__content-label__error">{touched.bankName ? errors.bankName : null}</span>
                            </div>
                            <div className='banks-detail__second-box__content-label'>
                                <span className='banks-detail__second-box__content-label__title'>
                                    Tipo de cuenta <span style={{ color: '#F85F61' }}>*</span>
                                </span>
                                <Select
                                    options={optionsTypeAccount}
                                    disabled={isDisabled}
                                    popupMatchSelectWidth={false}
                                    placeholder='Selecciona un tipo de cuenta'
                                    onBlur={(value) => handleBlur('typeAccount')(value)}
                                    onChange={(e, event) => onChange(event, 'typeAccount')}
                                    className='banks-detail__second-box__content-label__select'
                                    value={values?.typeAccount ? values.typeAccount : undefined}
                                    status={errors.typeAccount && touched.typeAccount ? 'error' : ''}
                                    popupClassName='banks-detail__second-box__content-label__select__popup'
                                    suffixIcon={
                                        <div className='banks-detail__second-box__content-label__select__icon'>
                                            <img
                                                alt={isDisabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                src={isDisabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                            />
                                        </div>
                                    }
                                />
                                <span className="banks-detail__second-box__content-label__error">{touched.typeAccount ? errors.typeAccount : null}</span>
                            </div>
                            <div className='banks-detail__second-box__content-label'>
                                <span className='banks-detail__second-box__content-label__title'>
                                    Número de cuenta <span style={{ color: '#F85F61' }}>*</span>
                                </span>
                                <Input
                                    disabled={isDisabled}
                                    value={values.numberAccount}
                                    placeholder='Escribe el número de cuenta'
                                    onBlur={(value) => handleBlur('numberAccount')(value)}
                                    onChange={(value) => onChange(value, 'numberAccount')}
                                    className='banks-detail__second-box__content-label__input'
                                    status={errors.numberAccount && touched.numberAccount ? 'error' : ''}
                                />
                                <span className="banks-detail__second-box__content-label__error">{touched.numberAccount ? errors.numberAccount : null}</span>
                            </div>
                            <div className='banks-detail__second-box__content-label'>
                                <span className='banks-detail__second-box__content-label__title'>
                                    Código de identificación <span style={{ color: '#F85F61' }}>*</span>
                                </span>
                                <Input
                                    disabled={isDisabled}
                                    value={values.identificationCode}
                                    placeholder='Escribe los últimos 4 dígitos'
                                    onBlur={(value) => handleBlur('identificationCode')(value)}
                                    className='banks-detail__second-box__content-label__input'
                                    onChange={(value) => onChange(value, 'identificationCode')}
                                    status={errors.identificationCode && touched.numberAccount ? 'error' : ''}
                                    readOnly={true}
                                />
                                <span className="banks-detail__second-box__content-label__error">{touched.numberAccount ? errors.identificationCode : null}</span>
                            </div>
                            <div className='banks-detail__second-box__content-label'>
                                <span className='banks-detail__second-box__content-label__title'>
                                    Sede o licorera <span style={{ color: '#F85F61' }}>*</span>
                                </span>
                                <Select
                                    options={optionsHeadquarters}
                                    disabled={isDisabled}
                                    popupMatchSelectWidth={false}
                                    placeholder='Selecciona una sede'
                                    className='banks-detail__second-box__content-label__select'
                                    onBlur={(value) => handleBlur('associatedOperation')(value)}
                                    onChange={(e, event) => onChange(event, 'associatedOperation')}
                                    popupClassName='banks-detail__second-box__content-label__select__popup'
                                    value={values?.associatedOperation ? values.associatedOperation : undefined}
                                    status={errors.associatedOperation && touched.associatedOperation ? 'error' : ''}
                                    suffixIcon={
                                        <div className='banks-detail__second-box__content-label__select__icon'>
                                            <img
                                                alt={isDisabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                src={isDisabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                            />
                                        </div>
                                    }
                                />
                                <span className="banks-detail__second-box__content-label__error">{touched.associatedOperation ? errors.associatedOperation : null}</span>
                            </div>
                            <div className='banks-detail__second-box__content-label'>
                                <span className='banks-detail__second-box__content-label__title'>
                                    Saldo inicial de la cuenta <span style={{ color: '#F85F61' }}>*</span>
                                </span>
                                <NumericFormat
                                    type='text'
                                    prefix={"$ "}
                                    placeholder='$ 0'
                                    disabled={isDisabled}
                                    thousandSeparator=","
                                    value={values.initialBalance}
                                    thousandsGroupStyle="thousand"
                                    onValueChange={({floatValue}) => onChange(floatValue, 'initialBalance')}
                                    onBlur={(value) => handleBlur('initialBalance')(value)}
                                    className={errors.initialBalance && touched.initialBalance ? 'input-price-error' : 'input-price'}
                                />
                                <span className="banks-detail__second-box__content-label__error">{touched.initialBalance ? errors.initialBalance : null}</span>
                            </div>
                            <div className='banks-detail__second-box__content-label'>
                                <span className='banks-detail__second-box__content-label__title'>
                                    Fecha de creación <span style={{ color: '#F85F61' }}>*</span>
                                </span>
                                <div className='banks-detail__second-box__content-label__content-picker'>
                                    <div className='banks-detail__second-box__content-label__content-picker__content-icon'>
                                        <img
                                            alt={isDisabled ? "icon_calendar_disabled" : "icon_calendar"}
                                            src={isDisabled ? Assets.SharedIcons.icon_calendar_disabled : Assets.SharedIcons.icon_calendar}
                                        />
                                    </div>
                                    <DatePicker
                                        clearIcon={null}
                                        suffixIcon={null}
                                        format="YYYY/MM/DD"
                                        disabled={isDisabled}
                                        placeholder="AAAA/MM/DD"
                                        onBlur={(value) => handleBlur('dateOfCreation')(value)}
                                        onChange={(e, date) => onChange(date, 'dateOfCreation')}
                                        status={errors.dateOfCreation && touched.dateOfCreation ? 'error' : ''}
                                        className='banks-detail__second-box__content-label__content-picker__picker'
                                        value={values.dateOfCreation ? dayjs(values.dateOfCreation, 'YYYY:MM:DD').utc() : null}

                                    />
                                </div>
                                <span className="banks-detail__second-box__content-label__error">{touched.dateOfCreation ? errors.dateOfCreation : null}</span>
                            </div>
                        </div>
                    </div>
                    <div className='grid-x justify-content-end banks-detail__content-buttons'>
                        <button
                            className='banks-detail__button__cancel'
                            onClick={() => history('/banksTreasury', { replace: true })}
                        >
                            <span>Cancelar</span>
                        </button>
                        <button
                            onClick={() => createOrUpdateBank(idBank || idBankCreated)}
                            className='banks-detail__button__save'
                            disabled={isDisabled || Object.keys(errors).length}
                        >
                            <span>Guardar cambios</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BanksDetailPage;