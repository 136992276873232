//Components
import WeekSelectorComponent from "../../components/week-selector/week-selector.component";
import {TablesPortfolioComponent} from "./components/tables-portfolio/tables-portfolio.component.jsx";

//Components - Shared
import ErrorAlertComponent from "../../../shared/components/alerts/error-alert/error-alert.component";

// Libraries
import dayjs from "dayjs";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Services
import {
  getAllPortfolioWalletService,
  getHistoricalPortfoliosService,
} from "../../../services/portfolio.services";
import { setModuleNameService } from "../../../services/authentication.services";

// Styles
import "./portfolio.page.scss";


const PortfolioPage = () => {
  let history = useNavigate();

  const { state: routeState } = useLocation();

  const INITIAL_STATE = {
    headquartersList: [],
    dateSelected: dayjs(),
    daySelected: dayjs(),
  };

  const [checkedItems, setCheckedItems] = useState([]);
  const [state, setState] = useState(INITIAL_STATE);
  const [searchValues, setSearchValues] = useState({});
  const { dateSelected, daySelected, headquartersList } = state;

  const handleDate = (date) => {
    setState({
      ...state,
      dateSelected: date,
    });
  };
  const previousDate = () => {
    const yearNew = dateSelected.subtract(1, "year");
    setState({
      ...state,
      dateSelected: yearNew,
    });
  };

  const nextDate = () => {
    const yearNew = dateSelected.add(1, "year");
    setState({
      ...state,
      dateSelected: yearNew,
    });
  };

  const previousWeek = () => {
    const week = dateSelected.subtract(1, "week");
    setState({
      ...state,
      dateSelected: week,
    });
  };

  const nextWeek = () => {
    const week = dateSelected.add(1, "week");
    setState({
      ...state,
      dateSelected: week,
    });
  };

  const selectDay = (day) => {
    setState({
      ...state,
      daySelected: day,
    });
  };

  useEffect(() => {
    //Send route name
    setModuleNameService("Carteras");
  }, []);

  useEffect(() => {
    if (routeState?.key && routeState?.key === 6) {
      getPortfolio(dayjs(daySelected).format("YYYY-MM-DD"));
    } else {
      getPortfolioByDate(dayjs(daySelected).format("YYYY-MM-DD"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected, routeState?.key]);

  const getPortfolio = async (date, filterValue) => {
    try {
      const res = await getAllPortfolioWalletService(date, filterValue);
      setState((prevState) => ({
        ...prevState,
        headquartersList: res,
      }));
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const getPortfolioByDate = async (date, filterValue) => {
    try {
      const res = await getHistoricalPortfoliosService(date, filterValue);
      setState((prevState) => ({
        ...prevState,
        headquartersList: res,
      }));
    } catch (error) {
      ErrorAlertComponent();
    }
  };
  const viewPortfolio = async (idHeadquarter) => {
    try {
      history(`/wallet/${idHeadquarter}`, {
        state: {
          dateSelected: dayjs(daySelected).format("YYYY-MM-DD"),
          key: routeState?.key,
        },
      });
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const handleCheckboxChange = (index) => {
    // Clones the array of selected items and adds or removes the item according to its current state
    setCheckedItems((prevItems) => {
      const isChecked = prevItems.includes(index);
      return isChecked
        ? prevItems.filter((itemIndex) => itemIndex !== index)
        : [...prevItems, index];
    });
  };

  const isItemChecked = (index) => checkedItems.includes(index);

  const handleSearch = async (eventSearch, indexSearch) => {
    const { value } = eventSearch.target;
    setSearchValues((prevState) => ({...prevState,[indexSearch]: value,}));
    if (value.length > 1 || value.length === 0) {
      try {
        if (routeState?.key && routeState?.key === 6) {
          await getPortfolio( dayjs(daySelected).format("YYYY-MM-DD"), value, indexSearch );
        } else {
          await getPortfolioByDate( dayjs(daySelected).format("YYYY-MM-DD"), value);
        }
      } catch (error) {
        ErrorAlertComponent();
      }
    }
  };

  return (
    <div className="portfolio-page__container">
      <WeekSelectorComponent
        handleDate={(date) => handleDate(date)}
        previousDate={() => previousDate()}
        nextDate={() => nextDate()}
        previousWeek={() => previousWeek()}
        nextWeek={() => nextWeek()}
        selectDay={(date) => selectDay(date)}
        dateSelected={dateSelected}
        daySelected={daySelected}
        typeDate="año"
      />
      {headquartersList.map((headquarters, indexHeadquarterList) => {
        return (
          <TablesPortfolioComponent
            titleTable={headquarters.name}
            key={indexHeadquarterList}
            idTable={headquarters.id}
            handleSearch={(eventSearch) => handleSearch(eventSearch, indexHeadquarterList)}
            handleCheckboxChange={(index) => handleCheckboxChange(index)}
            isItemChecked={(index) => isItemChecked(index)}
            viewPortfolio={(idHeadquarter) => viewPortfolio(idHeadquarter)}
            headquartersList={headquarters.data}
            searchValue={searchValues[indexHeadquarterList] || ""}
          />
        );
      })}
    </div>
  );
};

export default PortfolioPage;
