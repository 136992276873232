//Actions
import { setStateCashClosingReducer } from '../../../../storage/reducers/cash-closing/cash-closing.actions';

//Libraries
import { Input, Select } from 'antd';
import { connect } from 'react-redux';
import { NumericFormat } from "react-number-format";
import React, { useEffect, useRef, useState } from 'react';

//Assets
import { Assets } from '../../../../assets';

//Services
import { deletedRecordExpensesTableService } from '../../../../services/cash-closing.services';
import { getAllExpenseCategoryService, getExpenseSubCategoryService } from '../../../../services/shared.services';
import { deletedInventoryExpensesTableService } from '../../../../services/cash-closing-inventory.services';

//Styles
import '../../cash-closing.page.scss';

//Utils
import { dataExpenses } from '../../../../utils/expenses.utils';

const ExpensesComponent = (props) => {

    const {
        cashClosingData,
        setStateCashClosingReducer,
        //Functions
        getAllPortfolio,
        //Variables
        dateInventory
    } = props;

    const INITIAL_STATE = {
        scrollEnd: false,
        expenses: dataExpenses,
        listOfExpenseCategory: []
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { expenses, scrollEnd, listOfExpenseCategory } = state;

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current && scrollEnd) {
            const miDiv = formRef.current;
            miDiv.scrollTop = miDiv.scrollHeight;
        }
    }, [scrollEnd, expenses.length]);

    useEffect(() => {
        getListOfCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashClosingData?.cashRegisterCashExpense]);

    useEffect(() => {
        if (cashClosingData?.cashRegisterCashExpense) {
            try {
                let arr1 = [...INITIAL_STATE.expenses];
                let dataMod = [...cashClosingData.cashRegisterCashExpense];
                let arr2 = dataMod.map(async (data) => {
                    const respListSubCategories = await getListOfSubCategories(data?.expenseSubcategoryCashExpense?.expenseCategoryExpenseSubcategory?.id);
                    const { expenseSubcategoryCashExpense, ...restOfData } = data;
                    const listOfExpenseSubCategory = await respListSubCategories;
                    return {
                        ...restOfData,
                        category: {
                            label: data?.expenseSubcategoryCashExpense?.expenseCategoryExpenseSubcategory?.name?.toLowerCase()?.replace(/\b\w/g, c => c?.toUpperCase()),
                            value: data?.expenseSubcategoryCashExpense?.expenseCategoryExpenseSubcategory?.id,
                        },
                        subCategory: {
                            label: data?.expenseSubcategoryCashExpense?.name?.toLowerCase()?.replace(/\b\w/g, c => c?.toUpperCase()),
                            value: data?.expenseSubcategoryCashExpense?.id
                        },
                        disabled: false,
                        disabledSubCategory: false,
                        listOfExpenseSubCategory
                    };
                });
                Promise.all(arr2).then((resolvedArr2) => {
                    const minLen = Math.min(arr1.length, resolvedArr2.length);

                    for (let i = 0; i < minLen; i++) {
                        if (arr1[i].id !== resolvedArr2[i].id) {
                            arr1[i] = resolvedArr2[i];
                        }
                    }

                    if (arr1.length < resolvedArr2.length) {
                        for (let i = minLen; i < resolvedArr2.length; i++) {
                            arr1.push(resolvedArr2[i]);
                        }
                    }

                    setState((prevState) => ({
                        ...prevState,
                        expenses: arr1,
                        scrollEnd: false
                    }));
                });
            } catch (error) {
                // TODO: Implement error alert with code error.
            }
        } else {
            setState(INITIAL_STATE)
        }
        formRef.current.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashClosingData?.cashRegisterCashExpense]);

    const addExpenses = () => {
        const objectInputDisabled = expenses.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

        if (!objectInputDisabled) {
            const newArray = [
                ...expenses,
                {
                    price: "",
                    disabled: false,
                    invoiceNumber: "",
                    category: {
                        label: "",
                        value: "",
                    },
                    subCategory: {
                        label: "",
                        value: "",
                    },
                    disabledSubCategory: true,
                    listOfExpenseSubCategory: []
                }
            ];
            setState((prevState) => ({
                ...prevState,
                expenses: newArray,
                scrollEnd: true
            }))
        } else {
            const newArray = expenses.map(object => (object === objectInputDisabled ? { ...object, disabled: false } : object));
            setState((prevState) => ({
                ...prevState,
                expenses: newArray
            }))
        }
    };

    const handleChange = async (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        const newArray = JSON.parse(JSON.stringify(expenses));

        if ((name === "category") || (name === "subCategory")) {
            newArray[index][name] = {
                label: value.label,
                value: value.value
            }
            if (name === "category") {
                try {
                    const listOfExpenseSubCategory = await getListOfSubCategories(value.value);
                    newArray[index].listOfExpenseSubCategory = listOfExpenseSubCategory;
                    newArray[index].disabledSubCategory = false;
                    newArray[index].subCategory = { label: "", value: "" }
                } catch (error) {
                    // TODO: Implement error alert with code error.
                }
            }
        } else {
            newArray[index][name] = value
        }
    
        setState({
            ...state,
            expenses: newArray
        })
        setStateCashClosingReducer('expensesForm', newArray);
    };

    const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => {
          totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0);
        });
        totalPrice = parseFloat(totalPrice.toFixed(2));
        return totalPrice;
    };

    const getListOfCategories = async () => {
        try {
            const listOfExpenseCategory = await getAllExpenseCategoryService();
            setState((prevState) => ({
                ...prevState,
                listOfExpenseCategory
            }))
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const getListOfSubCategories = async (idExpenseCategory) => {
        try {
            return await getExpenseSubCategoryService({ idExpenseCategory });
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const removeDataExpenses = async (item, index) => {
        let respService = false;
        const initialItem = { price: "", invoiceNumber: "", category: { label: "", value: "" }, subCategory: { label: "", value: "" }, listOfExpenseSubCategory: [], disabledSubCategory: true, disabled: true };
        const newArray = JSON.parse(JSON.stringify(expenses));
        newArray[index] = initialItem;
        try {
            if (item.id) {
                if (dateInventory) {
                    respService = await deletedInventoryExpensesTableService({ id: item.id });
                }else{
                    respService = await deletedRecordExpensesTableService({ id: item.id });
                }
            };
            newArray.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);
            setState({ ...state, expenses: newArray });
            setStateCashClosingReducer('expensesForm', newArray);
            (item?.id && respService) && await getAllPortfolio();
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    return (
        <div className='small-6'>
            <div className='cash-closing__payments__container'>
                <div className='cash-closing__payments__content'>
                    <div className='grid-y'>
                        <div className='grid-x cash-closing__payments__content-titles__fist'>
                            <div className='grid-x align-middle small-6'>
                                <img src={Assets.SharedIcons.icon_information} alt="icon_information" />
                                <span className='cash-closing__payments__titles cash-closing__payments__titles__space'>Gastos</span>
                            </div>
                            <div className='grid-x align-middle small-6 justify-content-end'>
                                <span className='cash-closing__payments__titles'>Total:</span>
                                <div className='grid-x align-middle cash-closing__summary__details'>
                                    <div className='cash-closing__payments__table__content-icon-price'>
                                        <img
                                            alt="icon_egress"
                                            src={Assets.SharedIcons.icon_egress}
                                            className='cash-closing__summary__price__icon'
                                        />
                                    </div>
                                    <NumericFormat
                                        type='text'
                                        prefix={"$ "}
                                        disabled
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        value={handleTotal(expenses)}
                                        thousandsGroupStyle="thousand"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='grid-x cash-closing__payments__content-titles__second'>
                            <span className='grid-x cash-closing__payments__titles small-3'>Categoría</span>
                            <span className='grid-x cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>Subcategoría</span>
                            <span className='grid-x cash-closing__payments__titles cash-closing__payments__titles__space--mod small-3'>N° de la factura</span>
                            <span className='grid-x justify-content-center cash-closing__payments__titles small-2'>Valor</span>
                        </div>
                    </div>
                    <div ref={formRef} className='cash-closing__payments__info'>
                        {expenses?.map((item, index) => {
                            return (
                                <div key={index} className='grid-x justify-content-between cash-closing__payments__table'>
                                    <div className='small-3'>
                                        <Select
                                            optionLabelProp="label"
                                            placeholder='Categoría'
                                            disabled={item.disabled}
                                            popupMatchSelectWidth={false}
                                            options={listOfExpenseCategory}
                                            className='cash-closing__payments__select'
                                            popupClassName='cash-closing__payments__select__popup'
                                            value={item?.category?.value ? item?.category : undefined}
                                            onChange={(e, event) => handleChange(event, index, 'category')}
                                            suffixIcon={
                                                <div className='cash-closing__payments__select__icon'>
                                                    <img
                                                        alt={item.disabled ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                        src={item.disabled ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className='grid-x small-3'>
                                        <Select
                                            optionLabelProp="label"
                                            placeholder='Subcategoría'
                                            popupMatchSelectWidth={false}
                                            disabled={item.disabledSubCategory}
                                            options={item?.listOfExpenseSubCategory}
                                            className='cash-closing__payments__select' 
                                            popupClassName='cash-closing__payments__select__popup'
                                            value={item?.subCategory?.value ? item?.subCategory : undefined}
                                            onChange={(e, event) => handleChange(event, index, 'subCategory')}
                                            suffixIcon={
                                                <div className='cash-closing__payments__select__icon'>
                                                    <img
                                                        alt={item.disabledSubCategory ? "icon_disabled_arrow" : "icon_blue_arrow"}
                                                        src={item.disabledSubCategory ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                    />
                                                </div>
                                            }
                                        />
                                    </div>
                                    <div className='grid-x small-3'>
                                        <Input
                                            placeholder='0000'
                                            disabled={item?.disabled}
                                            value={item?.invoiceNumber}
                                            className='cash-closing__payments__input'
                                            onChange={(e) => handleChange(e, index, "invoiceNumber")}
                                            prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                        />
                                    </div>
                                    <div className='grid-x justify-content-between small-3'>
                                        <div className='grid-x small-9 cash-closing__payments__table__content-inputs'>
                                            <NumericFormat
                                                type='text'
                                                prefix={"$ "}
                                                placeholder='$ 0'
                                                value={item?.price}
                                                thousandSeparator=","
                                                className='input-price'
                                                disabled={item.disabled}
                                                thousandsGroupStyle="thousand"
                                                onValueChange={({floatValue}) => handleChange(floatValue, index, 'price')}
                                            />
                                            <div className='cash-closing__payments__table__content-icon-price'>
                                                <img
                                                    alt="icon_add"
                                                    src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                />
                                            </div>
                                        </div>
                                        <div className='grid-x small-2 justify-content-end'>
                                            <button
                                                onClick={() => removeDataExpenses(item, index)}
                                                className='cash-closing__payments__button-delete'
                                                disabled={item?.disabled || cashClosingData?.isPublish}
                                            >
                                                <img
                                                    className='cash-closing__payments__button-delete__icon'
                                                    alt={ item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                    src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='grid-x'>
                        <div className='cash-closing__payments__line'></div>
                        <button
                            onClick={() => addExpenses()}
                            disabled={cashClosingData?.isPublish}
                            className='grid-x align-middle cash-closing__payments__type cash-closing__payments__type--mod'
                        >
                            <img
                                src={Assets.SharedIcons.icon_egress}
                                alt='icon_egress'
                            />
                            <span className='cash-closing__payments__type__name'>Agregar gasto</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ CashClosingReducer }) => {
    const { cashClosingData } = CashClosingReducer;
    return {
        cashClosingData
    };
};

const mapStateToPropsActions = {
    setStateCashClosingReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ExpensesComponent);