//Assets
import { Assets } from '../../assets'

//Components
import WeekSelectorComponent from '../components/week-selector/week-selector.component'
import CashClosingTreasuryComponent from './components/cash-closing-treasury/cash-closing-treasury.component';
import { ModalDownloadExcelComponent } from '../../shared/components/modals/modal-download-excel/modal-download-excel.component';

//Components - Shared
import ErrorAlertComponent from '../../shared/components/alerts/error-alert/error-alert.component';
import SuccessAlertComponent from '../../shared/components/alerts/success-alert/success-alert.component';
import InformationAlertComponent from '../../shared/components/alerts/information-alert/information-alert.component';

//Libraries
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';
import { AutoComplete, Checkbox, Dropdown, Input, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react'

//Services
import {
  getAllHeadQuartersService,
  getExpenseSubCategoryService,
  getAllExpenseCategoryService,
  getPaymentMethodDailyTransactionService,
  getConceptNoReportService
} from '../../services/shared.services';
import {
  createDailyMovementsService,
  createPublishDailyMovementsService,
  deletedDailyMovementRecordService,
  getDownloadExcelService,
  getFindOneDailyMovementsService,
  updateDailyMovementsService,
} from '../../services/daily-movements.services';
import { setModuleNameService } from '../../services/authentication.services';

//Styles
import "./daily-movements.page.scss"

//Utils
import { formattedNumber } from '../../utils/shared.utils';
import { dailyMovementsExcelUtils } from '../../utils/daily-movements-excel.utils'
import { dataPayment, listBanksType, listBanksTypeInitial } from '../../utils/daily-movements.utils'

const DailyMovementsPage = () => {

  const INITIAL_STATE = {
    scrollEnd: false,
    isHoverHandleSave: false,
    daySelected: dayjs().isSameOrAfter(dayjs().set('hour', 6)) ? dayjs() : dayjs().subtract(1, 'day'),
    dateSelected: dayjs().isSameOrAfter(dayjs().set('hour', 6)) ? dayjs() : dayjs().subtract(1, 'day'),
    summaryOfBalance: {
      finalCash: 0,
      finalGain: 0,
      cashReceipt: 0,
      cashExpense: 0,
    },
    filterKeyCheck: "0",
    dataFilter: [],
    dailyMovementsByFilter: [],
    dailyMovementForm: dataPayment,
    dailyMovementFormOriginal: dataPayment,
    bankCreditTypeList: listBanksType,
    bankCreditTypeListInitial: listBanksTypeInitial,
    isViewModal: false,

    //State
    isFirstRenderState: false,
    headquartersListState: [],
    expenseCategoryListState: [],
    expenseSubCategoryListState: [],
    paymentMethodListState: [],
    paymentMethodBankListState: [],
    optionsConcept: [],
    conceptCreditListState: [],
    conceptExpenseListState: [],
  }
  const [openModal, setOpenModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);

  const [state, setState] = useState(INITIAL_STATE);
  const {
    dailyMovementForm, scrollEnd, bankCreditTypeList,
    isHoverHandleSave, dateSelected, daySelected,
    summaryOfBalance, filterKeyCheck, dailyMovementsByFilter,
    dailyMovementFormOriginal,bankCreditTypeListInitial,
    
    //New State
    isFirstRenderState,headquartersListState,
    expenseCategoryListState,expenseSubCategoryListState,
    paymentMethodListState,paymentMethodBankListState,
    optionsConcept,conceptCreditListState,conceptExpenseListState, 
    isViewModal
  } = state;

  const formRef = useRef(null);

  useEffect(() => {
    getDataDaily();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected])

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, dailyMovementForm.length]);


  const getDataDaily = async() => {
    let newStateRender = {};

    if (!isFirstRenderState) {
      //Send route name
      setModuleNameService('Movimientos diarios');
      const optionsData = await getServicesInformation();
      newStateRender = {...optionsData, isFirstRenderState: true};
    }

    const getDataDailyMovements = await getFindOneDailyMovements(dayjs(daySelected).format('YYYY-MM-DD'));
    newStateRender = {...newStateRender, ...getDataDailyMovements};

    setState(prevState => ({
      ...prevState,
      ...newStateRender
    }));
  };

  const optionSubcategories = (id) => {
    return expenseSubCategoryListState.filter(category => category.idExpenseCategory === id)
  }

  const optionPaymentMethod = (type,idHeadQuarter) => {
    let listPaymentMethod = type !== "0" ? paymentMethodBankListState : paymentMethodListState
    if (type === "1") {
      listPaymentMethod = listPaymentMethod.filter(item => item.idHeadQuarter === idHeadQuarter)
    }

    return listPaymentMethod
  }

  const handleDate = (date) => {
    setState({
      ...state,
      dateSelected: date
    });
  }
  const previousDate = () => {
    const monthNew = dateSelected.subtract(1, 'month')
    setState({
      ...state,
      dateSelected: monthNew
    });
  }

  const nextDate = () => {
    const monthNew = dateSelected.add(1, 'month')
    setState({
      ...state,
      dateSelected: monthNew
    });
  }

  const previousWeek = () => {
    const week = dateSelected.subtract(1, 'week')
    setState({
      ...state,
      dateSelected: week
    });
  }

  const nextWeek = () => {
    const week = dateSelected.add(1, 'week')
    setState({
      ...state,
      dateSelected: week
    });
  }

  const selectDay = (day) => {
    setState({
      ...state,
      daySelected: day
    });
  };

  const getFindOneDailyMovements = async (date) => {
    try {
      const respFindOneDailyMovements = await getFindOneDailyMovementsService(date);
      const listOfDailyMovementsService = respFindOneDailyMovements.transactions;
      const dailyMovementFormAux = [...INITIAL_STATE.dailyMovementForm];

      const dailyMovementsMod = await Promise.all(listOfDailyMovementsService.map(async (dailyMovement) => {
          const isCredit = dailyMovement?.isCredit === 1;
          const isBank = dailyMovement?.paymentMethodDailyTransactionTransaction?.isBank === 1;

          return {
            id: dailyMovement.id,
            bankCreditType: {
              label: !isBank ? bankCreditTypeList[0].label : bankCreditTypeList[1].label,
              value: !isBank ? bankCreditTypeList[0].value : bankCreditTypeList[1].value,
            },
            headQuarter: {
              label: dailyMovement?.headQuarterTransaction?.name,
              value: dailyMovement?.headQuarterTransaction?.id,
            },
            conceptDescription: dailyMovement?.concept,
            conceptCategory: {
              label: dailyMovement?.expenseSubcategoryTransaction?.expenseCategoryExpenseSubcategory?.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase()) || undefined,
              value: dailyMovement?.expenseSubcategoryTransaction?.expenseCategoryExpenseSubcategory?.id || undefined,
            },
            subCategories: {
              label: dailyMovement?.expenseSubcategoryTransaction?.name.toLowerCase().replace(/\b\w/g, c => c.toUpperCase()) || undefined,
              value: dailyMovement?.expenseSubcategoryTransaction?.id || undefined,
            },
            paymentMethod: {
              label: dailyMovement?.paymentMethodDailyTransactionTransaction?.name,
              value: dailyMovement?.paymentMethodDailyTransactionTransaction?.id,
            },
            price: dailyMovement?.price,
            disabled: false,
            isCredit,
            isNotReported: dailyMovement?.isNotReported === 1,
          };
      }));

      const minLen = Math.min(dailyMovementFormAux.length, dailyMovementsMod.length);

      for (let i = 0; i < minLen; i++) {
        if (dailyMovementFormAux[i].id !== dailyMovementsMod[i].id) {
          dailyMovementFormAux[i] = dailyMovementsMod[i];
        }
      }

      if (dailyMovementFormAux.length < dailyMovementsMod.length) {
        for (let i = minLen; i < dailyMovementsMod.length; i++) {
          dailyMovementFormAux.push(dailyMovementsMod[i]);
        }
      }

      formRef.current.scrollTop = 0;

      const conceptCreditListState = await getConceptNoReportService(0)
      const conceptExpenseListState = await getConceptNoReportService(1)

      return({
        scrollEnd: false,
        dailyMovementForm: dailyMovementFormAux,
        dailyMovementFormOriginal: dailyMovementFormAux.map(objeto => ({ ...objeto })),
        summaryOfBalance: {
          finalCash: respFindOneDailyMovements.dailyTransaction?.previousBalance,
          cashReceipt: respFindOneDailyMovements.dailyTransaction?.cashReceived,
          cashExpense: respFindOneDailyMovements.dailyTransaction?.cashOutflow,
          finalGain:  ( respFindOneDailyMovements.dailyTransaction?.previousBalance) 
                      + respFindOneDailyMovements.dailyTransaction?.cashReceived 
                      - respFindOneDailyMovements.dailyTransaction?.cashOutflow
        },
        conceptCreditListState,
        conceptExpenseListState
      })

    } catch (error) {
      ErrorAlertComponent(undefined, error.code === "TRSR_009" ? "No se encontraron resultados" : undefined);
      return({
        scrollEnd: false,
        summaryOfBalance: INITIAL_STATE.summaryOfBalance,
        dailyMovementForm: INITIAL_STATE.dailyMovementForm,
      })
    }
  };

  const getServicesInformation = async () => {
    try {
      const responseServices = await Promise.allSettled([
        getAllHeadQuartersService(),
        getAllExpenseCategoryService(),
        getExpenseSubCategoryService(),
        getPaymentMethodDailyTransactionService({ isBank: false }),
        getPaymentMethodDailyTransactionService({ isBank: true }),
      ])
      let respServices = {}
      let errors = false

      for (let index = 0; index < responseServices.length; index++) {
        const elementResponse = responseServices[index];
        if (elementResponse.status === "fulfilled") {
            switch (index) {
                case 0:
                  respServices.headquartersListState = elementResponse?.value
                break;
                case 1:
                  respServices.expenseCategoryListState = elementResponse?.value
                break;
                case 2:
                  respServices.expenseSubCategoryListState = elementResponse?.value
                break;
                case 3:
                  respServices.paymentMethodListState = elementResponse?.value
                break;
                case 4:
                  respServices.paymentMethodBankListState = elementResponse?.value
                break;
                default:
                break;
            }
        }else{
          errors = true
        }
      };

      if (errors) {
        ErrorAlertComponent()
      }

      return respServices
    } catch (error) {
      ErrorAlertComponent()
    }
  };

  const toggleModalClosing = () => {
    setOpenModal(prevState => !prevState);
  };

  const addPaymentsExpenses = (isCashReceipt) => {
    const objectInputDisabled = dailyMovementForm.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

    if (!objectInputDisabled) {
      const newItemInArrayOfDailyMovement = [
        ...dailyMovementForm,
        {
          bankCreditType: bankCreditTypeListInitial[0],
          headQuarter: { label: "", value: "" },
          conceptDescription: "",
          conceptCategory: { label: "", value: "" },
          subCategories: { label: "", value: "" },
          paymentMethod: { label: "", value: "" },
          price: "",
          disabled: false,
          isCredit: isCashReceipt === 1,
          isNotReported: false,
        },
      ];

      const newData = {
        0: newItemInArrayOfDailyMovement,
        1: newItemInArrayOfDailyMovement.filter(obj => obj.isCredit),
        2: newItemInArrayOfDailyMovement.filter(obj => !obj.isCredit),
      };

      const typeOfDailyMovements = newData[filterKeyCheck];
      const newFilter = [...INITIAL_STATE.dailyMovementForm];

      let whileEntry = false;

      while (typeOfDailyMovements.length < 10 && newFilter.length > 0) {
        whileEntry = true;
        typeOfDailyMovements.push(newFilter.shift());
      };

      setState({
        ...state,
        dailyMovementsByFilter: typeOfDailyMovements,
        dailyMovementForm: newItemInArrayOfDailyMovement,
        scrollEnd: filterKeyCheck === "0" || !whileEntry ? true : false,
      });

    } else {

      const updatedForm = dailyMovementForm.map(object => (
        object === objectInputDisabled
          ? { ...object, disabled: false, isCredit: isCashReceipt === 1, bankCreditType: bankCreditTypeListInitial[0] }
          : object
      ));

      const newData = {
        0: updatedForm,
        1: updatedForm.filter(object => object.isCredit),
        2: updatedForm.filter(object => !object.isCredit),
      };

      const typeOfDailyMovements = newData[filterKeyCheck];
      const newFilter = [...INITIAL_STATE.dailyMovementForm];

      while (typeOfDailyMovements.length < 10 && newFilter.length > 0) {
        typeOfDailyMovements.push(newFilter.shift());
      }

      setState({
        ...state,
        dailyMovementForm: updatedForm,
        dailyMovementsByFilter: typeOfDailyMovements
      });
    };
  };

  const handleChange = async (e, index, name) => {
    const newArray = [...dailyMovementForm];
    const newArrayFilter = [...dailyMovementsByFilter];
    const value = e && e.target ? e.target.value || '' : e;
    const indexData = filterKeyCheck === "0" ? index : dailyMovementForm.findIndex(dailyMovement => dailyMovement === dailyMovementsByFilter[index]);
  
    const clearPaymentMethod = (array, idx) => {
      if (array[idx]?.paymentMethod?.value) {
        array[idx].paymentMethod = { label: "", value: "" };
      }
    };
  
    const clearConceptCategory = (array, idx) => {
      array[idx].conceptCategory = { label: "", value: "" };
      clearSubCategory(array, idx);
    };

    const clearSubCategory = (array, idx) => {
      if (array[idx]?.subCategories?.value) {
        array[idx].subCategories = { label: "", value: "" };
      }
    };

    const clearBankCreditType = (array, idx) => {
      if (array[idx]?.bankCreditType?.value) {
        array[idx].bankCreditType = bankCreditTypeListInitial[0];
      }
    };

    if (name === "isNotReported") {
      if (newArray[indexData]?.bankCreditType?.value !== bankCreditTypeListInitial[0]?.value) {
        clearPaymentMethod(newArray, indexData);
      }
      clearBankCreditType(newArray, indexData);
      if (filterKeyCheck === "1" || filterKeyCheck === "2") {
        if (newArrayFilter[index]?.bankCreditType?.value !== bankCreditTypeListInitial[0]?.value) {
          clearPaymentMethod(newArrayFilter, index);
        }
        clearBankCreditType(newArrayFilter, index);
      }

    }
  
    if (name === 'bankCreditType' || name === 'headQuarter') {
      clearPaymentMethod(newArray, indexData);
  
      if (filterKeyCheck === "1" || filterKeyCheck === "2") {
        clearPaymentMethod(newArrayFilter, index);
      }
    }
  
    if (name === 'bankCreditType' && value?.value !== '2') {
      clearConceptCategory(newArray, indexData);
  
      if (filterKeyCheck === "1" || filterKeyCheck === "2") {
        clearConceptCategory(newArrayFilter, index);
      }
    }
  
    if ((name === 'conceptCategory' && !newArray[indexData]?.isCredit) || (name === 'conceptCategory' && newArray[indexData]?.isCredit && newArray[indexData]?.bankCreditType?.value !== '2')) {

      clearSubCategory(newArray, indexData);

      if (filterKeyCheck === "1" || filterKeyCheck === "2") {
        clearSubCategory(newArrayFilter, index);
      }
    }
  
    newArray[indexData][name] = value;
  
    if (filterKeyCheck === "1" || filterKeyCheck === "2") {
      newArrayFilter[index][name] = value;
    }
  
    setState({
      ...state,
      dailyMovementForm: newArray,
      dailyMovementsByFilter: newArrayFilter
    });
  };
  
  const items = [
    {
      key: '0',
      label:
        <div className='daily-movements__payments__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKeyCheck === "0"}
            onChange={() => handleFilter("0")}
          >
            Ver todos
          </Checkbox>
        </div>
    },
    {
      key: '1',
      label:
        <div className='daily-movements__payments__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKeyCheck === "1"}
            onChange={() => handleFilter("1")}
          >
            Ver Abonos
          </Checkbox>
        </div>
    },
    {
      key: '2',
      label:
        <div className='daily-movements__payments__checkbox'>
          <Checkbox
            style={{ width: '100%' }}
            checked={filterKeyCheck === "2"}
            onChange={() => handleFilter("2")}
          >
            Ver Cobros
          </Checkbox>
        </div>
    },
  ];

  const handleFilter = (key) => {
    const newData = {
      0: dailyMovementForm,
      1: dailyMovementForm.filter(object => object.isCredit),
      2: dailyMovementForm.filter(object => !object.isCredit && object?.conceptCategory && object?.subCategories),
    };

    const typeOfDailyMovements = key in newData ? newData[key] : dailyMovementForm;
    const newFilter = [...INITIAL_STATE.dailyMovementForm];

    while (typeOfDailyMovements.length < 10 && newFilter.length > 0) {
      typeOfDailyMovements.push(newFilter.shift());
    }

    setState({
      ...state,
      filterKeyCheck: key,
      dailyMovementsByFilter: typeOfDailyMovements,
    });

    formRef.current.scrollTop = 0;
  };

  const handleSave = async (isPublish) => {
    const createNewRecords = [];
    const updateRecords = [];
    let errorForm = false

    for (const dailyMovementObject of dailyMovementForm) {
      if (dailyMovementObject.hasOwnProperty('disabled') && !dailyMovementObject.disabled) {
        if (
          (dailyMovementObject?.headQuarter.value && dailyMovementObject?.conceptDescription && dailyMovementObject?.subCategories?.value && dailyMovementObject?.paymentMethod.value && dailyMovementObject?.price) ||
          (dailyMovementObject?.headQuarter.value && dailyMovementObject?.conceptDescription && dailyMovementObject?.paymentMethod?.value && dailyMovementObject?.price)
        ) {
          const newDataCreateObject = validateAndStructure(dailyMovementObject)
          if (dailyMovementObject.hasOwnProperty('id')) {
            const objectSelectedOriginal = dailyMovementFormOriginal.find(object => object.id === dailyMovementObject.id)
            const originalDataCreateObject = validateAndStructure(objectSelectedOriginal)
            const updateData = findChanges(originalDataCreateObject,newDataCreateObject)
            if(Object.keys(updateData).length > 0){
              updateRecords.push(updateData)
            }
          } else {
            createNewRecords.push(newDataCreateObject)
          }

        } else {
          errorForm = true
        }
      };
    };

    if (errorForm) {
      ErrorAlertComponent('', 'Faltan campos por completar')
      return
    }

    if (updateRecords.length > 0 && createNewRecords.length > 0) {
      await createAndUpdateDailyMovements({key: 2, updateRecords, createNewRecords})
    }else{
      if (createNewRecords.length > 0) {
        await createAndUpdateDailyMovements({key: 0, createNewRecords})
      }
      if (updateRecords.length > 0) {
        await createAndUpdateDailyMovements({key: 1, updateRecords})
      }

      if (createNewRecords.length === 0 && updateRecords.length === 0 && !isPublish) {
        InformationAlertComponent(undefined, 'No se han detectado cambios')
      }
    }
  };

  const validateAndStructure = (object) => {
    let newPrice = typeof object.price === 'string' ? object.price.replace(/[^0-9.-]+/g, '') : object.price
    const newDataCreateObject = {
      type: Number(object.bankCreditType.value) + 1,
      isCredit: object.isCredit,
      concept: object.conceptDescription,
      isNotReported: object.isNotReported,
      idHeadQuarter: object.headQuarter.value,
      price: parseFloat(newPrice),
      idPaymentMethodDailyTransactions: object.paymentMethod.value
    };
    if (!object?.isCredit) {
      newDataCreateObject.idExpenseSubcategory = object.subCategories.value;
    }
    if (object.id) {
      newDataCreateObject.idTransaction = object.id
    }
    return newDataCreateObject
  }

  const findChanges = (object1, object2) =>{
    return Object.keys(object2).reduce((changes, key) => {
      if (object1.hasOwnProperty(key) && object1[key] !== object2[key]) {
        changes[key] = object2[key];
        (!changes.idTransaction) && (changes.idTransaction = object1.idTransaction)
      }
      return changes;
    }, {});
  }

  const createAndUpdateDailyMovements = async ({key, createNewRecords, updateRecords}) => {
    switch (key) {
      case 0:
        try {
          await createDailyMovementsService({ date: dayjs(daySelected)?.format('YYYY-MM-DD'), dailyTransactionTreasury: createNewRecords })
          SuccessAlertComponent(undefined, 'Los registros han sido guardados con éxito')
        } catch (error) {
          ErrorAlertComponent(undefined, 'Algunos registros no han podido ser guardados')
        }
        break;
      case 1:
        try {
          await updateDailyMovementsService({ date: dayjs(daySelected).format('YYYY-MM-DD'), dailyTransactionTreasury: updateRecords })
          SuccessAlertComponent(undefined, 'Los registros han sido actualizados con éxito')
        } catch (error) {
          ErrorAlertComponent(undefined, 'Algunos registros no han podido ser actualizados')
        }
        break;
      case 2:
        try {
          const resp = await Promise.allSettled([
            createDailyMovementsService({ date: dayjs(daySelected)?.format('YYYY-MM-DD'), dailyTransactionTreasury: createNewRecords }),
            updateDailyMovementsService({ date: dayjs(daySelected).format('YYYY-MM-DD'), dailyTransactionTreasury: updateRecords })
          ])

          if (resp[0].status === "fulfilled") {
            SuccessAlertComponent(undefined, 'Los registros han sido guardados con éxito')
          } else {
            ErrorAlertComponent(undefined, 'Algunos registros no han podido ser guardados')
          }

          if (resp[1].status === "fulfilled") {
            SuccessAlertComponent(undefined, 'Los registros han sido actualizados con éxito')
          }else{
            ErrorAlertComponent(undefined, 'Algunos registros no han podido ser actualizados')
          }
        } catch (error) {
          ErrorAlertComponent()
        }
        break;
    
      default:
        break;
    }

    try {
      const dailyMovements = await getFindOneDailyMovements(dayjs(daySelected).format('YYYY-MM-DD'))
      setState(prevState => ({
        ...prevState,
        ...dailyMovements
      }))
    } catch (error) {
      ErrorAlertComponent()
    }

  }

  const handlePublish = async () => {
    try {
      await handleSave(true)
      await createPublishDailyMovementsService({ date: dayjs(daySelected).format('YYYY-MM-DD') })
      SuccessAlertComponent(undefined, 'Se ha publicado el movimiento')
    } catch (error) {
      ErrorAlertComponent(undefined, 'No se pudo publicar el movimiento')
    }
  }

  const filterConcepts = async(concepts, type, isSearch, index) => {
    try {
      const newArray = [...dailyMovementForm];
      const newArrayFilter = [...dailyMovementsByFilter];
      const indexData = filterKeyCheck === "0" ? index : dailyMovementForm.findIndex(dailyMovement => dailyMovement === dailyMovementsByFilter[index]);
      newArray[indexData].conceptDescription = concepts;

      if (filterKeyCheck === "1" || filterKeyCheck === "2") {
        newArrayFilter[index].conceptDescription = concepts;
      }

      let typeFilter = type === "0" ? conceptCreditListState : conceptExpenseListState;

      const listConcept = isSearch ? typeFilter?.filter(object => 
        object?.value?.toLowerCase()?.includes(concepts?.toLowerCase())
      ) : []

      setState(prevState => ({
        ...prevState,
        optionsConcept: listConcept,
        dailyMovementForm: newArray,
        dailyMovementsByFilter: newArrayFilter
      }))

    } catch (error) {
      ErrorAlertComponent(undefined, 'No se pudieron cargar los conceptos')
    }
  }

  const optionsType = (num)=>{
    return bankCreditTypeListInitial.slice(0, num)
  }

  const removeDataDailyMovements = async (item, index) => {
    const initialValueItem = {
      bankCreditType: { label: "", value: "" },
      headQuarter: { label: "", value: "" },
      conceptDescription: "",
      conceptCategory: { label: "", value: "" },
      subCategories: { label: "", value: "" },
      paymentMethod: { label: "", value: "" },
      price: "",
      disabled: true,
      isCredit: false,
      isNotReported: false,
      disabledCategory: true,
      disabledSubCategory: true,
      disabledPaymentMethod: true,
      listOfPaymentMethod: [],
      listOfExpenseSubCategory: [],
    };
    const dailyMovementFormCopy = [...dailyMovementForm];
    const dailyMovementsByFilterCopy = [...dailyMovementsByFilter];

    try {
      const deleteTransaction = async () => {
        await deletedDailyMovementRecordService({ date: dayjs(daySelected).format('YYYY-MM-DD'), transactionsIds: [item?.id] });
        return await getFindOneDailyMovements(dayjs(daySelected).format('YYYY-MM-DD'));
      };

      if (filterKeyCheck === "0") {
        dailyMovementFormCopy[index] = initialValueItem;
      } else {
        const indexData = filterKeyCheck === "0" ? index : dailyMovementForm.findIndex(dailyMovement => dailyMovement === dailyMovementsByFilter[index]);
        dailyMovementFormCopy[indexData] = initialValueItem;
        dailyMovementsByFilterCopy[index] = initialValueItem;
      };

      let dailyMovements = {};
      if (item?.id) {
        dailyMovements = await deleteTransaction();
      };

      dailyMovementFormCopy.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);
      dailyMovementsByFilterCopy.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);

      if (item?.id) {
        setState(prevState => ({
          ...prevState,
          ...dailyMovements,
          dailyMovementsByFilter: dailyMovementsByFilterCopy
        }))
      } else {
        setState(prevState => ({
          ...prevState,
          dailyMovementForm: dailyMovementFormCopy,
          dailyMovementsByFilter: dailyMovementsByFilterCopy
        }));
      };

    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      isViewModal: false,
      startSelected: undefined,
      endSelected: undefined
    }))
  }

  const onDateChange = (dates) => {
    setSelectedDates(dates);
  };

  const downloadExcel = async () => {
    try {
      const startDate = selectedDates[0].format('YYYY-MM-DD');
      const endDate = selectedDates[1].format('YYYY-MM-DD');

      const dataExcel = await getDownloadExcelService(startDate, endDate);
      const dataExcelArray = Array.isArray(dataExcel) ? dataExcel : [];

      dailyMovementsExcelUtils({
        transactions: dataExcelArray,
        dateRange: { startDate, endDate }
      });

    } catch (error) {
      InformationAlertComponent(undefined, error.code === "TRSR_011" ? "No se encontraron resultados" : undefined);
    }
  }

  return (
    <div className="daily-movements__container">
      <WeekSelectorComponent
        daySelected={daySelected}
        nextDate={() => nextDate()}
        nextWeek={() => nextWeek()}
        dateSelected={dateSelected}
        previousWeek={() => previousWeek()}
        previousDate={() => previousDate()}
        selectDay={(date) => selectDay(date)}
        handleDate={(date) => handleDate(date)}
        disabledDate={dayjs().isSameOrAfter(dayjs().set('hour', 6)) ? dayjs() : dayjs().subtract(1, 'day')}
      />
      <div className="grid-x daily-movements__box">
        <div className="grid-x small-8 daily-movements__summary">
          <div className="small-3">
            <div className="daily-movements__summary__box">
              <span className="daily-movements__summary__text">
                Saldo del día anterior
              </span>
              <div className="grid-x daily-movements__summary__details align-middle justify-content-around">
                <img src={Assets.SharedIcons.icon_result} alt="icon_result" />
                <span className="grid-x small-10 align-right daily-movements__summary__price">
                  {formattedNumber(summaryOfBalance?.finalCash)}
                </span>
              </div>
            </div>
          </div>
          <div className='small-3'>
            <div className="daily-movements__summary__box">
              <span className="daily-movements__summary__text">
                Efectivo final
              </span>
              <div className="grid-x daily-movements__summary__details align-middle justify-content-around">
                <img src={Assets.SharedIcons.icon_result} alt="icon_result" />
                <span className="grid-x small-10 align-right daily-movements__summary__price">
                  {formattedNumber(summaryOfBalance?.finalGain)}
                </span>
              </div>
            </div>
          </div>
          <div className='small-3'>
            <div className="daily-movements__summary__box">
              <span className="daily-movements__summary__text">
                Total de efectivo recibido
              </span>
              <div className="grid-x daily-movements__summary__details align-middle justify-content-around">
                <img
                  src={Assets.SharedIcons.icon_income}
                  alt="icon_income"
                  height={"23px"}
                  width={"23px"}
                />
                <span className="grid-x small-10 align-right daily-movements__summary__price">
                  {formattedNumber(summaryOfBalance?.cashReceipt)}
                </span>
              </div>
            </div>
          </div>
          <div className='small-3'>
            <div className="daily-movements__summary__box">
              <span className="daily-movements__summary__text">
                Total de salidas de efectivo
              </span>
              <div className="grid-x daily-movements__summary__details align-middle justify-content-around">
                <img
                  src={Assets.SharedIcons.icon_egress}
                  alt="icon_egress"
                  height={"23px"}
                  width={"23px"}
                />
                <span className="grid-x small-10 align-right daily-movements__summary__price">
                  {formattedNumber(summaryOfBalance?.cashExpense)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-x small-4 align-center-middle justify-content-end'>
          <button
            onClick={() => handleSave()}
            className='daily-movements__button__save'
            onMouseOver={() => setState((prevState) => ({ ...prevState, isHoverHandleSave: true }))}
            onMouseOut={() => setState((prevState) => ({ ...prevState, isHoverHandleSave: false }))}
          >
            <img
              className='daily-movements__button__save__icon-save'
              alt={isHoverHandleSave ? 'icon_save_hover' : 'icon_save'}
              src={isHoverHandleSave ? Assets.SharedIcons.icon_save_hover : Assets.SharedIcons.icon_save}
            />
            <span>Guardar y actualizar</span>
          </button>
          <button
            className='daily-movements__button__publish'
            onClick={() => handlePublish()}
          >
            <span>Finalizar y publicar</span>
            <img
              alt='icon_publish'
              src={Assets.SharedIcons.icon_publish}
              className='daily-movements__button__publish__icon-publish'
            />
          </button>
          <button
            className='daily-movements__button-download'
            onClick={() => setState((prevState) => ({ ...prevState, isViewModal: true }))}
          >
            <span>Generar Excel</span>
            <img
              alt='icon_publish'
              src={Assets.SharedIcons.icon_publish}
              className='daily-movements__button-download__icon-publish'
            />
          </button>
        </div>
      </div>
      <div className="grid-x daily-movements__payments">
        <div className="daily-movements__payments__content">
          <div className='grid-x justify-content-between'>
            <div className='align-middle'>
              <Dropdown menu={{ items }} trigger={['click']} className='grid-x small-12'>
                <div style={{ cursor: 'pointer' }}>
                  <img
                    className='daily-movements__payments__icon-filter'
                    src={Assets.SharedIcons.icon_filter}
                    alt="icon_filter" />
                  <span className="daily-movements__summary__price">Pagos y gastos extras</span>
                </div>
              </Dropdown>
            </div>
            <div className='grid-x align-middle'>
              <button
                className="daily-movements__summary__view-cash-closing"
                onClick={toggleModalClosing}
              >
                Ver cierre de caja
              </button>
              <img src={Assets.SharedIcons.icon_arrow} alt="icon_arrow" />
            </div>
          </div>
          <div className='grid-x daily-movements__subtitle__content'>
            <span className='grid-x small-3 daily-movements__subtitle'>
              <span className='grid-x align-middle daily-movements__input-width__report'>
                No repo.
              </span>
              <span className='grid-x align-middle daily-movements__input-width__type'>
                Tipo
              </span>
              <span className='grid-x align-middle daily-movements__input-width'>
                Sede o negocio
              </span>
            </span>
            <span className='grid-x align-middle small-2 daily-movements__subtitle'>
              Concepto
            </span>
            <div className='grid-x small-7'>
              <span className='small-3 daily-movements__subtitle grid-x align-middle'>Categoría</span>
              <span className='small-3 daily-movements__subtitle grid-x align-middle'>Subcategorías</span>
              <span className='small-3 daily-movements__subtitle grid-x align-middle'>Tipo de pago</span>
              <span className='small-3 daily-movements__subtitle grid-x align-middle'>Valor</span>
            </div>
          </div>
          <div ref={formRef} className='daily-movements__summary__content'>
            {(filterKeyCheck === "0" ? dailyMovementForm : dailyMovementsByFilter).map((item, index) => {
              const disabledItemCategory = item.disabled || (item?.isCredit && item?.bankCreditType?.value !== "2")
              return (
                <div key={index} className='grid-x daily-movements__payments__container'>
                  <div className='grid-x small-3'>
                    <div className='daily-movements__payments__checkbox daily-movements__input-width__report'>
                      <Checkbox
                        disabled={item.disabled}
                        checked={item.isNotReported}
                        onChange={(e) => handleChange(e.target.checked, index, 'isNotReported')}
                      />
                    </div>
                    <div className='daily-movements__input-width__type'>
                      <Select
                        placeholder='Selección'
                        optionLabelProp='label'
                        disabled={item.disabled}
                        options={item.isNotReported ? optionsType(1) : optionsType((item?.id || !item?.isCredit) ? -1 : 3)}
                        popupMatchSelectWidth={false}
                        className='daily-movements__payments__select'
                        popupClassName='daily-movements__payments__select__popup'
                        value={item?.bankCreditType?.value ? item?.bankCreditType : undefined}
                        onChange={(e, event) => handleChange(event, index, 'bankCreditType')}
                        suffixIcon={
                          <div className={item.disabled ? 'daily-movements__payments__select__icon' : 'daily-movements__payments__select__icon__active'}>
                            <img
                              src={item.disabled ? Assets.SharedIcons.icon_gray_arrow : Assets.SharedIcons.icon_blue_arrow}
                              alt="icon_gray_arrow"
                            />
                          </div>
                        }
                        optionRender={(option) => {
                          return (
                            <div className='grid-x align-middle'>
                              <img
                                src={option?.data?.icon}
                                alt={option?.data?.text}
                                className='daily-movements__payments__select__icon-option-render'
                              />
                              <span>{option?.data?.text}</span>
                            </div>
                          )
                        }}
                      />
                    </div>
                    <div className='daily-movements__input-width'>
                      <Select
                        placeholder='Selección'
                        optionLabelProp='label'
                        disabled={item.disabled}
                        options={headquartersListState}
                        popupMatchSelectWidth={false}
                        className='daily-movements__payments__select'
                        popupClassName='daily-movements__payments__select__popup'
                        value={item?.headQuarter?.value ? item?.headQuarter : undefined}
                        onChange={(e, event) => handleChange(event, index, 'headQuarter')}
                        suffixIcon={
                          <div className={item.disabled ? 'daily-movements__payments__select__icon' : 'daily-movements__payments__select__icon__active'}>
                            <img
                              src={item.disabled ? Assets.SharedIcons.icon_gray_arrow : Assets.SharedIcons.icon_blue_arrow}
                              alt="icon_gray_arrow"
                            />
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <div className='grid-x small-2 daily-movements__payments__input__content'>
                    <AutoComplete
                      onSearch={(e) => {
                        const trimmedValue = e.trimStart();
                        if (trimmedValue) {
                          filterConcepts(trimmedValue, item?.isCredit ? "1" : "0", item?.isNotReported, index);
                        } else {
                          handleChange("", index, 'conceptDescription')
                          setState({ ...state, optionsConcept: [] });
                        }
                      }}
                      options={item.isNotReported ? optionsConcept : []}
                      onSelect={(value)=> handleChange(value, index, 'conceptDescription')}
                      disabled={item.disabled}
                      value={item?.conceptDescription}
                      className='small-12'
                      onFocus={() => filterConcepts(item.conceptDescription, item?.isCredit ? "1" : "0", item?.isNotReported, index)}
                    >
                      <Input
                        placeholder='Concepto'
                        className='daily-movements__payments__input'
                        prefix={
                          <img
                            className='daily-movements__payments__icon'
                            alt={item.disabled ? "icon_add" : item.isCredit ? "icon_income" : "icon_egress"}
                            src={item.disabled ? Assets.SharedIcons.icon_add : item.isCredit ? Assets.SharedIcons.icon_income : Assets.SharedIcons.icon_egress}
                          />
                        }
                      />
                    </AutoComplete>
                  </div>
                  <div className='grid-x small-7'>
                    <div className='small-3'>
                      <Select
                        placeholder='Selección'
                        optionLabelProp='label'
                        popupMatchSelectWidth={false}
                        options={expenseCategoryListState}
                        disabled={disabledItemCategory}
                        className='daily-movements__payments__select'
                        popupClassName='daily-movements__payments__select__popup'
                        onChange={(e, event) => handleChange(event, index, 'conceptCategory')}
                        value={item?.conceptCategory?.value ? item?.conceptCategory : undefined}
                        suffixIcon={
                          <div className={disabledItemCategory ? 'daily-movements__payments__select__icon' : 'daily-movements__payments__select__icon__active'}>
                            <img
                              src={disabledItemCategory ? Assets.SharedIcons.icon_gray_arrow : Assets.SharedIcons.icon_blue_arrow}
                              alt="icon_gray_arrow"
                            />
                          </div>
                        }
                      />
                    </div>
                    <div className='small-3'>
                      <Select
                        placeholder='Selección'
                        optionLabelProp='label'
                        popupMatchSelectWidth={false}
                        disabled={!item?.conceptCategory?.value}
                        options={item?.conceptCategory?.value ? optionSubcategories(item?.conceptCategory?.value) : []}
                        className='daily-movements__payments__select'
                        popupClassName='daily-movements__payments__select__popup'
                        value={item?.subCategories?.value ? item?.subCategories : undefined}
                        onChange={(e, event) => handleChange(event, index, 'subCategories')}
                        suffixIcon={
                          <div className={!item?.conceptCategory?.value ? 'daily-movements__payments__select__icon' : 'daily-movements__payments__select__icon__active'}>
                            <img
                              src={!item?.conceptCategory?.value ? Assets.SharedIcons.icon_gray_arrow : Assets.SharedIcons.icon_blue_arrow}
                              alt="icon_gray_arrow"
                            />
                          </div>
                        }
                      />
                    </div>
                    <div className='small-3'>
                      <Select
                        placeholder='Selección'
                        optionLabelProp='label'
                        popupMatchSelectWidth={false}
                        options={item?.bankCreditType?.value ? optionPaymentMethod(item?.bankCreditType?.value, item?.headQuarter?.value) : []}
                        disabled={item.disabled || !item.bankCreditType?.value}
                        className='daily-movements__payments__select'
                        popupClassName='daily-movements__payments__select__popup'
                        value={item?.paymentMethod?.value ? item?.paymentMethod : undefined}
                        onChange={(e, event) => handleChange(event, index, 'paymentMethod')}
                        suffixIcon={
                          <div className={item.disabled || !item.bankCreditType?.value ? 'daily-movements__payments__select__icon' : 'daily-movements__payments__select__icon__active'}>
                            <img
                              alt="icon_gray_arrow"
                              src={item.disabled || !item.bankCreditType?.value ? Assets.SharedIcons.icon_gray_arrow : Assets.SharedIcons.icon_blue_arrow}
                            />
                          </div>
                        }
                      />
                    </div>
                    <div className='grid-x small-3'>
                      <div className='grid-x small-9'>
                        <div className='grid-x small-12 daily-movements__payments__content-input-price'>
                            <NumericFormat
                              type='text'
                              prefix={"$ "}
                              placeholder='$ 0'
                              value={item?.price}
                              thousandSeparator=","
                              className='input-price'
                              disabled={item.disabled}
                              thousandsGroupStyle="thousand"
                              onChange={(e) => handleChange(e, index, 'price')}
                            />
                            <div className='daily-movements__payments__content-icon-price'>
                              <img
                                className='daily-movements__payments__icon'
                                alt={item.disabled ? "icon_add" : item.isCredit ? "icon_income" : "icon_egress"}
                                src={item.disabled ? Assets.SharedIcons.icon_add : item.isCredit ? Assets.SharedIcons.icon_income : Assets.SharedIcons.icon_egress}
                              />
                            </div>
                        </div>
                      </div>
                      <div className='grid-x small-3 justify-content-end'>
                        <button
                          disabled={item?.disabled}
                          className='daily-movements__payments__button-delete'
                          onClick={() => removeDataDailyMovements(item, index)}
                        >
                          <img
                            className='daily-movements__payments__button-delete__icon'
                            alt={item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                            src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='grid-x'>
            <div className='daily-movements__summary__line daily-movements__summary__line--mod'></div>
            <div className='grid-x small-6'>
              {(filterKeyCheck === "0" || filterKeyCheck === "1") &&
                <div className='grid-x align-middle small-6'>
                  <button
                    onClick={() => addPaymentsExpenses(1)}
                  >
                    <img
                      src={Assets.SharedIcons.icon_income}
                      alt='icon_income'
                    />
                    <span className='daily-movements__button-income'>Agregar Abono</span>
                  </button>
                </div>
              }
              {(filterKeyCheck === "0" || filterKeyCheck === "2") &&
                <div className='grid-x align-middle small-6'>
                  <button
                    onClick={() => addPaymentsExpenses(0)}
                  >
                    <img
                      src={Assets.SharedIcons.icon_egress}
                      alt='icon_egress'
                    />
                    <span className='daily-movements__button-income daily-movements__button-income--mod'>
                      Agregar Cobros
                    </span>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {openModal && 
        <CashClosingTreasuryComponent 
          open={openModal} 
          onClose={toggleModalClosing} 
          date={daySelected}
        />
      }
      {isViewModal && (
        <ModalDownloadExcelComponent
          isOpen={isViewModal}
          onClose={() => closeModal()}
          onGenerate={() => downloadExcel()}
          onDateChange={onDateChange}
          title={"Seleccione el rango de fechas"}
          month={false}
        />
      )}
    </div>
  );
};

export default DailyMovementsPage;