// Actions
import { setStateHeadquartersReducer } from '../../../../../storage/reducers/headquarters/headquarters.actions';

// Assets
import { Assets } from '../../../../../assets';

// Components - Shared
import ErrorAlertComponent from '../../../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import React, { useEffect, useState, useRef } from 'react'
import { Input, InputNumber } from 'antd';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

// Services
import { removeConceptInformation } from '../../../../../services/headquarters.services';

// Styles
import './they-owe-us-inventory.component.scss';
import SharedConst from '../../../../../core/constants/shared.const';

const TheyOweUsInventoryComponent = (props) => {

    const {
        // Actions
        setStateHeadquartersReducer,
        // Variables
        inventory,
        bankBalance,
        charges,
        changesGeneralSummary,
        weOweUsData,
        generalSummary,
        totalCashClosingExpenses,
        isReloadGeneralSummary
    } = props;

    const INITIAL_STATE = {
        isCollapsedInventory: false,
        isCollapsedAuthorized: false,
        isCollapsedOwesTo: false,
        scrollEnd: false,
        listOfWeOweUs: [],
        listInventory: [],
        scrollEndInventory:false
    };
    const [state, setState] = useState(INITIAL_STATE);

    const { 
        isCollapsedInventory, isCollapsedAuthorized, isCollapsedOwesTo, 
        listOfWeOweUs, scrollEnd, listInventory, scrollEndInventory
    } = state;
    
    const formRef = useRef(null);
    const formRefInventory = useRef(null);
    dayjs.extend(utc);


    useEffect(() => {
      if (formRefInventory.current && scrollEndInventory) {
        const miDiv = formRefInventory.current;
        miDiv.scrollTop = miDiv.scrollHeight;
      }
    }, [scrollEndInventory, listInventory?.length]);

    useEffect(() => {
      if (formRef.current && scrollEnd) {
        const miDiv = formRef.current;
        miDiv.scrollTop = miDiv.scrollHeight;
      }
    }, [scrollEnd, listOfWeOweUs?.length]);

    useEffect(() => {
        setState((prevState) => ({
          ...prevState,
          listOfWeOweUs: weOweUsData.data,
          listInventory: inventory?.totalByHeadquarters
        }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[generalSummary])

    const addWeOweUs = () => {
        const objectInputDisabled = listOfWeOweUs.find(object => object.hasOwnProperty("disabled") && object.disabled === true);
    
        if (!objectInputDisabled) {
          const newArray = [
            ...listOfWeOweUs,
            {
              description: '',
              value: '',
              date: generalSummary?.dateFormat,
              disabled: false
            }
          ];
          setState((prevState) => ({
            ...prevState,
            scrollEnd: true,
            listOfWeOweUs: newArray
          }))
        } else {
          const newArray = listOfWeOweUs.map(object => (object === objectInputDisabled ? { ...object, disabled: false } : object));
          setState((prevState) => ({
            ...prevState,
            listOfWeOweUs: newArray
          }))
        }
      };

      const addInventory = () => {
        const objectInputDisabled = listInventory.find(object => object.hasOwnProperty("disabled") && object.disabled === true);
    
        if (!objectInputDisabled) {
          const newArray = [
            ...listInventory,
            {
              description: '',
              value: '',
              date: generalSummary?.dateFormat,
              edit: true,
            }
          ];
          setState((prevState) => ({
            ...prevState,
            scrollEndInventory: true,
            listInventory: newArray
          }))
        } else {
          const newArray = listInventory.map(object => (object === objectInputDisabled ? { ...object, edit: true } : object));
          setState((prevState) => ({
            ...prevState,
            listInventory: newArray
          }))
        }
      };

    const onChanges = (data, target, object) => {
        let value = data && data.target ? data.target.value || '' : data;
        if (target === "description") value = value.trimStart()
        let changesReducer = changesGeneralSummary ? [...changesGeneralSummary] : [];
        if(target === "bankBalance" && (value || value === 0)){
            const bankBalanceKey = SharedConst.FINAL_BALANCE_HEADQUARTER.BANK_BALANCE;
            const existingObjectIndex = changesReducer.findIndex(x => x.description === bankBalanceKey);
            const bankBalance = { value, idConcept: generalSummary?.orderTypes[1], description: bankBalanceKey, id: object?.id || undefined, date: object?.date || undefined };
            (existingObjectIndex !== -1) ? changesReducer[existingObjectIndex] = bankBalance : changesReducer.push(bankBalance);
        }
        setStateHeadquartersReducer("changesGeneralSummary", changesReducer)
    };

    const handleChange = (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        let changesReducer = changesGeneralSummary ? [...changesGeneralSummary] : []
        let newChanges = {}
        const listOfWeOweUsCopy = JSON.parse(JSON.stringify(listOfWeOweUs));
        
        listOfWeOweUsCopy[index][name] = value;
    
        const existingObjectIndex = changesReducer.findIndex(x => ((x.index === index) && (x.idConcept === weOweUsData?.id)));
        if (existingObjectIndex !== -1) {
          changesReducer[existingObjectIndex] = {
            ...changesReducer[existingObjectIndex],
            [name]: value,
            idConcept: weOweUsData?.id,
          };
        } else {
          newChanges = {
            ...listOfWeOweUsCopy[index],
            idConcept: weOweUsData?.id,
            index: index,
            [name]: value
          };
          changesReducer.push(newChanges);
        }

        setStateHeadquartersReducer("changesGeneralSummary", changesReducer)

        setState({
        ...state,
        listOfWeOweUs: listOfWeOweUsCopy
        })
    }

    const handleChangeInventory = (e, index, name) => {
        let value = e && e.target ? e.target.value || '' : e;
        let changesReducer = changesGeneralSummary ? [...changesGeneralSummary] : []
        let newChanges = {}
        const listInventoryCopy = JSON.parse(JSON.stringify(listInventory));
        
        listInventoryCopy[index][name] = value;
    
        const existingObjectIndex = changesReducer.findIndex(x => ((x.index === index) && (x.idConcept === inventory?.id)));
        if (existingObjectIndex !== -1) {
          changesReducer[existingObjectIndex] = {
            ...changesReducer[existingObjectIndex],
            [name]: value,
            idConcept: inventory?.id,
          };
        } else {
          newChanges = {
            ...listInventoryCopy[index],
            idConcept: inventory?.id,
            index: index,
            [name]: value
          };
          changesReducer.push(newChanges);
        }

        setStateHeadquartersReducer("changesGeneralSummary", changesReducer)

        setState({
            ...state,
            listInventory: listInventoryCopy
        })
    }
    const removeItem = async(item, listName)=>{
        try {
            if (item.id) {
                await removeConceptInformation(item?.id, generalSummary?.dateFormat); 
                setStateHeadquartersReducer("isReloadGeneralSummary", !isReloadGeneralSummary)
            } else {
                let idTableDeleted 
                let list = []
                switch (listName) {
                    case "listInventory":
                        list = listInventory
                        idTableDeleted = inventory?.id
                        break;
                    case "listOfWeOweUs":
                        list = listOfWeOweUs
                        idTableDeleted = weOweUsData?.id
                        break;
                    default:
                        break;
                }
                const itemIndex = list.findIndex(listItem => 
                    listItem === item
                );
                if (itemIndex !== -1) {
                    const updatedList = list.filter((item, index) => index !== itemIndex);
                    setState((prevState) => ({
                        ...prevState,
                        [listName]: updatedList
                    }));
                    const itemEliminated = changesGeneralSummary.filter((item) => !(item.idConcept === idTableDeleted && item.index === itemIndex));
                    const changesList = itemEliminated.map((item) => {
                        if ((item.index >= itemIndex) && (item.idConcept === idTableDeleted)) {
                            return {
                                ...item,
                                index: item.index -1
                            }
                        }
                        return item
                    }) 
                    setStateHeadquartersReducer("changesGeneralSummary", changesList)
                }
            }
        } catch (error) {
            ErrorAlertComponent()
        }
    }

    return (
        <div className='they-owe-us-inventory__container'>
            {/* TODO: is hidden by customer request as this initial value should be in the creation of the site.  */}
            {/* <div className='grid-x align-middle justify-content-between they-owe-us-inventory__initial-investment__content'>
                <span className='they-owe-us-inventory__initial-investment__title'>Inversión inicial</span>
                <NumericFormat
                    value={inventory?.initialBalance ? inventory?.initialBalance : 0}
                    type='text'
                    prefix={"$ "}
                    placeholder='$ 0'
                    thousandSeparator=","
                    className='input-price'
                    thousandsGroupStyle="thousand"
                    disabled
                />
            </div> */}
            <div className='they-owe-us-inventory__table'>
                <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__title__content'>
                    <span className='they-owe-us-inventory__table__title'>Inventario en sede</span>
                    <button
                        onClick={() => setState({ ...state, isCollapsedInventory: !isCollapsedInventory })}
                    >
                        <img
                            alt='icon_blue_arrow_up'
                            src={Assets.SharedIcons.icon_blue_arrow_up}
                            className={`they-owe-us-inventory__table__icon ${isCollapsedInventory ? 'rotate' : ''}`}
                        />
                    </button>
                </div>
                <div className='they-owe-us-inventory__table__box'>
                    <div className={`they-owe-us-inventory__table__content-info ${isCollapsedInventory ? 'collapsed' : ''}`}>
                        <div className='grid-x align-middle they-owe-us-inventory__table__subtitle__content'>
                            <span className='small-3 they-owe-us-inventory__table__subtitle'>Fecha</span>
                            <span className='small-4 they-owe-us-inventory__table__subtitle'>Concepto</span>
                            <span className='small-4 they-owe-us-inventory__table__subtitle'>Valor</span>
                        </div>
                        <div ref={formRefInventory} className='they-owe-us-inventory__table__content-info'>
                            {listInventory?.map((inventoryHeadquarter, index) => {
                                const handleKeyPress = (event) => {
                                    if (event?.data === '-' && event?.target?.selectionStart === 2) {
                                        if (inventoryHeadquarter?.value?.toString()?.includes('-')) {
                                            event.preventDefault();
                                        }
                                        return
                                    }

                                    if (!/^[0-9.]$/.test(event?.data)) {
                                        event.preventDefault();
                                    }

                                    if (event?.data === '.' && (!inventoryHeadquarter.value || inventoryHeadquarter?.value?.toString()?.includes('.'))) {
                                        event.preventDefault();
                                    }
                                };

                                const isEdit = inventoryHeadquarter?.edit;
                                return (
                                    <div key={index} className='grid-x align-middle justify-content-between they-owe-us-inventory__table__add__content'>
                                        <Input
                                            disabled
                                            prefix={<></>}
                                            className='small-3 they-owe-us-inventory__table__add__input'
                                            value={isEdit ? dayjs.utc(inventoryHeadquarter?.date).format("YYYY-MM-DD") : generalSummary.dateFormat}
                                        />
                                        <Input
                                            onChange={(e) => handleChangeInventory(e, index, 'description')}
                                            disabled={!isEdit}
                                            prefix={isEdit ? <img src={Assets.SharedIcons.icon_edit} alt='icon_edit' /> : <></>}
                                            value={inventoryHeadquarter?.description}
                                            className={`small-4 they-owe-us-inventory__table__add__input ${isEdit ? 'they-owe-us-inventory__table__add__input--mod' : ''}`}
                                        />
                                        <div className={`grid-x small-4 ${isEdit ? "they-owe-us-inventory__concept-input-price" : "they-owe-us-inventory__table__add__inputs-price__disabled"}`}>
                                            <InputNumber
                                                prefix={isEdit ? <img src={Assets.SharedIcons.icon_edit} alt='icon_edit' /> : <></>}
                                                disabled={!isEdit}
                                                value={inventoryHeadquarter?.value}
                                                controls={false}
                                                formatter={(value) => {
                                                    if (value === undefined || value === null) return '';

                                                    const cleanedValue = value.replace(/\$\s?|(,*)/g, '');

                                                    const parts = cleanedValue.split('.');

                                                    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                                                    let decimalPart = parts[1] ? parts[1].slice(0, 2) : '';

                                                    const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

                                                    return `$ ${formattedValue}`;
                                                }}
                                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                                onChange={(floatValue) => handleChangeInventory(floatValue, index, 'value')}
                                                onBeforeInput={(event) => handleKeyPress(event)}
                                                className='they-owe-us-inventory__input_number'
                                            />
                                        </div>
                                        <div className='grid-x'>
                                            <button
                                                disabled={!isEdit}
                                                className='they-owe-us-inventory__button-delete'
                                                onClick={() => removeItem(inventoryHeadquarter, "listInventory")}
                                            >
                                                {isEdit &&

                                                    <img
                                                        className='they-owe-us-inventory__button-delete__icon'
                                                        alt='icon_deleted_active'
                                                        src={Assets.SharedIcons.icon_deleted_active}
                                                    />
                                                }
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='grid-x align-middle justify-content-between they-owe-us-inventory__tables__total__content--mod'>
                        <div className='grid-x align-middle justify-content-between they-owe-us-inventory__tables__total__text__content'>
                            <button
                                className='grid-x align-middle'
                                onClick={() => addInventory()}
                            >
                                <img
                                    alt='icon_income_blue'
                                    src={Assets.SharedIcons.icon_income_blue}
                                    className='they-owe-us-inventory__tables__total__icon'
                                />
                                <span className='they-owe-us-inventory__tables__total__text--mod'>Agregar concepto</span>
                            </button>
                            <span className='they-owe-us-inventory__tables__total__text'>Total</span>
                        </div>
                        <div className='they-owe-us-inventory__tables__total__content-value'>
                            <NumericFormat
                                disabled
                                type='text'
                                prefix={"$ "}
                                placeholder='$ 0'
                                thousandSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className='input-price'
                                thousandsGroupStyle="thousand"
                                value={inventory?.total ? inventory?.total : 0}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid-x align-middle justify-content-between they-owe-us-inventory__real-cash-content'>
                <div className='they-owe-us-inventory__real-cash-content__title__content'>
                    <span className='they-owe-us-inventory__real-cash-content__title'>Total de envase</span>
                </div>
                <div className='they-owe-us-inventory__table__total__content-value they-owe-us-inventory__table__total__content-value--mod'>
                    <NumericFormat
                        type='text'
                        disabled
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={generalSummary?.totalContainer ? generalSummary?.totalContainer : 0}
                    />
                </div>
            </div>

            <div className='they-owe-us-inventory__table they-owe-us-inventory__table--mod'>
                <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__title__content'>
                    <span className='they-owe-us-inventory__table__title'>Nos deben </span>
                    <button
                        onClick={() => setState({ ...state, isCollapsedOwesTo: !isCollapsedOwesTo })}
                    >
                        <img
                            alt='icon_blue_arrow_up'
                            src={Assets.SharedIcons.icon_blue_arrow_up}
                            className={`they-owe-us-inventory__table__icon ${isCollapsedOwesTo ? 'rotate' : ''}`}
                        />
                    </button>
                </div>
                <div className='they-owe-us-inventory__table__box'>
                    <div className={`they-owe-us-inventory__table__content-info ${isCollapsedOwesTo ? 'collapsed' : ''}`}>
                        <div className='grid-x align-middle  they-owe-us-inventory__table__subtitle__content'>
                            <span className='small-3 they-owe-us-inventory__table__subtitle'>Fecha</span>
                            <span className='small-4 they-owe-us-inventory__table__subtitle'>Concepto</span>
                            <span className='small-4 they-owe-us-inventory__table__subtitle'>Valor</span>
                        </div>
                        <div ref={null} className='they-owe-us-inventory__tables__content-info-scroll--mod'>
                            {listOfWeOweUs?.map((weOweUs, index) => {

                                const handleKeyPress = (event) => {
                                    if (event?.data === '-' && event?.target?.selectionStart === 2) {
                                        if (weOweUs?.value?.toString()?.includes('-')) {
                                            event.preventDefault();
                                        }
                                        return
                                    }

                                    if (!/^[0-9.]$/.test(event?.data)) {
                                        event.preventDefault();
                                    }

                                    if (event?.data === '.' && (!weOweUs.value || weOweUs?.value?.toString()?.includes('.'))) {
                                        event.preventDefault();
                                    }
                                };

                                return (
                                    <div key={index} className='grid-x align-middle justify-content-between they-owe-us-inventory__tables__content-label'>
                                        <Input
                                            disabled
                                            prefix={<></>}
                                            value={weOweUs?.edit ? dayjs.utc(weOweUs?.date).format("YYYY-MM-DD") : generalSummary.dateFormat}
                                            className='small-3 they-owe-us-inventory__table__add__input'
                                        />
                                        <Input
                                            value={weOweUs?.description}
                                            disabled={weOweUs.disabled}
                                            className='small-4 they-owe-us-inventory__tables__input__concept'
                                            onChange={(e) => handleChange(e, index, "description")}
                                            prefix={
                                                <img
                                                    alt={weOweUs.disabled ? 'icon_add' : 'icon_edit'}
                                                    src={weOweUs.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                />
                                            }
                                        />
                                        <div className='grid-x small-4 they-owe-us-inventory__tables__input__concept-input-price'>
                                            <InputNumber
                                                prefix={<img src={Assets.SharedIcons.icon_edit} alt='icon_edit' />}
                                                disabled={weOweUs?.disabled}
                                                value={weOweUs?.value}
                                                controls={false}
                                                formatter={(value) => {
                                                    if (value === undefined || value === null) return '';

                                                    const cleanedValue = value.replace(/\$\s?|(,*)/g, '');

                                                    const parts = cleanedValue.split('.');

                                                    let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                                                    let decimalPart = parts[1] ? parts[1].slice(0, 2) : '';

                                                    const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

                                                    return `$ ${formattedValue}`;
                                                }}
                                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                                                onChange={(floatValue) => handleChange(floatValue, index, 'value')}
                                                onBeforeInput={(event) => handleKeyPress(event)}
                                                className='they-owe-us-inventory__input_number'
                                            />
                                        </div>
                                        <div className='grid-x'>
                                            <button
                                                disabled={weOweUs?.disabled}
                                                className='they-owe-us-inventory__button-delete'
                                                onClick={() => removeItem(weOweUs, "listOfWeOweUs")}
                                            >
                                                {!weOweUs?.disabled &&

                                                    <img
                                                        className='they-owe-us-inventory__button-delete__icon'
                                                        alt='icon_deleted_active'
                                                        src={Assets.SharedIcons.icon_deleted_active}
                                                    />
                                                }
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    <div className='grid-x align-middle justify-content-between they-owe-us-inventory__tables__total__content--mod'>
                        <div className='grid-x align-middle justify-content-between they-owe-us-inventory__tables__total__text__content'>
                            <button
                                className='grid-x align-middle'
                                onClick={() => addWeOweUs()}
                            >
                                <img
                                    alt='icon_income_blue'
                                    src={Assets.SharedIcons.icon_income_blue}
                                    className='they-owe-us-inventory__tables__total__icon'
                                />
                                <span className='they-owe-us-inventory__tables__total__text--mod'>Agregar concepto</span>
                            </button>
                            <span className='they-owe-us-inventory__tables__total__text'>Total</span>
                        </div>
                        <div className='they-owe-us-inventory__tables__total__content-value'>
                            <NumericFormat
                                disabled
                                type='text'
                                prefix={"$ "}
                                placeholder='$ 0'
                                thousandSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className='input-price'
                                thousandsGroupStyle="thousand"
                                value={weOweUsData?.total || 0}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='grid-x align-middle justify-content-between they-owe-us-inventory__real-cash-content'>
                <div className='they-owe-us-inventory__real-cash-content__title__content'>
                    <span className='they-owe-us-inventory__real-cash-content__title'>Escribir efectivo real</span>
                </div>
                <div className='they-owe-us-inventory__table__total__content-value they-owe-us-inventory__table__total__content-value--mod'>
                    <NumericFormat
                        type='text'
                        disabled
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={generalSummary?.realCashFinalBalance || 0}
                    />
                </div>
            </div>
            <div className='grid-x align-middle justify-content-between they-owe-us-inventory__real-cash-content'>
                <div className='they-owe-us-inventory__real-cash-content__title__content'>
                    <span className='they-owe-us-inventory__real-cash-content__title'>Gastos cierre de caja</span>
                </div>
                <div className='they-owe-us-inventory__table__total__content-value they-owe-us-inventory__table__total__content-value--mod'>
                    <NumericFormat
                        type='text'
                        disabled
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={totalCashClosingExpenses}
                    />
                </div>
            </div>
            <div className='grid-x align-middle justify-content-between they-owe-us-inventory__real-cash-content'>
                <div className='they-owe-us-inventory__real-cash-content__title__content'>
                    <span className='they-owe-us-inventory__real-cash-content__title'>{SharedConst.FINAL_BALANCE_HEADQUARTER.BANK_BALANCE}</span>
                </div>
                <div className='they-owe-us-inventory__real-cash-content__input'>
                    <NumericFormat
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={bankBalance ? bankBalance?.value : 0}
                        onValueChange={({ floatValue }) => onChanges(floatValue, "bankBalance", bankBalance)}
                    />
                </div>
            </div>


            <div className='they-owe-us-inventory__table'>
                <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__title__content'>
                    <span className='they-owe-us-inventory__table__title'>Autorizados para fiados</span>
                    <button
                        onClick={() => setState({ ...state, isCollapsedAuthorized: !isCollapsedAuthorized })}
                    >
                        <img
                            alt='icon_blue_arrow_up'
                            src={Assets.SharedIcons.icon_blue_arrow_up}
                            className={`they-owe-us-inventory__table__icon ${isCollapsedAuthorized ? 'rotate' : ''}`}
                        />
                    </button>
                </div>
                <div className='they-owe-us-inventory__table__box'>
                    <div className={`they-owe-us-inventory__table__add__parentContainer ${isCollapsedAuthorized ? 'collapsed' : ''}`}>
                        <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__subtitle__content'>
                            <span className='they-owe-us-inventory__table__subtitle'>Nombre</span>
                            <span className='they-owe-us-inventory__table__subtitle'>Valor</span>
                        </div>
                        <div className='they-owe-us-inventory__table__add__parentContainer'>
                            {charges?.totalByCharge
                                ?.filter(item => item.total !== 0)
                                .map((item, index) => (
                                    <div key={index} className='grid-x align-middle justify-content-between they-owe-us-inventory__table__add__content'>
                                        <Input
                                            disabled
                                            prefix={<></>}
                                            value={item?.charge || ""}
                                            className='they-owe-us-inventory__table__add__input'
                                        />
                                        <div className='they-owe-us-inventory__table__add__inputs-price__disabled'>
                                            <NumericFormat
                                                disabled
                                                type='text'
                                                value={item?.total || 0}
                                                prefix={"$ "}
                                                placeholder='$ 0'
                                                thousandSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                className='input-price'
                                                thousandsGroupStyle="thousand"
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className='grid-x align-middle justify-content-between they-owe-us-inventory__table__total__content'>
                        <div className='grid-x align-middle justify-content-end they-owe-us-inventory__table__total__text__content'>
                            <span className='they-owe-us-inventory__table__total__text'>Total</span>
                        </div>
                        <div className='they-owe-us-inventory__table__total__content-value'>
                            <NumericFormat
                                disabled
                                type='text'
                                prefix={"$ "}
                                placeholder='$ 0'
                                thousandSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className='input-price'
                                thousandsGroupStyle="thousand"
                                value={charges?.total || 0}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = ({ HeadquartersReducer }) => {
    const { generalSummary, changesGeneralSummary, totalCashClosingExpenses, isReloadGeneralSummary } = HeadquartersReducer;
    
    const orderTypes = generalSummary?.orderTypes;
    const dataTables = generalSummary?.dataTables;
    let inventory = generalSummary?.inventory;
    const charges = generalSummary?.charges;

    let bankBalance = { value: 0 };

    let weOweUsData = {
        total: 0,
        data: []
      }

    if (orderTypes && dataTables &&  orderTypes?.length) {
        const idFilter = orderTypes[1]
        const idFilterWeOweUs = orderTypes[5]
        const dataFilter = dataTables[idFilter]?.data
        weOweUsData.id = idFilterWeOweUs
        const weOweUs = dataTables[idFilterWeOweUs]
        if(weOweUs){
            weOweUsData = {
              ...weOweUsData,
              ...weOweUs
            }
          }
        if(dataFilter?.length){
            bankBalance = {
                ...dataFilter[0]
            }
        }
    }

    return {
        inventory,
        bankBalance,
        charges,
        changesGeneralSummary,
        weOweUsData,
        generalSummary,
        totalCashClosingExpenses,
        isReloadGeneralSummary
    };
};

const mapStateToPropsActions = {
    setStateHeadquartersReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(TheyOweUsInventoryComponent);