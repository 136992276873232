const AdministrationPanelEndPoints = {
    GET_ALL_USERS: `/api/administrationPanel/`,
    CREATE_USER: `/api/administrationPanel/`,
    UPDATE_USER: `/api/administrationPanel/user/`,
    GET_USER_BY_ID: `/api/administrationPanel/user/:idUser`,
    GET_USER_PERMISSION_BY_ID: `/api/administrationPanel/permission/:idUser`,
    CREATE_USER_PERMISSION: `/api/administrationPanel/permissions`,
    UPDATE_USER_PERMISSION: `/api/administrationPanel/permissions`,
    GET_ALL_HEADQUARTER: `/api/administrationPanel/headquarter`,
    GET_HEADQUARTER_BY_ID: `/api/administrationPanel/headquarter/:idHeadquarter`,
    CREATE_HEADQUARTER: `/api/administrationPanel/headquarter/`,
    UPDATE_HEADQUARTER: `/api/administrationPanel/headquarter/`,
    UPDATE_USER_HEADQUARTER: `/api/administrationPanel/userChargeHeadquarter/`,
    UPDATE_STORE_HEADQUARTER: `/api/administrationPanel/storeHeadquarter/`,
    GET_ALL_HISTORY_CONSTANTS: `/api/administrationPanel/historicalConstant/`,
    CREATE_CONSTANT: `/api/administrationPanel/:url`,
    CREATE_CATEGORY: `/api/administrationPanel/expenseCategory/`,
    UPDATE_CATEGORY: `/api/administrationPanel/expenseCategory/`,
    CREATE_SUBCATEGORY: `/api/administrationPanel/expenseSubCategory/`,
    UPDATE_SUBCATEGORY: `/api/administrationPanel/expenseSubCategory/`,
    GET_CATEGORIES_HEADQUARTER: `/api/administrationPanel/headquarterCategory/`,
    CREATE_CATEGORY_HEADQUARTER: `/api/administrationPanel/headquarterCategory/`,
    UPDATE_CATEGORY_HEADQUARTER: `/api/administrationPanel/headquarterCategory/`,
    GET_HEADQUARTER_BY_ID_CATEGORY: `/api/administrationPanel/headquarterByCategory/:idHeadQuarterCategory`,
    GET_LIST_MANAGER_HEADQUARTER: `/api/administrationPanel/userList`,
}

export default AdministrationPanelEndPoints;