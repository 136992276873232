// Assets
import { Assets } from "../../../../../assets";

// Components - Shared
import ErrorAlertComponent from "../../../../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../../../../shared/components/alerts/success-alert/success-alert.component";

// Libraries
import React, { useEffect, useState } from "react";
import { Collapse, DatePicker, Input } from "antd";
import dayjs from 'dayjs';

// Services
import { 
    createHistoricalService, deleteHistoricalService, getAllConstantsService, 
    updateHistoricalService 
} from "../../../../../services/administration-panel.services";

// Styles
import "./constants-panel.component.scss";

export const ConstantsPanelComponent = () => {

    const INITIAL_STATE = {
        dataMapper: {},
        activated: null,
        inputList: null,
        editList: {},
        dateList: null,
        activeKeys: null
    }

    const [state, setState] = useState(INITIAL_STATE);
    const {
        dataMapper, activated, inputList,
        editList, dateList, activeKeys
    } = state

    useEffect(() => {
        getAllConstants();
    }, [])

    const getAllConstants = async () => {
        try {
            const resGetAll = await getAllConstantsService();
            setState((prevState) => ({
                ...prevState,
                dataMapper: resGetAll
            }))
        } catch (error) {
            ErrorAlertComponent()
        }
    }

    const handleActivated = (key, list) => {
        setState((prevState) => ({
            ...prevState,
            activated: {
                ...prevState.activated,
                [`${key}`]: list
            }
        }))
    }

    const handleAddList = async (info, name, key, data, date, id) => {
        try {
            await createHistoricalService(data ? info?.url : info?.urlParent || info?.url, name, dayjs(date).format('YYYY-MM-DD'), id);
            SuccessAlertComponent(undefined, "Constante creada exitosamente")
            setState((prevState) => ({
                ...prevState,
                inputList: {
                    ...prevState.inputList,
                    [`${key}-${activated[key]}${data ? `:${data.name}` : ""}`]: ""
                },
                dateList: {
                    ...prevState.dateList,
                    [`${key}-${activated[key]}${data ? `:${data.name}` : ""}`]: dayjs()
                }
            }))
            await getAllConstants();
        } catch (error) {
            let listError ={
                HTCN002: "El tipo de valor ingresado de la constante no es valido",
                ADPN002: "La constante ya ha sido asignada a la fecha seleccionada",
                ADPN004: "La constante ya ha sido asignada",
                ADPN005: "La constante ya ha sido asignada",
                ADPN006: "La constante ya ha sido asignada",
                ADPN007: "La constante ya ha sido asignada",
                ADPN009: "La constante ya ha sido asignada",
                ADPN010: "La constante ya ha sido asignada",
                ADPN011: "La constante ya ha sido asignada",
            }
            ErrorAlertComponent(undefined, listError[error?.code] || undefined);
        }
    }

    const handleEditList = async (url, name, id, key, nameDetails) => {
        try {
            await updateHistoricalService(url, name, id);
            setState((prevState) => ({
                ...prevState,
                editList: {
                    ...prevState.editList,
                    [`${key}-${[activated[key]]}:${nameDetails}`]: {
                        value: nameDetails,
                        isEdit: false
                    }
                }
            }))
            SuccessAlertComponent(undefined, "Constante actualizada exitosamente")
            await getAllConstants();
        } catch (error) {
            ErrorAlertComponent()
        }
    }

    const handleDeleteList = async (url, id) => {
        try {
            await deleteHistoricalService(url, id);
            SuccessAlertComponent(undefined, "Constante eliminada exitosamente")
            await getAllConstants();
        } catch (error) {
            ErrorAlertComponent()
        }
    }

    return (
        <div>
            {Object.keys(dataMapper).map((key, index) => {
                return (
                    <div key={index}>
                        <label className="constants-panel__title">
                            {key}
                        </label>
                        <div className="grid-x justify-content-between">
                            <div className="constants-panel__content-box">
                                {Object.keys(dataMapper[key])?.map((list, indexList) => {
                                    return (
                                        <button
                                            key={indexList}
                                            onClick={() => handleActivated(key, list)}
                                            className={`grid-x justify-content-between constants-panel__info-row ${activated?.hasOwnProperty(key) && activated[key] === list ? 'constants-panel__info-row--selected' : ''}`}
                                        >
                                            <div className='constants-panel__info-row__content__description-site'>
                                                <div className='constants-panel__info-row__content__description-site__sub-content'>
                                                    <span className='text-left constants-panel__info-row__text-site'>
                                                        {list}
                                                    </span>
                                                </div>
                                            </div>
                                            <img
                                                alt={activated?.hasOwnProperty(key) && activated[key] === list ? "icon_arrow" : "icon_blue_arrow"}
                                                src={activated?.hasOwnProperty(key) && activated[key] === list ? Assets.SharedIcons.icon_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                className={`constants-panel__info-row__content__description-total-inventory__icon ${activated?.hasOwnProperty(key) && activated[key] === list ? "" : "constants-panel__info-row__content__description-total-inventory__icon--rotate"}`}
                                            />
                                        </button>
                                    )
                                })}
                            </div>
                            <div className="constants-panel__content-box constants-panel__content-box--details">
                                <div className="constants-panel__collapse">
                                    <Collapse
                                        activeKey={activeKeys && activeKeys[`${key}-${activated[key]}`] ? activeKeys[`${key}-${activated[key]}`] : null}
                                        onChange={(keyOnChange) => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                activeKeys: {
                                                    ...prevState.activeKeys,
                                                    [`${key}-${activated[key]}`]: keyOnChange
                                                }
                                            }))
                                        }}
                                        accordion
                                        className="constants-panel__collapse__content"
                                        expandIcon={({ isActive }) => {
                                            const viewIcon = activated && dataMapper[key][activated[key]]?.data &&
                                            dataMapper[key][activated[key]]?.data?.length > 0 &&
                                            dataMapper[key][activated[key]]?.data[0]?.hasOwnProperty("historicalConstant") ? true : false
                                            return (
                                                <>
                                                    { viewIcon ?
                                                        <img
                                                            alt={isActive ? "icon_arrow" : "icon_blue_arrow"}
                                                            src={isActive ? Assets.SharedIcons.icon_arrow : Assets.SharedIcons.icon_blue_arrow}
                                                            className={isActive ? "constants-panel__info-row__content__description-total-inventory__icon constants-panel__info-row__content__description-total-inventory__icon--rotate" : "constants-panel__info-row__content__description-total-inventory__icon"}
                                                        />
                                                        :
                                                        <></>
                                                    }
                                                </>
                                            )
                                        }}
                                        expandIconPosition="end"
                                        collapsible={
                                            (
                                                activated && dataMapper[key][activated[key]]?.data &&
                                                dataMapper[key][activated[key]]?.data?.length > 0 &&
                                                dataMapper[key][activated[key]]?.data[0]?.hasOwnProperty("historicalConstant")
                                            )
                                                ? false
                                                : "disabled"
                                        }
                                        items={
                                            activated && Object.keys(activated)?.length > 0 ?
                                                dataMapper[key][activated[key]]?.data?.map((dataDetails, indexDetails) => {
                                                    return ({
                                                        key: indexDetails,
                                                        label: (
                                                            <div className="grid-x">
                                                                {editList && editList[`${key}-${activated[key]}:${dataDetails?.name}`]?.isEdit ?
                                                                        <Input
                                                                            value={editList && editList[`${key}-${activated[key]}:${dataDetails?.name}`] ? editList[`${key}-${activated[key]}:${dataDetails?.name}`]?.value : dataDetails?.name}
                                                                            className="constants-panel__content-input__input"
                                                                            onClick={(e) => e.stopPropagation()}
                                                                            onChange={(data) => {
                                                                                setState((prevState) => ({
                                                                                    ...prevState,
                                                                                    editList: {
                                                                                        ...prevState.editList,
                                                                                        [`${key}-${[activated[key]]}:${dataDetails?.name}`]: {
                                                                                            ...prevState.editList[`${key}-${[activated[key]]}:${dataDetails?.name}`],
                                                                                            value: data.target.value.trimStart(),
                                                                                        }
                                                                                    }
                                                                                }))
                                                                            }}
                                                                        />
                                                                    :
                                                                    <div className="grid-x align-center-middle">
                                                                        <span style={{minWidth: "150px", marginRight: "10px"}}>{dataDetails?.name}</span>
                                                                        <span>
                                                                            {dataDetails?.date ? dayjs(dataDetails?.date).format("YYYY/MM/DD") : null}
                                                                        </span>
                                                                    </div>
                                                                }
                                                                {dataMapper[key][activated[key]]?.url !== "/historicalConstant" || (dataMapper[key][activated[key]]?.url === "/historicalConstant" && dataDetails.hasOwnProperty("historicalConstant")) ?
                                                                    <>
                                                                        <button
                                                                            className="constants-panel__collapse__edit"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                if (editList && editList[`${key}-${[activated[key]]}:${dataDetails?.name}`]?.isEdit) {
                                                                                    handleEditList(
                                                                                        dataMapper[key][activated[key]]?.urlParent || dataMapper[key][activated[key]]?.url,
                                                                                        editList[`${key}-${[activated[key]]}:${dataDetails?.name}`]?.value,
                                                                                        dataDetails?.id,
                                                                                        key,
                                                                                        dataDetails.name
                                                                                    )
                                                                                } else {
                                                                                    setState((prevState) => ({
                                                                                        ...prevState,
                                                                                        editList: {
                                                                                            ...prevState.editList,
                                                                                            [`${key}-${[activated[key]]}:${dataDetails?.name}`]: {
                                                                                                value: prevState.editList[`${key}-${[activated[key]]}:${dataDetails?.name}`] ? prevState.editList[`${key}-${[activated[key]]}:${dataDetails?.name}`].value : dataDetails?.name,
                                                                                                isEdit: prevState.editList[`${key}-${[activated[key]]}:${dataDetails?.name}`] ? !prevState.editList[`${key}-${[activated[key]]}:${dataDetails?.name}`].isEdit : true
                                                                                            }
                                                                                        }
                                                                                    }))
                                                                                }
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={Assets.SharedIcons.icon_edit}
                                                                                alt="icon_edit"
                                                                            />
                                                                        </button>
                                                                        {editList && editList[`${key}-${activated[key]}:${dataDetails?.name}`]?.isEdit &&
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation()
                                                                                    setState((prevState) => ({
                                                                                        ...prevState,
                                                                                        editList: {
                                                                                            ...prevState.editList,
                                                                                            [`${key}-${[activated[key]]}:${dataDetails?.name}`]: {
                                                                                                value: dataDetails?.name,
                                                                                                isEdit: false
                                                                                            }
                                                                                        }
                                                                                    }))
                                                                                }}
                                                                                className="constants-panel__collapse__delete"
                                                                            >
                                                                                <img
                                                                                    src={Assets.SharedIcons.icon_close}
                                                                                    alt="icon_delete"
                                                                                />
                                                                            </button>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <button
                                                                        style={{ marginLeft: "10px" }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            handleDeleteList(dataMapper[key][activated[key]]?.url, dataDetails?.id)
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={Assets.SharedIcons.icon_trash_bin}
                                                                            alt="icon_trash_bin"
                                                                        />
                                                                    </button>
                                                                }
                                                            </div>
                                                        ),
                                                        children: (
                                                            <>
                                                                {dataDetails?.historicalConstant?.map((data, dataIndex) => (
                                                                    <div key={dataIndex}>
                                                                        <div className="grid-x">
                                                                            <span className="constants-panel__content-input__delete">
                                                                                {data?.name}
                                                                            </span>
                                                                            <span className="constants-panel__content-input__delete">
                                                                                {data?.date &&dayjs(data?.date).format("YYYY/MM/DD")}
                                                                            </span>
                                                                            { dataMapper[key][activated[key]]?.url === "/historicalConstant" &&
                                                                                <button
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        handleDeleteList(dataMapper[key][activated[key]]?.url, data?.id)
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={Assets.SharedIcons.icon_trash_bin}
                                                                                        alt="icon_trash_bin"
                                                                                    />
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="grid-x justify-content-between constants-panel__content-input">
                                                                    <Input
                                                                        value={inputList && inputList[`${key}-${activated[key]}:${dataDetails?.name}`] ? inputList[`${key}-${activated[key]}:${dataDetails?.name}`] : ""}
                                                                        className={`${dataMapper[key][activated[key]]?.url === "/historicalConstant" ? "small-5" : "small-9"} constants-panel__content-input__input`}
                                                                        onChange={(data) => {
                                                                            setState((prevState) => ({
                                                                                ...prevState,
                                                                                inputList: {
                                                                                    ...prevState.inputList,
                                                                                    [`${key}-${[activated[key]]}:${dataDetails?.name}`]: data.target.value.trimStart()
                                                                                }
                                                                            }))
                                                                        }}
                                                                    />
                                                                    {dataMapper[key][activated[key]]?.url === "/historicalConstant" &&
                                                                        <div className='constants-panel__content-picker'>
                                                                            <div className='constants-panel__content-picker__content-icon'>
                                                                                <img
                                                                                    alt="icon_calendar"
                                                                                    src={Assets.SharedIcons.icon_calendar}
                                                                                />
                                                                            </div>
                                                                            <DatePicker
                                                                                allowClear={{
                                                                                    clearIcon: null
                                                                                }}
                                                                                suffixIcon={null}
                                                                                format="YYYY/MM/DD"
                                                                                placeholder="AAAA/MM/DD"
                                                                                onChange={(e, date) =>
                                                                                    setState((prevState) => ({
                                                                                        ...prevState,
                                                                                        dateList: {
                                                                                            ...prevState.dateList,
                                                                                            [`${[activated[key]]}:${dataDetails?.name}`]: date
                                                                                        }
                                                                                    }))
                                                                                }
                                                                                className='constants-panel__content-picker__picker'
                                                                                value={dateList && dateList[`${activated[key]}:${dataDetails?.name}`] ? dayjs(dateList[`${activated[key]}:${dataDetails?.name}`]) : dayjs()}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    <div className="grid-x align-center-middle">
                                                                        <button
                                                                            disabled={inputList && inputList[`${key}-${activated[key]}:${dataDetails?.name}`] ? false : true}
                                                                            className="constants-panel__add"
                                                                            onClick={() =>
                                                                                handleAddList(
                                                                                    dataMapper[key][activated[key]],
                                                                                    inputList[`${key}-${activated[key]}:${dataDetails?.name}`],
                                                                                    key,
                                                                                    dataDetails,
                                                                                    dateList && dateList[`${key}-${activated[key]}:${dataDetails?.name}`] ? dateList[`${key}-${activated[key]}:${dataDetails?.name}`] : dayjs(),
                                                                                    dataDetails?.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <span>Agregar</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                })
                                                : []
                                        }
                                    />
                                </div>
                                {activated && dataMapper[key][activated[key]] &&
                                    <div className="grid-x justify-content-between constants-panel__content-input">
                                        <Input
                                            value={inputList && inputList[[`${key}-${[activated[key]]}`]] ? inputList[[`${key}-${[activated[key]]}`]] : ""}
                                            className={`${(dataMapper[key][activated[key]]?.url === "/historicalConstant" && !dataMapper[key][activated[key]].hasOwnProperty("urlParent")) ? "small-5" : "small-9"} constants-panel__content-input__input`}
                                            onChange={(data) => {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    inputList: {
                                                        ...prevState.inputList,
                                                        [`${key}-${[activated[key]]}`]: data.target.value.trimStart()
                                                    }
                                                }))
                                            }}
                                        />
                                        {(dataMapper[key][activated[key]]?.url === "/historicalConstant" && !dataMapper[key][activated[key]].hasOwnProperty("urlParent")) &&
                                            <div className='constants-panel__content-picker'>
                                                <div className='constants-panel__content-picker__content-icon'>
                                                    <img
                                                        alt="icon_calendar"
                                                        src={Assets.SharedIcons.icon_calendar}
                                                    />
                                                </div>
                                                <DatePicker
                                                    allowClear={{
                                                        clearIcon: null
                                                    }}
                                                    suffixIcon={null}
                                                    format="YYYY/MM/DD"
                                                    placeholder="AAAA/MM/DD"
                                                    onChange={(e, date) =>
                                                        setState((prevState) => ({
                                                            ...prevState,
                                                            dateList: {
                                                                ...prevState.dateList,
                                                                [`${[activated[key]]}`]: date
                                                            }
                                                        }))
                                                    }
                                                    className='constants-panel__content-picker__picker'
                                                    value={dateList && dateList[activated[key]] ? dayjs(dateList[activated[key]]) : dayjs()}
                                                />
                                            </div>
                                        }
                                        <div className="grid-x align-center-middle">
                                            <button
                                                disabled={inputList && inputList[[`${key}-${[activated[key]]}`]] ? false : true}
                                                className="constants-panel__add"
                                                onClick={() =>
                                                    handleAddList(
                                                        dataMapper[key][activated[key]],
                                                        inputList[[`${key}-${[activated[key]]}`]],
                                                        key,
                                                        null,
                                                        dateList ? dateList[activated[key]] : dayjs(),
                                                        dataMapper[key][activated[key]]?.id
                                                    )
                                                }
                                            >
                                                <span>Agregar</span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};
