//Actions
import { setStateCashClosingReducer } from '../../../../storage/reducers/cash-closing/cash-closing.actions';

//Assets
import { Assets } from '../../../../assets';

//Libraries
import { Input } from 'antd';
import { connect } from 'react-redux';
import { NumericFormat } from "react-number-format";
import React, { useEffect, useRef, useState } from 'react';

//Services
import { deletedRecordDataphonesTableService } from '../../../../services/cash-closing.services';
import { deletedInventoryDataphonesTableService } from '../../../../services/cash-closing-inventory.services';

//Styles
import '../../cash-closing.page.scss';

const DataphonesComponent = (props) => {

    const {
        //Actions
        setStateCashClosingReducer,
        //Variables
        cashClosingData,
        dateInventory,
        //Functions
        getAllPortfolio
    } = props

    const INITIAL_STATE = {
        scrollEnd: false,
        dataPhones: [
            { price: "", voucherNumber: "", disabled: true },
            { price: "", voucherNumber: "", disabled: true },
            { price: "", voucherNumber: "", disabled: true },
            { price: "", voucherNumber: "", disabled: true },
            { price: "", voucherNumber: "", disabled: true },
            { price: "", voucherNumber: "", disabled: true },
            { price: "", voucherNumber: "", disabled: true },
        ]
    };

    const [state, setState] = useState(INITIAL_STATE)

    const { dataPhones, scrollEnd } = state

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current && scrollEnd) {
            const miDiv = formRef.current;
            miDiv.scrollTop = miDiv.scrollHeight;
        }
    }, [scrollEnd, dataPhones.length]);

    useEffect(() => {
        if (cashClosingData?.cashRegisterCashReceiptCardReader) {
            let arr1 = [...INITIAL_STATE.dataPhones];
            let arr2 = [...cashClosingData.cashRegisterCashReceiptCardReader];

            const minLen = Math.min(arr1.length, arr2.length);

            for (let i = 0; i < minLen; i++) {
                if (arr1[i].id !== arr2[i].id) {
                    arr1[i] = arr2[i];
                }
            };

            if (arr1.length < arr2.length) {
                for (let i = minLen; i < arr2.length; i++) {
                    arr1.push(arr2[i]);
                }
            };

            setState((prevState) => ({
                ...prevState,
                dataPhones: arr1,
                scrollEnd: false
            }))
        } else {
            setState(INITIAL_STATE)
        }
        formRef.current.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashClosingData?.cashRegisterCashReceiptCardReader]);

    const addDataPhones = () => {
        const objectDisabled = dataPhones.find(object => object.hasOwnProperty("disabled") && object.disabled === true);
        if (!objectDisabled) {
            const newArray = [
                ...dataPhones,
                {
                    price: "",
                    disabled: false,
                    voucherNumber: ""
                },
            ]
            setState({
                ...state,
                scrollEnd: true,
                dataPhones: newArray
            })
        } else {
            const newArray = dataPhones.map(object => object === objectDisabled ? { ...object, disabled: false } : object);
            setState({ ...state, dataPhones: newArray })
        };
    };

    const handleInputChange = (data, index, name) => {
        let value = data && data.target ? data.target.value || '' : data;
        const newArray = JSON.parse(JSON.stringify(dataPhones));

        newArray[index][name] = value;
        setState({
            ...state,
            dataPhones: newArray
        })
        setStateCashClosingReducer('dataPhonesForm', newArray);
    };

    const handleTotal = (data) => {
        let totalPrice = 0;
        data.forEach(object => {
            totalPrice += (parseFloat(object.price) ? parseFloat(object.price) : 0);
        });
        totalPrice = parseFloat(totalPrice.toFixed(2));
        return totalPrice;
    };

    const removeDataPhones = async (item, index) => {
        let respService = false;
        const initialItem = { price: "", voucherNumber: "", disabled: true };
        const newArray = JSON.parse(JSON.stringify(dataPhones));
        newArray[index] = initialItem;
        try {
            if (item.id) {
                if (dateInventory) {
                    respService = await deletedInventoryDataphonesTableService({ id: item.id });
                }else{
                    respService = await deletedRecordDataphonesTableService({ id: item.id });
                }
            };
            newArray.sort((a, b) => (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1);
            setState({ ...state, dataPhones: newArray });
            setStateCashClosingReducer('dataPhonesForm', newArray);
            (item?.id && respService) && await getAllPortfolio();
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };
    

    return (
        <div className='small-6 cash-closing__payments__box-padding'>
            <div className='cash-closing__payments__container'>
                <div className='cash-closing__payments__content'>
                    <div className='grid-y'>
                        <div className='grid-x cash-closing__payments__content-titles__fist'>
                            <div className='grid-x align-middle small-6'>
                                <img src={Assets.SharedIcons.icon_filter} alt="icon_filter" />
                                <span className='cash-closing__payments__titles cash-closing__payments__titles__space'>Datáfonos</span>
                            </div>
                            <div className='grid-x align-middle small-6 justify-content-end'>
                                <span className='cash-closing__payments__titles'>Total:</span>
                                <div className='grid-x align-middle cash-closing__summary__details'>
                                    <div className='cash-closing__payments__table__content-icon-price'>
                                        <img
                                            alt="icon_income"
                                            src={Assets.SharedIcons.icon_income}
                                            className='cash-closing__summary__price__icon'
                                        />
                                    </div>
                                    <NumericFormat
                                        type='text'
                                        prefix={"$ "}
                                        disabled={true}
                                        placeholder='$ 0'
                                        thousandSeparator=","
                                        className='input-price'
                                        thousandsGroupStyle="thousand"
                                        value={handleTotal(dataPhones)}
                                    />
                                </div>
                               
                            </div>
                        </div>
                        <div className='grid-x cash-closing__payments__content-titles__second'>
                            <span className='grid-x cash-closing__payments__titles small-6'>Numero de voucher</span>
                            <span className='grid-x justify-content-center cash-closing__payments__titles small-5'>Valor</span>
                        </div>
                    </div>
                    <div ref={formRef} className='cash-closing__payments__info'>
                        {dataPhones?.map((item, index) => {
                            return (
                                <div key={index} className='grid-x cash-closing__payments__table'>
                                    <div className='small-6'>
                                        <Input
                                            disabled={item?.disabled}
                                            value={item?.voucherNumber}
                                            placeholder='Numero de voucher'
                                            className='cash-closing__payments__input'
                                            onChange={(e) => { handleInputChange(e, index, "voucherNumber") }}
                                            prefix={<img src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit} alt="icon_add" />}
                                        />
                                    </div>
                                    <div className='grid-x small-5 justify-content-center'>
                                        <div className='grid-x cash-closing__payments__table__content-inputs'>
                                            <NumericFormat
                                                type='text'
                                                prefix={"$ "}
                                                placeholder='$ 0'
                                                value={item?.price}
                                                thousandSeparator=","
                                                className='input-price'
                                                disabled={item.disabled}
                                                thousandsGroupStyle="thousand"
                                                onValueChange={({floatValue}) => handleInputChange(floatValue, index, 'price')}
                                            />
                                            <div className='cash-closing__payments__table__content-icon-price'>
                                                <img
                                                    alt="icon_add"
                                                    src={item.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid-x small-1 justify-content-end'>
                                        <button
                                            onClick={() => removeDataPhones(item, index)}
                                            className='cash-closing__payments__button-delete'
                                            disabled={item?.disabled || cashClosingData?.isPublish}
                                        >
                                            <img
                                                className='cash-closing__payments__button-delete__icon'
                                                alt={ item?.disabled ? 'icon_deleted_disabled' : 'icon_deleted_active'}
                                                src={item?.disabled ? Assets.SharedIcons.icon_deleted_disabled : Assets.SharedIcons.icon_deleted_active}
                                            />
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className='grid-x'>
                        <div className='cash-closing__payments__line'></div>
                        <button
                            onClick={() => addDataPhones()}
                            disabled={cashClosingData?.isPublish}
                            className='grid-x align-middle cash-closing__payments__type cash-closing__payments__type'
                        >
                            <img
                                alt='icon_income'
                                src={Assets.SharedIcons.icon_income}
                            />
                            <span className='cash-closing__payments__type__name'>Agregar venta de datáfono</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ CashClosingReducer }) => {
    const { cashClosingData } = CashClosingReducer;
    return {
        cashClosingData
    };
};

const mapStateToPropsActions = {
    setStateCashClosingReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(DataphonesComponent);