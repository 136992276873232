// Assets
import { Assets } from '../../../assets'

//Components
import SuccessAlertComponent from '../../../shared/components/alerts/success-alert/success-alert.component';
import ErrorAlertComponent from '../../../shared/components/alerts/error-alert/error-alert.component';
import {ModalDownloadExcelComponent} from '../../../shared/components/modals/modal-download-excel/modal-download-excel.component';

// Libraries
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { NumericFormat } from "react-number-format";
import { Input, DatePicker, Tooltip, Select } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Services
import { getAllPortfolioService, getSummaryByHeadquarterService, getSummaryByHeadquarterWalletService, updateSelectorWalletInventory, getDownloadExcelWalletService } from '../../../services/portfolio.services';
import { setModuleNameService } from '../../../services/authentication.services';
import { getAllExpenseCategoryService, getExpenseSubCategoryService } from '../../../services/shared.services';

// Styles
import './portfolio-detail.page.scss'

// Utils
import { dataExcelDetailPage, dataPayment } from '../../../utils/portfolio-detail.page'

const PortfolioDetailPage = () => {

  let history = useNavigate()
  const { id } = useParams(); // Get the site ID from the URL
  const { state: routeState } = useLocation();
  const dateSelected = routeState?.dateSelected;

  const INITIAL_STATE = {
    headquartersList: [],
    summaryByHeadquarter: {
      cashReceipt: dataPayment,
      cashExpense: dataPayment
    },
    optionsOfExpenseCategory: [],
    isViewModal: false,
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { headquartersList, summaryByHeadquarter, optionsOfExpenseCategory,isViewModal} = state;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(dayjs(dateSelected));
  const [selectedDates, setSelectedDates] = useState(null);

  useEffect(() => {
    //Send route name
    setModuleNameService('Carteras');

  }, [])

  useEffect(() => {
    dataPortfolioDetail(dateSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getOptionsOfCategories();
  }, [])

  const getOptionsOfCategories = async () => {
    try {
      const listOfExpenseCategory = await getAllExpenseCategoryService();
      setState((prevState) => ({
        ...prevState,
        optionsOfExpenseCategory: listOfExpenseCategory
      }))
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const getListOfSubCategories = async (idExpenseCategory) => {
    try {
        return await getExpenseSubCategoryService({ idExpenseCategory });
    } catch (error) {
        // TODO: Implement error alert with code error.
    }
  }; 

  const dataPortfolioDetail = async (dateSelect) => {
    let services = [getAllPortfolioService()];
    if (routeState?.key && routeState?.key === 6) {
      services.push(
        getSummaryByHeadquarterWalletService({
          date: dayjs(dateSelect).format('YYYY-MM-DD'),
          idHeadQuarter: id
        })
      )
    }else{
      services.push(
        getSummaryByHeadquarterService({
          date: dayjs(dateSelect).endOf('month').format('YYYY-MM-DD'),
          idHeadQuarter: id
        })
      )
    }

    await Promise.allSettled(services)
      .then(async (res) => {
        let headquartersList = [];
        let summaryByHeadquarter = {};
        let cashReceipt = [];
        let cashExpense = [];

        for (let index = 0; index < res.length; index++) {
          const elementResponse = res[index];

          if (elementResponse.status === "fulfilled") {
            switch (index) {
              case 0:
                headquartersList = elementResponse?.value
                break;
              case 1:
                summaryByHeadquarter = elementResponse?.value
                cashReceipt = [...summaryByHeadquarter?.cashReceipt];
                cashExpense = [...summaryByHeadquarter?.cashExpense];
                const newCashReceipt = [...INITIAL_STATE?.summaryByHeadquarter?.cashReceipt];
                const newCashExpense = [...INITIAL_STATE?.summaryByHeadquarter?.cashExpense];

                await Promise.all(
                  cashExpense.map(async (data) => {
                    const id = data?.expenseSubcategoryTransaction?.expenseCategoryExpenseSubcategory?.id;
                    if (id) {
                      const response = await getListOfSubCategories(id);
                      data.listOfExpenseSubCategory = response || [];
                    }
                    data.category = {
                      label: data.expenseSubcategoryTransaction?.expenseCategoryExpenseSubcategory?.name,
                      value: data.expenseSubcategoryTransaction?.expenseCategoryExpenseSubcategory?.id
                    };
                    data.subCategory = {
                      label: data.expenseSubcategoryTransaction?.name,
                      value: data.expenseSubcategoryTransaction?.id
                    };

                    if (data.category?.value) {
                       data.disabledCategory = true
                    }
                    if (data.subCategory?.value) {
                       data.disabledSubCategory = true
                    }
                  })
                );

                while (cashReceipt.length < 10 && newCashReceipt.length > 0) {
                  cashReceipt.push(newCashReceipt.shift())
                }
                while (cashExpense.length < 10 && newCashExpense.length > 0) {
                  cashExpense.push(newCashExpense.shift())
                }
                break;
              default:
                break;
            }
          }
        };
        setState((prevState) => ({
          ...prevState,
          headquartersList,
          summaryByHeadquarter: {
            ...summaryByHeadquarter,
            cashReceipt,
            cashExpense
          }
        }));
        setDataLoaded(true);
      })
      .catch(() => {
        // TODO: Implement error alert with code error.
      })
  }

  if (!dataLoaded) {
    return <div>Cargando...</div>;
  }
  const headquarters = headquartersList?.find((headquarters) => headquarters.id === id);

  if (!headquarters) {
    return <div>Sede no encontrada.</div>;
  };

  const goToMonth = (amount) => {
    const newDate = dayjs(selectedMonth).add(amount, 'month');
    const currentDate = dayjs();

    if (newDate.isBefore(currentDate, 'month') || newDate.isSame(currentDate, 'month')) {
      setSelectedMonth(newDate);
      dataPortfolioDetail(dayjs(newDate).format('YYYY-MM-DD'));
    }
  };

  const onChangeDate = (date) => {
    setSelectedMonth(date);
    dataPortfolioDetail(dayjs(date).format('YYYY-MM-DD'));
  };

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs(), 'month');
  };

  const handleChange = async(e, index, target, item) => {
    const newArray = JSON.parse(JSON.stringify(summaryByHeadquarter.cashExpense));

    if ((target === "category") || (target === "subCategory")) {
      newArray[index][target] = {
          label: e.label,
          value: e.value
      }
      if (target === "category") {
        try {
          const listOfExpenseSubCategory = await getListOfSubCategories(e.value);
          newArray[index].listOfExpenseSubCategory = listOfExpenseSubCategory;
          newArray[index].subCategory = { label: "", value: ""}
        } catch (error) {
          // TODO: Implement error alert with code error.
        }
      }

      if (target === "subCategory") {
        try {
          const idExpenseSubcategory = e.id
          const idTransaction = item.id

          await updateSelectorWalletInventory(idExpenseSubcategory, idTransaction)
          SuccessAlertComponent();
        } catch (error) {
          ErrorAlertComponent()
        }
      }
    }
    else {
      newArray[index][target] = e
    }

    setState((prevState) => ({
      ...prevState,
          summaryByHeadquarter: {
            ...prevState.summaryByHeadquarter,
            cashExpense: newArray,
          }
    }))
  };
  
  const closeModal = () => {
    setState((prevState) => ({
      ...prevState,
      isViewModal: false,
      startSelected: undefined,
      endSelected: undefined
    }))
  }
  const onDateChange = (dates) => {
    setSelectedDates(dates);
  };

  const dataExcelDetail = async () => {
    try {
      const startDate = selectedDates[0].format('YYYY-MM-DD');
      const endDate = selectedDates[1].format('YYYY-MM-DD');
      const response = await getDownloadExcelWalletService(startDate, endDate, headquarters.id);
      dataExcelDetailPage(response);

    } catch (error) {
      ErrorAlertComponent()
    }
  };

  return (
    <div className='portfolio-detail-page__container'>
      <div className='grid-x small-12 portfolio-detail-page__content-header'>
        <div className='grid-x align-middle'>
          <button
            onClick={() => history(-1)}
            className="grid-x portfolio-detail-page__content-header__link"
          >
            Histórico de carteras
            <img
              alt='icon_arrow'
              src={Assets.SharedIcons.icon_arrow}
              style={{ marginLeft: '10px' }}
            />
          </button>
          <span className='portfolio-detail-page__name'>
            {headquarters.name}
          </span>
        </div>
      </div>
      <div className='grid-x small-12 align-bottom portfolio-detail-page__content-date-picker'>
        <div>
          <div className='grid-x align-middle justify-content-between portfolio-detail-page__content-date-picker__date'>
            <button onClick={() => goToMonth(-1)}>
              <img
                alt='icon_arrow'
                src={Assets.SharedIcons.icon_arrow}
                className='portfolio-detail-page__content-date-picker__arrow'
              />
            </button>
            <DatePicker
              picker='month'
              bordered={false}
              suffixIcon={null}
              allowClear={false}
              inputReadOnly={true}
              value={selectedMonth}
              defaultValue={dayjs()}
              disabledDate={disabledDate}
              onChange={(date) => onChangeDate(date)}
              className='portfolio-detail-page__content-date-picker__month'
              format={(value) => {
                return `${dayjs(value)?.format('MMMM, YYYY')}`;
              }}
            />
            <button
              onClick={() => goToMonth(1)}
              className='portfolio-detail-page__content-date-picker__button-next'
              disabled={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month'))}
            >
              <img
                alt={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month'))  ? 'icon_arrow_picker_disabled' : 'icon_arrow'}
                src={(dayjs(selectedMonth).add(1, 'month').isAfter(dayjs(), 'month')) ? Assets.SharedIcons.icon_arrow_picker_disabled : Assets.SharedIcons.icon_arrow}
              />
            </button>
          </div>
        </div>
        <div className='grid-x portfolio-detail-page__content-header__totalizers'>
          <div className='grid-x small-5 portfolio-detail-page__content-header__box'>
            <div className='small-6 portfolio-detail-page__content-header__box__padding-box'>
              <span className="portfolio-detail-page__content-header__text">
                <img
                  alt='icon_income'
                  src={Assets.SharedIcons.icon_income}
                  style={{ height: '15px', width: '15px', marginRight: '3px' }}
                />
                Total de Abonos
              </span>
              <div className="grid-x portfolio-detail-page__content-header__details">
                <NumericFormat
                  disabled
                  type='text'
                  prefix={"$ "}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                  value={summaryByHeadquarter?.totals?.cashReceived}
                />
              </div>
            </div>
            <div className='small-6 portfolio-detail-page__content-header__box__padding-box'>
              <span className="portfolio-detail-page__content-header__text">
                <img
                  src={Assets.SharedIcons.icon_egress}
                  alt='icon_egress'
                  style={{ height: '15px', width: '15px', marginRight: '3px' }}
                />
                Total de Cobros
              </span>
              <div className="grid-x portfolio-detail-page__content-header__details">
                <NumericFormat
                  type='text'
                  prefix={"$ "}
                  disabled={true}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                  value={summaryByHeadquarter?.totals?.cashOutflow}
                />
              </div>
            </div>
          </div>
          <div className='small-3 portfolio-detail-page__content-header__box'>
            <span className="portfolio-detail-page__content-header__text">Total a la fecha</span>
            <div className="grid-x portfolio-detail-page__content-header__details">
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                className='input-price'
                thousandsGroupStyle="thousand"
                value={summaryByHeadquarter?.totals?.total}
              />
            </div>
          </div>
        </div>
        <div className="grid-x align-middle">
          <button
            className="grid-xgrid-x align-middle portfolio-detail-page__content-header__details__button-download"
            onClick={() => setState((prevState) => ({ ...prevState, isViewModal: true }))}>
            <img
              alt={"icon_download_blue_hover"}
              src={Assets.SharedIcons.icon_publish}
            />
            <span>Descargar Excel</span>
          </button>
        </div>
        {isViewModal && (
          <ModalDownloadExcelComponent
            isOpen={isViewModal}
            onClose={() => closeModal()}
            onGenerate={() => dataExcelDetail()}
            onDateChange={onDateChange}
            title={"Seleccione el rango de fechas"}
            month={true}
          />
        )}
      </div>
      <div className='grid-x small-12 justify-content-between portfolio-detail-page__content-tables'>
        <div className='portfolio-detail-page__content-tables__table_fertilizers'>
          <div className='grid-x align-middle justify-content-between portfolio-detail-page__content-tables__content-values'>
            <div className='grid-x align-middle'>
              <img
                alt='icon_income'
                src={Assets.SharedIcons.icon_income}
              />
              <span className='portfolio-detail-page__content-tables__title'>Abonos</span>
            </div>
            <div className='grid-x align-middle'>
              <span className='portfolio-detail-page__content-tables__title'>Total del mes</span>
              <div className='grid-x align-middle portfolio-detail-page__content-tables__values'>
                <div className='portfolio-detail-page__content-tables__values__content-icon'>
                  <img
                    alt="icon_income"
                    src={Assets.SharedIcons.icon_income}
                    className='portfolio-detail-page__content-tables__values__content-icon__icon'
                  />
                </div>
                <NumericFormat
                  disabled
                  value={summaryByHeadquarter?.totals?.subTotal}
                  type='text'
                  prefix={"$ "}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                />
              </div>
            </div>
          </div>
          <div className='grid-x small-12 portfolio-detail-page__content-tables__content-info'>
            <div className='grid-x align-content-end portfolio-detail-page__content-tables__content-info__content-title'>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Fecha</span>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Concepto</span>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Tipo de pago</span>
              <span className='small-3 portfolio-detail-page__content-tables__content-info__title'>Valor</span>
            </div>
            <div className='portfolio-detail-page__content-tables__content-info__content-scroll'>
              {summaryByHeadquarter?.cashReceipt.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='grid-x align-middle portfolio-detail-page__content-tables__content-info__information-row'
                  >
                    <div className={`small-3 portfolio-detail-page__content-tables__content-input${!item?.date ? '__disabled' : ''}`}>
                      <DatePicker
                        disabled
                        clearIcon={null}
                        suffixIcon={null}
                        format="YYYY/MM/DD"
                        placeholder="AAAA/MM/DD"
                        value={item?.date ? dayjs(item?.date, 'YYYY:MM:DD') : null}
                      />
                    </div>
                    <div className={`small-3 portfolio-detail-page__content-tables__content-input${!item?.conceptDescription ? '__disabled' : ''}`}>
                      <Tooltip
                        color='gray'
                        overlayStyle={{maxWidth: '100%'}}
                        title={item?.conceptDescription}
                      >
                        <Input
                          disabled
                          prefix={null}
                          placeholder='Descripción'
                          value={item?.conceptDescription}
                        />
                      </Tooltip>
                    </div>
                    <div className={`small-3 portfolio-detail-page__content-tables__content-input${!item?.PaymentMethod?.name ? '__disabled' : ''}`}>
                      <Input
                        disabled
                        prefix={null}
                        placeholder='Tipo de pago'
                        value={item?.PaymentMethod?.name}
                      />
                    </div>
                    <div className={`grid-x small-3 justify-content-end portfolio-detail-page__content-tables__content-input${!item.price ? '__disabled' : ''}`}>
                      <NumericFormat
                        disabled
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        value={item?.price}
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='portfolio-detail-page__content-tables__table'>
          <div className='grid-x align-middle justify-content-between portfolio-detail-page__content-tables__content-values'>
            <div className='grid-x align-middle'>
              <img
                alt='icon_egress'
                src={Assets.SharedIcons.icon_egress}
              />
              <span className='portfolio-detail-page__content-tables__title'>Cobros y créditos</span>
            </div>
            <div className='grid-x align-middle'>
              <span className='portfolio-detail-page__content-tables__title'>Total del mes</span>
              <div className='grid-x portfolio-detail-page__content-tables__values--mod'>
                <div className='portfolio-detail-page__content-tables__values__content-icon'>
                  <img
                    alt="icon_egress"
                    src={Assets.SharedIcons.icon_egress}
                    className='portfolio-detail-page__content-tables__values__content-icon__icon'
                  />
                </div>
                <NumericFormat
                  disabled
                  value={summaryByHeadquarter?.totals?.totalOtherProducts}
                  type='text'
                  prefix={"$ "}
                  placeholder='$ 0'
                  thousandSeparator=","
                  className='input-price'
                  thousandsGroupStyle="thousand"
                />
              </div>
            </div>
          </div>
          <div className='grid-x small-12 portfolio-detail-page__content-tables__content-info'>
            <div className='grid-x align-content-end portfolio-detail-page__content-tables__content-info__content-title'>
              <span className='small-2 portfolio-detail-page__content-tables__content-info__title'>Fecha</span>
              <span className='small-2 portfolio-detail-page__content-tables__content-info__title'>Concepto</span>
              <span className='small-2 portfolio-detail-page__content-tables__content-info__title'>Categoría</span>
              <span className='small-2 portfolio-detail-page__content-tables__content-info__title'>Sub-categoría</span>
              <span className='small-2 portfolio-detail-page__content-tables__content-info__title'>Tipo de pago</span>
              <span className='small-2 portfolio-detail-page__content-tables__content-info__title'>Valor</span>
            </div>
            <div className='portfolio-detail-page__content-tables__content-info__content-scroll'>
              {summaryByHeadquarter.cashExpense.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='grid-x align-middle portfolio-detail-page__content-tables__content-info__information-row'
                  >
                    <div className={`small-2 portfolio-detail-page__content-tables__content-input${!item?.date ? '__disabled' : ''}`}>
                      <DatePicker
                        disabled
                        clearIcon={null}
                        suffixIcon={null}
                        format="YYYY/MM/DD"
                        placeholder="AAAA/MM/DD"
                        value={item?.date ? dayjs(item?.date, 'YYYY:MM:DD') : null}
                      />
                    </div>
                    <div className={`small-2 portfolio-detail-page__content-tables__content-input${!item?.conceptDescription ? '__disabled' : ''}`}>
                      <Tooltip
                        color="gray"
                        overlayStyle={{ maxWidth: '100%' }}
                        overlayInnerStyle={{ fontSize: '12px' }}
                        title={item?.conceptDescription}
                      >
                        <Input
                          disabled
                          prefix={null}
                          placeholder='Descripción'
                          value={item?.conceptDescription}
                        />
                      </Tooltip>
                    </div>
                    <div className={`small-2 portfolio-detail-page__content-tables__content-select${!item?.expenseSubcategoryTransaction?.expenseCategoryExpenseSubcategory?.name ? '__disabled' : ''}`}>
                      <Tooltip
                        color="gray"
                        overlayStyle={{ maxWidth: '100%' }}
                        overlayInnerStyle={{ fontSize: '12px' }}
                        title={item?.category?.label}
                      >
                        <Select
                          disabled={!item.disabledCategory}
                          prefix={null}
                          placeholder='Categoría'
                          value={item?.category?.value ? item?.category : undefined}
                          options={optionsOfExpenseCategory}
                          popupMatchSelectWidth={false}
                          popupClassName='portfolio-detail-page__content-tables__content-select__popup'
                          onChange={(e, event) => handleChange(event, index, "category")}
                          suffixIcon={
                            <div className='portfolio-detail-page__content-tables__content-select__icon'>
                              <img
                                src={!item.disabledCategory ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                alt={!item.disabledCategory ? "icon_disabled_arrow" : "icon_blue_arrow"}
                              />
                            </div>
                          }
                        />
                      </Tooltip>
                    </div>
                    <div className={`small-2 portfolio-detail-page__content-tables__content-select${!item?.expenseSubcategoryTransaction?.name ? '__disabled' : ''}`}>
                      <Tooltip
                        color="gray"
                        overlayStyle={{ maxWidth: '100%' }}
                        overlayInnerStyle={{ fontSize: '12px' }}
                        title={item?.subCategory?.label}
                      >
                        <Select
                          disabled={!item.disabledSubCategory}
                          prefix={null}
                          placeholder='Sub-categoría'
                          value={item?.subCategory?.value ? item?.subCategory : undefined}
                          options={item?.listOfExpenseSubCategory}
                          popupMatchSelectWidth={false}
                          popupClassName='portfolio-detail-page__content-tables__content-select__popup'
                          onChange={(e, event) => handleChange(event, index, "subCategory", item)}
                          suffixIcon={
                            <div className='portfolio-detail-page__content-tables__content-select__icon'>
                              <img
                                src={!item.disabledSubCategory ? Assets.SharedIcons.icon_disabled_arrow : Assets.SharedIcons.icon_blue_arrow}
                                alt={!item.disabledSubCategory ? "icon_disabled_arrow" : "icon_blue_arrow"}
                              />
                            </div>
                          }
                        />
                      </Tooltip>
                    </div>
                    <div className={`small-2 portfolio-detail-page__content-tables__content-input${!item?.PaymentMethod?.name ? '__disabled' : ''}`}>
                      <Input
                        disabled
                        prefix={null}
                        placeholder='Tipo de pago'
                        value={item?.PaymentMethod?.name}
                      />
                    </div>
                    <div className={`grid-x small-2 justify-content-end portfolio-detail-page__content-tables__content-input${!item.price ? '__disabled' : ''}`}>
                      <NumericFormat
                        disabled
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        value={item?.price}
                        thousandSeparator=","
                        className='input-price'
                        thousandsGroupStyle="thousand"
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetailPage;