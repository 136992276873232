//Assets
import { Assets } from "../../../../../assets";

//Components
import ErrorAlertComponent from "../../../../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../../../../shared/components/alerts/success-alert/success-alert.component";
import InformationAlertComponent from "../../../../../shared/components/alerts/information-alert/information-alert.component";

//Libraries
import React, { useEffect, useState } from 'react'
import { Input } from "antd";

//Services
import {
  createCategoryHeadquarterService,
  createHeadquarterService,
  getCategoryHeadquarterService,
  getHeadquarterByIdCategoryService,
  updateCategoryHeadquarterService,
  updateHeadquarterService
} from "../../../../../services/administration-panel.services";

//Styles
import "./wallet-rule.component.scss"

export const WalletRuleComponent = () => {

  const INITIAL_STATE = {
    //Category
    listOfCategories: [],
    selectedIdCategory: "",
    isAddCategory: false,
    isEditing: false,
    nameOfCategory: "",
    //Sub-category
    listOfSubCategories: [],
    selectedIdSubCategory: "",
    isAddSubCategory: false,
    isEditingSubCategory: false,
    nameOfSubCategory: ""
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    //Category
    listOfCategories,
    selectedIdCategory,
    isAddCategory,
    isEditing,
    nameOfCategory,
    //Sub-category
    listOfSubCategories,
    selectedIdSubCategory,
    isAddSubCategory,
    isEditingSubCategory,
    nameOfSubCategory
  } = state;

  useEffect(() => {
    getListOfCategoriesHeadquarter();
  }, [])

  const getListOfCategoriesHeadquarter = async () => {
    try {
      const respListCategories = await getCategoryHeadquarterService();
      setState((prevState) => ({
        ...prevState,
        listOfCategories: respListCategories
      }))
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const getListOfHeadquarterByIdCategory = async (idHeadQuarterCategory) => {
    try {
      let respListOfSubcategory = await getHeadquarterByIdCategoryService(idHeadQuarterCategory);
      setState((prevState) => ({
        ...prevState,
        listOfSubCategories: respListOfSubcategory,
        selectedIdCategory: idHeadQuarterCategory
      }))
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const createAndUpdateNewCategoryService = async (name) => {
    try {
      const nameExist = listOfCategories.some(category => category.name.toLowerCase() === name.toLowerCase())
      const nameLength = name.length <= 1
      if (isEditing) {
        if (nameExist) {
          InformationAlertComponent(undefined, "El nombre de la categoría de cartera ya existe");
          return;
        } else if (nameLength) {
          InformationAlertComponent(undefined, "El nombre de la categoría de cartera debe tener al menos 2 caracteres")
          return;
        }
        await updateCategoryHeadquarterService({ name, id: selectedIdCategory })
        getListOfCategoriesHeadquarter();
        SuccessAlertComponent(undefined, "Se ha actualizado la categoría de cartera satisfactoriamente");
      } else {
        if (nameExist) {
          InformationAlertComponent(undefined, "El nombre de la categoría de cartera ya existe");
          return;
        } else if (nameLength) {
          InformationAlertComponent(undefined, "El nombre de la categoría de cartera debe tener al menos 2 caracteres")
          return;
        }
        await createCategoryHeadquarterService({ name })
        getListOfCategoriesHeadquarter();
        SuccessAlertComponent(undefined, "Se ha creado la categoría de cartera satisfactoriamente");
      }
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const createAndUpdateNewSubCategoryService = async (name) => {
    try {
      const nameExist = listOfSubCategories.some(category => category.name.toLowerCase() === name.toLowerCase())
      const nameLength = name.length <= 1
      if (isEditingSubCategory) {
        if (nameExist) {
          InformationAlertComponent(undefined, "El nombre de la cartera ya existe");
          return;
        } else if (nameLength) {
          InformationAlertComponent(undefined, "El nombre de la cartera debe tener al menos 2 caracteres")
          return;
        }
        await updateHeadquarterService({ name, idHeadquarter: selectedIdSubCategory })
        getListOfHeadquarterByIdCategory(selectedIdCategory);
        SuccessAlertComponent(undefined, "Se ha actualizado la cartera satisfactoriamente");
      } else {
        if (nameExist) {
          InformationAlertComponent(undefined, "El nombre de la cartera ya existe");
          return;
        } else if (nameLength) {
          InformationAlertComponent(undefined, "El nombre de la cartera debe tener al menos 2 caracteres")
          return;
        }
        await createHeadquarterService({ name, idHeadQuarterCategory: selectedIdCategory })
        getListOfHeadquarterByIdCategory(selectedIdCategory);
        SuccessAlertComponent(undefined, "Se ha creado la cartera satisfactoriamente");
      }
    } catch (error) {
      ErrorAlertComponent();
    }
  };

  const onChangeField = (data, target) => {
    let value = data && data.target ? data.target.value || '' : data;

    if (target === "nameOfCategory") {
      setState((prevState) => ({
        ...prevState,
        nameOfCategory: value
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        nameOfSubCategory: value
      }))

    }
  };

  const onClickButtonsCategories = async (target) => {
    if (target === "add") {
      setState((prevState) => ({
        ...prevState,
        isAddCategory: true,
        isEditing: false,
        isAddSubCategory: false
      }))
    }

    if (target === "cancel" || target === "accept") {
      setState((prevState) => ({
        ...prevState,
        isAddCategory: false,
        nameOfCategory: ""
      }))

      if (target === "accept") {
        setState((prevState) => ({
          ...prevState,
          isEditing: false
        }))
        createAndUpdateNewCategoryService(nameOfCategory);
      }
    }
  };

  const onClickButtonsSubCategories = async (target) => {
    if (target === "addSubCategory") {
      setState((prevState) => ({
        ...prevState,
        isAddSubCategory: true,
        isEditingSubCategory: false,
        isAddCategory: false
      }))
    }

    if (target === "cancelSubCategory" || target === "acceptSubCategory") {
      setState((prevState) => ({
        ...prevState,
        isAddSubCategory: false,
        nameOfSubCategory: ""
      }))

      if (target === "acceptSubCategory") {
        setState((prevState) => ({
          ...prevState,
          isEditingSubCategory: false
        }))
        createAndUpdateNewSubCategoryService(nameOfSubCategory)
      }
    }
  };

  return (
    <div className="wallet-rule__container">
      <div className="wallet-rule__content-box-category">
        <div className="wallet-rule__content-title">
          <img
            src={Assets.SharedIcons.icon_liquor_blue}
            alt="icon_sites"
          />
          <span className="wallet-rule__title">
            Categoría de cartera
          </span>
        </div>
        <div className="wallet-rule__list-titles__content">
          <span className="wallet-rule__list-titles__title-name__title-category">
            Nombre de la categoría de cartera
          </span>
        </div>
        <div className="wallet-rule__content-info">
          {listOfCategories?.map((item, index) => {
            return (
              <div key={index} className={`grid-x wallet-rule__info-row${selectedIdCategory === item.id ? '__selected' : ''}`}>
                <div className="wallet-rule__info-row__content-title">
                  <span className="wallet-rule__info-row__text-site">
                    {item?.name}
                  </span>
                </div>
                <div className="wallet-rule__info-row__content-edit">
                  <button
                    className="wallet-rule__info-row__content-edit__edit"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isAddCategory: true,
                        isAddSubCategory: false,
                        isEditing: true,
                        nameOfCategory: item.name,
                        selectedIdCategory: item.id
                      }))
                      getListOfHeadquarterByIdCategory(item.id)
                    }}
                  >
                    <img
                      src={Assets.SharedIcons.icon_edit}
                      alt="icon_edit"
                    />
                  </button>
                </div>
                <div className="wallet-rule__info-row__content-icon">
                  <button
                    className="wallet-rule__info-row__content-icon__icon"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isAddCategory: false,
                        nameOfCategory: ""
                      }))
                      getListOfHeadquarterByIdCategory(item.id)
                    }}
                  >
                    <img
                      src={selectedIdCategory === item.id ? Assets.SharedIcons.icon_arrow : Assets.SharedIcons.icon_blue_arrow}
                      alt={selectedIdCategory === item.id ? "icon_arrow" : "icon_blue_arrow"}
                    />
                  </button>
                </div>
              </div>
            )
          })}
        </div>
        {isAddCategory ? (
          <div className="wallet-rule__content-input-add">
            <Input
              value={nameOfCategory}
              placeholder="Nombre de la categoría"
              className="wallet-rule__content-input-add__input"
              onChange={(value) => onChangeField(value, "nameOfCategory")}
            />
            <div className="wallet-rule__content-input-add__content-button">
              <button
                onClick={() => onClickButtonsCategories("cancel")}
                className="wallet-rule__content-input-add__button-cancel"
              >
                Cancelar
              </button>
              <button
                onClick={() => onClickButtonsCategories("accept")}
                className="wallet-rule__content-input-add__button-accept"
              >
                {isEditing ? "Editar" : "Crear"}
              </button>
            </div>
          </div>
        ) : (
          <div className="grid-x align-middle wallet-rule__content-button-add">
            <button
              onClick={() => onClickButtonsCategories("add")}
            >
              <span className="wallet-rule__title-name-add">
                Agregar nueva categoría
              </span>
              <img
                src={Assets.SharedIcons.icon_add_plus}
                alt="icon_add_plus"
              />
            </button>
          </div>
        )}
      </div>
      <div className="wallet-rule__content-box-subCategory">
        <div className="wallet-rule__content-title">
          <img
            src={Assets.SharedIcons.icon_liquor_blue}
            alt="icon_sites"
          />
          <span className="wallet-rule__title">
            Carteras
          </span>
        </div>
        <div className="wallet-rule__list-titles__content">
          <span className="wallet-rule__list-titles__title-name__title-subcategory">
            Nombre de la cartera
          </span>
        </div>
        <div className="wallet-rule__content-info">
          {!selectedIdCategory ? (
            <>
              <span className="wallet-rule__span-selected">
                Selecciona una categoría de cartera
              </span>
            </>
          ) : (
            <>
              {listOfSubCategories?.map((item, index) => {
                return (
                  <div key={index} className="grid-x wallet-rule__info-row">
                    <div className="wallet-rule__info-row__content-title-subcategory">
                      <span className="wallet-rule__info-row__text-site">
                        {item?.name}
                      </span>
                    </div>
                    <div className="wallet-rule__info-row__content-edit">
                      <button
                        className="wallet-rule__info-row__content-edit__edit"
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            isAddSubCategory: true,
                            isAddCategory: false,
                            isEditingSubCategory: true,
                            nameOfSubCategory: item.name,
                            selectedIdSubCategory: item.id
                          }))
                        }}
                      >
                        <img
                          src={Assets.SharedIcons.icon_edit}
                          alt="icon_edit"
                        />
                      </button>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
        {isAddSubCategory ? (
          <div className="wallet-rule__content-input-add-subCategory">
            <Input
              value={nameOfSubCategory}
              placeholder="Nombre de la sub-categoría"
              className="wallet-rule__content-input-add-subCategory__input"
              onChange={(value) => onChangeField(value, "nameOfSubCategory")}
            />
            <div className="wallet-rule__content-input-add-subCategory__content-button">
              <button
                onClick={() => { onClickButtonsSubCategories("cancelSubCategory") }}
                className="wallet-rule__content-input-add-subCategory__button-cancel"
              >
                Cancelar
              </button>
              <button
                onClick={() => { onClickButtonsSubCategories("acceptSubCategory") }}
                className="wallet-rule__content-input-add-subCategory__button-accept"
              >
                {isEditingSubCategory ? "Editar" : "Crear"}
              </button>
            </div>
          </div>
        ) : (
          <div className="grid-x align-middle wallet-rule__content-button-add">
            <button
              disabled={selectedIdCategory ? false : true}
              onClick={() => { onClickButtonsSubCategories("addSubCategory") }}
            >
              <span className={`${selectedIdCategory ? 'wallet-rule__title-name-add' : 'wallet-rule__title-name-add-disabled'}`}>
                Agregar nueva cartera
              </span>
              <img
                src={selectedIdCategory ? Assets.SharedIcons.icon_add_plus : Assets.SharedIcons.icon_add}
                alt="icon_add_plus"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  )
};