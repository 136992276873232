//Assets
import { Assets } from "../../../../../assets";

//Components
import ErrorAlertComponent from "../../../../../shared/components/alerts/error-alert/error-alert.component";
import SuccessAlertComponent from "../../../../../shared/components/alerts/success-alert/success-alert.component";
import InformationAlertComponent from "../../../../../shared/components/alerts/information-alert/information-alert.component";

//Libraries
import React, { useEffect, useState } from "react";
import { Checkbox, Input } from "antd";

//Services
import { getAllExpenseCategoryService, getExpenseSubCategoryService } from "../../../../../services/shared.services";
import { createCategoryService, createSubCategoryService, updateCategoryService, updateSubCategoryService } from "../../../../../services/administration-panel.services";

//Styles
import "./expense-account.component.scss"

export const ExpenseAccountComponent = () => {

    const INITIAL_STATE = {
        //Category
        listOfCategories: [],
        selectedIdCategory: "",
        isAddCategory: false,
        isEditing: false,
        nameOfCategory: "",
        //Sub-category
        listOfSubCategories: [],
        selectedIdSubCategory: "",
        isAddSubCategory: false,
        isEditingSubCategory: false,
        nameOfSubCategory: ""
    };

    const [state, setState] = useState(INITIAL_STATE);

    const {
        //Category
        listOfCategories,
        selectedIdCategory,
        isAddCategory,
        isEditing,
        nameOfCategory,
        //Sub-category
        listOfSubCategories,
        selectedIdSubCategory,
        isAddSubCategory,
        isEditingSubCategory,
        nameOfSubCategory
    } = state;

    useEffect(() => {
        getListOfCategories();
    }, [])

    const getListOfCategories = async () => {
        try {
            const respListCategories = await getAllExpenseCategoryService();
            setState((prevState) => ({
                ...prevState,
                listOfCategories: respListCategories
            }))
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const getListOfSubCategory = async (idExpenseCategory) => {
        try {
            let respListOfSubcategory = await getExpenseSubCategoryService({ idExpenseCategory });
            setState((prevState) => ({
                ...prevState,
                listOfSubCategories: respListOfSubcategory,
                selectedIdCategory: idExpenseCategory
            }))
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const onChangeField = (data, target) => {
        let value = data && data.target ? data.target.value || '' : data;

        if (target === "nameOfCategory") {
            setState((prevState) => ({
                ...prevState,
                nameOfCategory: value
            }))
        } else {
            setState((prevState) => ({
                ...prevState,
                nameOfSubCategory: value
            }))

        }
    };

    const onClickButtonsCategories = async (target) => {
        if (target === "add") {
            setState((prevState) => ({
                ...prevState,
                isAddCategory: true,
                isEditing: false,
                isAddSubCategory: false
            }))
        }

        if (target === "cancel" || target === "accept") {
            setState((prevState) => ({
                ...prevState,
                isAddCategory: false,
                nameOfCategory: ""
            }))

            if (target === "accept") {
                setState((prevState) => ({
                    ...prevState,
                    isEditing: false
                }))
                createAndUpdateNewCategoryService(nameOfCategory);
            }
        }
    };

    const onClickButtonsSubCategories = async (target) => {
        if (target === "addSubCategory") {
            setState((prevState) => ({
                ...prevState,
                isAddSubCategory: true,
                isEditingSubCategory: false,
                isAddCategory: false
            }))
        }

        if (target === "cancelSubCategory" || target === "acceptSubCategory") {
            setState((prevState) => ({
                ...prevState,
                isAddSubCategory: false,
                nameOfSubCategory: ""
            }))

            if (target === "acceptSubCategory") {
                setState((prevState) => ({
                    ...prevState,
                    isEditingSubCategory: false
                }))
                createAndUpdateNewSubCategoryService(nameOfSubCategory)
            }
        }
    };

    const createAndUpdateNewCategoryService = async (name) => {
        try {
            const nameExist = listOfCategories.some(category => category.name.toLowerCase() === name.toLowerCase())
            const nameLength = name.length <= 1
            if (isEditing) {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la categoría debe tener al menos 2 caracteres")
                    return;
                }
                await updateCategoryService({ name, id: selectedIdCategory })
                getListOfCategories();
                SuccessAlertComponent(undefined, "Se ha actualizado la categoría satisfactoriamente");
            } else {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la categoría debe tener al menos 2 caracteres")
                    return;
                }
                await createCategoryService({ name })
                getListOfCategories();
                SuccessAlertComponent(undefined, "Se ha creado la categoría satisfactoriamente");
            }
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    const createAndUpdateNewSubCategoryService = async (name) => {
        try {
            const nameExist = listOfSubCategories.some(category => category.name.toLowerCase() === name.toLowerCase())
            const nameLength = name.length <= 1
            if (isEditingSubCategory) {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría debe tener al menos 2 caracteres")
                    return;
                }
                await updateSubCategoryService({ name, id: selectedIdSubCategory })
                getListOfSubCategory(selectedIdCategory);
                SuccessAlertComponent(undefined, "Se ha actualizado la Sub-categoría satisfactoriamente");
            } else {
                if (nameExist) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría ya existe");
                    return;
                } else if (nameLength) {
                    InformationAlertComponent(undefined, "El nombre de la Sub-categoría debe tener al menos 2 caracteres")
                    return;
                }
                await createSubCategoryService({ name, idExpenseCategory: selectedIdCategory })
                getListOfSubCategory(selectedIdCategory);
                SuccessAlertComponent(undefined, "Se ha creado la Sub-categoría satisfactoriamente");
            }
        } catch (error) {
            ErrorAlertComponent();
        }
    };

    return (
        <div className="expense-account__container">
            <div className="expense-account__content-box-category">
                <div className="expense-account__content-title">
                    <img
                        src={Assets.SharedIcons.icon_liquor_blue}
                        alt="icon_sites"
                    />
                    <span className="expense-account__title">
                        Categorías
                    </span>
                </div>
                <div className="expense-account__list-titles__content">
                    <span className="expense-account__list-titles__title-name__title-category">
                        Nombre de la categoría
                    </span>
                </div>
                <div className="expense-account__content-info">
                    {listOfCategories?.map((item, index) => {
                        return (
                            <div key={index} className={`grid-x expense-account__info-row${selectedIdCategory === item.id ? '__selected' : ''}`}>
                                <div className="expense-account__info-row__content-title">
                                    <span className="expense-account__info-row__text-site">
                                        {item?.name}
                                    </span>
                                </div>
                                <div className="expense-account__info-row__content-edit">
                                    <button
                                        className="expense-account__info-row__content-edit__edit"
                                        onClick={() => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                isAddCategory: true,
                                                isAddSubCategory: false,
                                                isEditing: true,
                                                nameOfCategory: item.name,
                                                selectedIdCategory: item.id
                                            }))
                                            getListOfSubCategory(item.id)
                                        }}
                                    >
                                        <img
                                            src={Assets.SharedIcons.icon_edit}
                                            alt="icon_edit"
                                        />
                                    </button>
                                </div>
                                <div className="expense-account__info-row__content-icon">
                                    <button
                                        className="expense-account__info-row__content-icon__icon"
                                        onClick={() => { 
                                            setState((prevState) => ({
                                                ...prevState,
                                                isAddCategory: false,
                                                nameOfCategory: ""
                                            }))
                                            getListOfSubCategory(item.id) 
                                        }}
                                    >
                                        <img
                                            src={selectedIdCategory === item.id ? Assets.SharedIcons.icon_arrow : Assets.SharedIcons.icon_blue_arrow}
                                            alt={selectedIdCategory === item.id ? "icon_arrow" : "icon_blue_arrow"}
                                        />
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {isAddCategory ? (
                    <div className="expense-account__content-input-add">
                        <Input
                            value={nameOfCategory}
                            placeholder="Nombre de la categoría"
                            className="expense-account__content-input-add__input"
                            onChange={(value) => onChangeField(value, "nameOfCategory")}
                        />
                        <div className="expense-account__content-input-add__content-button">
                            <button
                                onClick={() => { onClickButtonsCategories("cancel") }}
                                className="expense-account__content-input-add__button-cancel"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={() => { onClickButtonsCategories("accept") }}
                                className="expense-account__content-input-add__button-accept"
                            >
                                {isEditing ? "Editar" : "Crear"}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="grid-x align-middle expense-account__content-button-add">
                        <button
                            onClick={() => { onClickButtonsCategories("add") }}
                        >
                            <span className="expense-account__title-name-add">
                                Agregar nueva categoría
                            </span>
                            <img
                                src={Assets.SharedIcons.icon_add_plus}
                                alt="icon_add_plus"
                            />
                        </button>
                    </div>
                )}
            </div>
            <div className="expense-account__content-box-subCategory">
                <div className="expense-account__content-title">
                    <img
                        src={Assets.SharedIcons.icon_liquor_blue}
                        alt="icon_sites"
                    />
                    <span className="expense-account__title">
                        Sub-categorías
                    </span>
                </div>
                <div className="expense-account__list-titles__content">
                    <span className="expense-account__list-titles__title-name__title-subcategory">
                        Nombre de la sub-categoría
                    </span>
                    <div className="expense-account__list-titles__content-check">
                        <span className="expense-account__list-titles__title-name__title-subcategory">
                            Check 1
                        </span>
                        <span className="expense-account__list-titles__title-name__title-subcategory">
                            Check 2
                        </span>
                        <span className="expense-account__list-titles__title-name__title-subcategory">
                            Check 3
                        </span>
                    </div>
                </div>
                <div className="expense-account__content-info">
                    {!selectedIdCategory ? (
                        <>
                            <span className="expense-account__span-selected">
                                Selecciona una categoría
                            </span>
                        </>
                    ) : (
                        <>
                            {listOfSubCategories?.map((item, index) => {
                                return (
                                    <div key={index} className="grid-x expense-account__info-row">
                                        <div className="expense-account__info-row__content-title-subcategory">
                                            <span className="expense-account__info-row__text-site">
                                                {item.name}
                                            </span>
                                        </div>
                                        <div className="expense-account__info-row__content-check-subcategory">
                                            <Checkbox
                                                checked=""
                                                onChange={(e) => {}}
                                            />
                                            <Checkbox
                                                checked=""
                                                onChange={(e) => {}}
                                            />
                                            <Checkbox
                                                checked=""
                                                onChange={(e) => {}}
                                            />
                                        </div>
                                        <div className="expense-account__info-row__content-edit">
                                            <button
                                                className="expense-account__info-row__content-edit__edit"
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        isAddSubCategory: true,
                                                        isAddCategory: false,
                                                        isEditingSubCategory: true,
                                                        nameOfSubCategory: item.name,
                                                        selectedIdSubCategory: item.id
                                                    }))
                                                }}
                                            >
                                                <img
                                                    src={Assets.SharedIcons.icon_edit}
                                                    alt="icon_edit"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
                {isAddSubCategory ? (
                    <div className="expense-account__content-input-add-subCategory">
                        <Input
                            value={nameOfSubCategory}
                            placeholder="Nombre de la sub-categoría"
                            className="expense-account__content-input-add-subCategory__input"
                            onChange={(value) => onChangeField(value, "nameOfSubCategory")}
                        />
                        <div className="expense-account__content-input-add-subCategory__content-button">
                            <button
                                onClick={() => { onClickButtonsSubCategories("cancelSubCategory")}}
                                className="expense-account__content-input-add-subCategory__button-cancel"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={() => { onClickButtonsSubCategories("acceptSubCategory")}}
                                className="expense-account__content-input-add-subCategory__button-accept"
                            >
                                {isEditingSubCategory ? "Editar" : "Crear"}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="grid-x align-middle expense-account__content-button-add">
                        <button
                            disabled={selectedIdCategory ? false : true}
                            onClick={() => { onClickButtonsSubCategories("addSubCategory")}}
                        >
                            <span className={`${selectedIdCategory ? 'expense-account__title-name-add' : 'expense-account__title-name-add-disabled'}`}>
                                Agregar nueva Sub-categoría
                            </span>
                            <img
                                src={selectedIdCategory ? Assets.SharedIcons.icon_add_plus : Assets.SharedIcons.icon_add}
                                alt="icon_add_plus"
                            />
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}