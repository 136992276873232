// Actions
import { setStateHeadquartersReducer } from '../../../../../storage/reducers/headquarters/headquarters.actions';

// Assets
import { Assets } from '../../../../../assets';

// Components - Shared
import ErrorAlertComponent from '../../../../../shared/components/alerts/error-alert/error-alert.component';

// Libraries
import { Input, InputNumber } from 'antd';
import { NumericFormat } from 'react-number-format';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";

// Services
import { removeConceptInformation } from '../../../../../services/headquarters.services';

// Styles
import './credits-or-we-owe-inventory.component.scss';

const CreditsOrWeOweInventoryComponent = (props) => {

  const {
    // Actions
    setStateHeadquartersReducer,
    // Variables
    provisionsData,
    treasuryPortfoliosData,
    weMustData,
    changesGeneralSummary,
    generalSummary,
    totalCredits,
    isReloadGeneralSummary
  } = props;
  
  const INITIAL_STATE = {
    scrollEnd: false,
    listOfWeOwe: [],
    isCollapsedPortfolios: false,
    isCollapsedOwesTo: false,
    isCollapsedProvisions: false,
    isCollapsedCredits: false,
    portfolioList: [],
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { scrollEnd, listOfWeOwe, isCollapsedPortfolios, isCollapsedOwesTo,
    isCollapsedProvisions, isCollapsedCredits, portfolioList
  } = state;

  const formRef = useRef(null);
  dayjs.extend(utc);

  useEffect(() => {
    if (formRef.current && scrollEnd) {
      const miDiv = formRef.current;
      miDiv.scrollTop = miDiv.scrollHeight;
    }
  }, [scrollEnd, listOfWeOwe?.length]);


  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      listOfWeOwe: weMustData.data,
      portfolioList: treasuryPortfoliosData?.data
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSummary])

  const addWeOwe = () => {
    const objectInputDisabled = listOfWeOwe.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

    if (!objectInputDisabled) {
      const newArray = [
        ...listOfWeOwe,
        {
          description: '',
          value: '',
          date: generalSummary?.dateFormat,
          disabled: false
        }
      ];
      setState((prevState) => ({
        ...prevState,
        scrollEnd: true,
        listOfWeOwe: newArray
      }))
    } else {
      const newArray = listOfWeOwe.map(object => (object === objectInputDisabled ? { ...object, disabled: false } : object));
      setState((prevState) => ({
        ...prevState,
        listOfWeOwe: newArray
      }))
    }
  };

  const handleChange = (e, index, name) => {
    let value = e && e.target ? e.target.value || '' : e;
    let changesReducer = changesGeneralSummary ? [...changesGeneralSummary] : []
    let newChanges = {}
    const listOfWeOweCopy = JSON.parse(JSON.stringify(listOfWeOwe));

    listOfWeOweCopy[index][name] = value;

    const existingObjectIndex = changesReducer.findIndex(x => (x.index === index) && (x.idConcept === weMustData?.id));
    if (existingObjectIndex !== -1) {
      changesReducer[existingObjectIndex] = {
        ...changesReducer[existingObjectIndex],
        [name]: value,
        idConcept: weMustData?.id,
      };
    } else {
      newChanges = {
        ...listOfWeOweCopy[index],
        idConcept: weMustData?.id,
        index: index,
        [name]: value
      };
      changesReducer.push(newChanges);
    }

    setStateHeadquartersReducer("changesGeneralSummary", changesReducer)

    setState({
      ...state,
      listOfWeOwe: listOfWeOweCopy
    })
  };

  const totalAmount = totalCredits?.providerList?.reduce((acc, Credits) => {
    return acc + (Credits.credit - Credits.payment);
  }, 0);

  const addPortfolioTreasury = () => {
    const objectInputDisabled = portfolioList.find(object => object.hasOwnProperty("disabled") && object.disabled === true);

    if (!objectInputDisabled) {
      const newArray = [
        ...portfolioList,
        {
          description: '',
          value: '',
          date: generalSummary?.dateFormat,
          edit: true
        }
      ];
      setState((prevState) => ({
        ...prevState,
        scrollEnd: true,
        portfolioList: newArray
      }))
    } else {
      const newArray = portfolioList.map(object => (object === objectInputDisabled ? { ...object, edit: true } : object));
      setState((prevState) => ({
        ...prevState,
        portfolioList: newArray
      }))
    }
  };

  const handleChangePortfolio = (e, index, name) => {
    let value = e && e.target ? e.target.value || '' : e;
    let changesReducer = changesGeneralSummary ? [...changesGeneralSummary] : []
    let newChanges = {}
    const portfolioListCopy = JSON.parse(JSON.stringify(portfolioList));

    portfolioListCopy[index][name] = value;

    const existingObjectIndex = changesReducer.findIndex(x => (x.index === index) && (x.idConcept === treasuryPortfoliosData?.id));
    if (existingObjectIndex !== -1) {
      changesReducer[existingObjectIndex] = {
        ...changesReducer[existingObjectIndex],
        [name]: value,
        idConcept: treasuryPortfoliosData?.id,
      };
    } else {
      newChanges = {
        ...portfolioListCopy[index],
        idConcept: treasuryPortfoliosData?.id,
        index: index,
        [name]: value
      };
      changesReducer.push(newChanges);
    }

    setStateHeadquartersReducer("changesGeneralSummary", changesReducer)

    setState({
      ...state,
      portfolioList: portfolioListCopy
    })
  };

  const removeItem = async(item, listName)=>{
    try {
      if (item.id) {
        await removeConceptInformation(item?.id, generalSummary?.dateFormat); 
        setStateHeadquartersReducer("isReloadGeneralSummary", !isReloadGeneralSummary)
        
      } else {
        let idTableDeleted 
        let list = []
        switch (listName) {
            case "portfolioList":
                list = portfolioList
                idTableDeleted = treasuryPortfoliosData?.id
                break;
            case "listOfWeOwe":
                list = listOfWeOwe
                idTableDeleted = weMustData?.id
                break;
            default:
                break;
        }
        const itemIndex = list.findIndex(listItem =>
          listItem === item
        );
        if (itemIndex !== -1) {
          const updatedList = list.filter((item, index) => index !== itemIndex);
          setState((prevState) => ({
            ...prevState,
            [listName]: updatedList
          }));
          const itemEliminated = changesGeneralSummary.filter((item) => !(item.idConcept === idTableDeleted && item.index === itemIndex));
          const changesList = itemEliminated.map((item) => {
            if ((item.index >= itemIndex) && (item.idConcept === idTableDeleted)) {
              return {
                ...item,
                index: item.index - 1
              }
            }
            return item
          })
          setStateHeadquartersReducer("changesGeneralSummary", changesList)
        }
      }
    } catch (error) {
      ErrorAlertComponent()
    }
  }

  return (
    <div className='credits-or-we-owe-inventory__container'>
      <div className='credits-or-we-owe-inventory__table'>
        <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__title__content'>
          <span className='credits-or-we-owe-inventory__table__title'>Carteras con tesorería</span>
          <button
            onClick={() => setState({ ...state, isCollapsedPortfolios: !isCollapsedPortfolios })}
          >
            <img
              alt='icon_blue_arrow_up'
              className={`credits-or-we-owe-inventory__table__icon ${isCollapsedPortfolios ? 'rotate' : ''}`}
              src={Assets.SharedIcons.icon_blue_arrow_up}
            />
          </button>
        </div>
        <div className='credits-or-we-owe-inventory__table__box'>
          <div className={`credits-or-we-owe-inventory__table__content-info-scroll ${isCollapsedPortfolios ? 'collapsed' : ''}`}>
            <div className='grid-x align-middle credits-or-we-owe-inventory__table__subtitle__content'>
              <span className='small-3 credits-or-we-owe-inventory__table__subtitle'>Fecha</span>
              <span className='small-4 credits-or-we-owe-inventory__table__subtitle'>Concepto</span>
              <span className='small-4 credits-or-we-owe-inventory__table__subtitle'>Valor</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__content-info-scroll'>
              {portfolioList?.map((portfoliosWithTreasury, index) => {

                const handleKeyPress = (event) => {
                  if (event?.data === '-' && event?.target?.selectionStart === 2) {
                    if (portfoliosWithTreasury?.value?.toString()?.includes('-')) {
                      event.preventDefault();
                    }
                    return
                  }

                  if (!/^[0-9.]$/.test(event?.data)) {
                    event.preventDefault();
                  }

                  if (event?.data === '.' && (!portfoliosWithTreasury.value || portfoliosWithTreasury?.value?.toString()?.includes('.'))) {
                    event.preventDefault();
                  }
                };

                return (
                  <div key={index} className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__content-label'>
                    <Input
                      disabled
                      prefix={<></>}
                      value={portfoliosWithTreasury?.edit ? dayjs.utc(portfoliosWithTreasury?.date).format("YYYY-MM-DD") : generalSummary.dateFormat}
                      className='small-3 credits-or-we-owe-inventory__table__input'
                    />
                    <Input
                      onChange={(e) => handleChangePortfolio(e, index, "description")}
                      disabled={!portfoliosWithTreasury?.edit}
                      prefix={portfoliosWithTreasury?.edit ? <img src={Assets.SharedIcons.icon_edit} alt='icon_edit' /> : <></>}
                      value={portfoliosWithTreasury?.description}
                      className='small-4 credits-or-we-owe-inventory__table__input'
                    />
                    <div className={`grid-x small-4 ${portfoliosWithTreasury?.edit ? "credits-or-we-owe-inventory__table__input__concept-input-price" : "credits-or-we-owe-inventory__table__input-price-disabled"} `}>
                      <InputNumber
                        prefix={portfoliosWithTreasury?.edit ? <img src={Assets.SharedIcons.icon_edit} alt='icon_edit' /> : <></>}
                        disabled={!portfoliosWithTreasury?.edit}
                        value={portfoliosWithTreasury?.value}
                        controls={false}
                        formatter={(value) => {
                          if (value === undefined || value === null) return '';

                          const cleanedValue = value.replace(/\$\s?|(,*)/g, '');

                          const parts = cleanedValue.split('.');

                          let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                          let decimalPart = parts[1] ? parts[1].slice(0, 2) : '';

                          const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

                          return `$ ${formattedValue}`;
                        }}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        onChange={(floatValue) => handleChangePortfolio(floatValue, index, 'value')}
                        onBeforeInput={(event) => handleKeyPress(event)}
                        className='credits-or-we-owe-inventory__input_number'
                      />
                    </div>
                    <div className='grid-x'>
                      <button
                        disabled={!portfoliosWithTreasury?.edit}
                        className='credits-or-we-owe-inventory__button-delete'
                        onClick={() => removeItem(portfoliosWithTreasury, "portfolioList")}
                      >
                        {portfoliosWithTreasury?.edit &&

                          <img
                            className='credits-or-we-owe-inventory__button-delete__icon'
                            alt='icon_deleted_active'
                            src={Assets.SharedIcons.icon_deleted_active}
                          />
                        }
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__content--mod'>
            <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__text__content'>
              <button
                className='grid-x align-middle'
                onClick={() => addPortfolioTreasury()}
              >
                <img
                  alt='icon_income_blue'
                  src={Assets.SharedIcons.icon_income_blue}
                  className='credits-or-we-owe-inventory__table__total__icon'
                />
                <span className='credits-or-we-owe-inventory__table__total__text--mod'>Agregar concepto</span>
              </button>
              <span className='credits-or-we-owe-inventory__table__total__text'>Total</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__total__content-value'>
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                className='input-price'
                thousandsGroupStyle="thousand"
                value={treasuryPortfoliosData?.total || 0}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__real-cash-content'>
        <div className='credits-or-we-owe-inventory__real-cash-content__title__content'>
          <span className='credits-or-we-owe-inventory__real-cash-content__title'>Acumulado diferencia </span>
        </div>
        <div className='credits-or-we-owe-inventory__real-cash-content__input'>
          <NumericFormat
            disabled
            type='text'
            prefix={"$ "}
            placeholder='$ 0'
            thousandSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            className='input-price'
            thousandsGroupStyle="thousand"
            value={generalSummary?.totalSumDifference}
          />
        </div>
      </div>
      <div className='credits-or-we-owe-inventory__table'>
        <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__title__content'>
          <span className='credits-or-we-owe-inventory__table__title'>Debemos a</span>
          <button
            onClick={() => setState({ ...state, isCollapsedOwesTo: !isCollapsedOwesTo })}
          >
            <img
              alt='icon_blue_arrow_up'
              className={`credits-or-we-owe-inventory__table__icon ${isCollapsedOwesTo ? 'rotate' : ''}`}
              src={Assets.SharedIcons.icon_blue_arrow_up}
            />
          </button>
        </div>
        <div className='credits-or-we-owe-inventory__table__box'>
          <div className={`credits-or-we-owe-inventory__table__content-info-scroll--mod ${isCollapsedOwesTo ? 'collapsed' : ''}`}>
            <div className='grid-x align-middle credits-or-we-owe-inventory__table__subtitle__content'>
              <span className='small-3 credits-or-we-owe-inventory__table__subtitle'>Fecha</span>
              <span className='small-4 credits-or-we-owe-inventory__table__subtitle'>Concepto</span>
              <span className='small-4 credits-or-we-owe-inventory__table__subtitle'>Valor</span>
            </div>
            <div ref={formRef} className='credits-or-we-owe-inventory__table__content-info-scroll--mod'>
              {listOfWeOwe?.map((weOwe, index) => {

                const handleKeyPress = (event) => {
                  if (event?.data === '-' && event?.target?.selectionStart === 2) {
                    if (weOwe?.value?.toString()?.includes('-')) {
                      event.preventDefault();
                    }
                    return
                  }

                  if (!/^[0-9.]$/.test(event?.data)) {
                    event.preventDefault();
                  }

                  if (event?.data === '.' && (!weOwe.value || weOwe?.value?.toString()?.includes('.'))) {
                    event.preventDefault();
                  }
                };

                return (
                  <div key={index} className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__content-label'>
                    <Input
                      disabled
                      prefix={<></>}
                      value={weOwe?.edit ? dayjs.utc(weOwe?.date).format("YYYY-MM-DD") : generalSummary.dateFormat}
                      className='small-3 credits-or-we-owe-inventory__table__input'
                    />
                    <Input
                      value={weOwe?.description}
                      disabled={weOwe.disabled}
                      className='small-4 credits-or-we-owe-inventory__table__input__concept'
                      onChange={(e) => handleChange(e, index, "description")}
                      prefix={
                        <img
                          alt={weOwe.disabled ? 'icon_add' : 'icon_edit'}
                          src={weOwe.disabled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_edit}
                        />
                      }
                    />
                    <div className='grid-x small-4 credits-or-we-owe-inventory__table__input__concept-input-price'>
                      <InputNumber
                        prefix={<img src={Assets.SharedIcons.icon_edit} alt='icon_edit' />}
                        disabled={weOwe?.disabled}
                        value={weOwe?.value}
                        controls={false}
                        formatter={(value) => {
                          if (value === undefined || value === null) return '';

                          const cleanedValue = value.replace(/\$\s?|(,*)/g, '');

                          const parts = cleanedValue.split('.');

                          let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                          let decimalPart = parts[1] ? parts[1].slice(0, 2) : '';

                          const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

                          return `$ ${formattedValue}`;
                        }}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                        onChange={(floatValue) => handleChange(floatValue, index, 'value')}
                        onBeforeInput={(event) => handleKeyPress(event)}
                        className='credits-or-we-owe-inventory__input_number'
                      />
                    </div>
                    <div className='grid-x'>
                      <button
                        disabled={weOwe?.disabled}
                        className='credits-or-we-owe-inventory__button-delete'
                        onClick={() => removeItem(weOwe, "listOfWeOwe")}
                      >
                        {!weOwe?.disabled &&

                          <img
                            className='credits-or-we-owe-inventory__button-delete__icon'
                            alt='icon_deleted_active'
                            src={Assets.SharedIcons.icon_deleted_active}
                          />
                        }
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__content--mod'>
            <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__text__content'>
              <button
                className='grid-x align-middle'
                onClick={() => addWeOwe()}
              >
                <img
                  alt='icon_income_blue'
                  src={Assets.SharedIcons.icon_income_blue}
                  className='credits-or-we-owe-inventory__table__total__icon'
                />
                <span className='credits-or-we-owe-inventory__table__total__text--mod'>Agregar concepto</span>
              </button>
              <span className='credits-or-we-owe-inventory__table__total__text'>Total</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__total__content-value'>
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                className='input-price'
                thousandsGroupStyle="thousand"
                value={weMustData?.total || 0}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='credits-or-we-owe-inventory__table'>
        <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__title__content'>
          <span className='credits-or-we-owe-inventory__table__title'>Provisiones</span>
          <button onClick={() => setState({ ...state, isCollapsedProvisions: !isCollapsedProvisions })}>
            <img
              alt='icon_blue_arrow_up'
              className={`credits-or-we-owe-inventory__table__icon ${isCollapsedProvisions ? 'rotate' : ''}`}
              src={Assets.SharedIcons.icon_blue_arrow_up}
            />
          </button>
        </div>
        <div className='credits-or-we-owe-inventory__table__box'>
          <div className={`credits-or-we-owe-inventory__table__content-info-scroll--mod ${isCollapsedProvisions ? 'collapsed' : ''}`}>
            <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__subtitle__content'>
              <span className='credits-or-we-owe-inventory__table__subtitle'>Concepto</span>
              <span className='credits-or-we-owe-inventory__table__subtitle'>Valor</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__content-info-scroll--mod'>
              {provisionsData?.data?.map((provision, index) => {
                return (
                  <div key={index} className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__content-label'>
                    <Input
                      disabled
                      prefix={<></>}
                      value={provision?.description}
                      className='credits-or-we-owe-inventory__table__input'
                    />
                    <div className='credits-or-we-owe-inventory__table__total__content-value'>
                      <NumericFormat
                        disabled
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={provision?.value || 0}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__content'>
            <div className='grid-x align-middle justify-content-end credits-or-we-owe-inventory__table__total__text__content'>
              <span className='credits-or-we-owe-inventory__table__total__text'>Total</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__total__content-value'>
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                className='input-price'
                thousandsGroupStyle="thousand"
                value={provisionsData?.total || 0}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='credits-or-we-owe-inventory__table'>
        <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__title__content'>
          <span className='credits-or-we-owe-inventory__table__title'>Créditos</span>
          <button
            onClick={() => setState({ ...state, isCollapsedCredits: !isCollapsedCredits })}
          >
            <img
              alt='icon_blue_arrow_up'
              className={`credits-or-we-owe-inventory__table__icon ${isCollapsedCredits ? 'rotate' : ''}`}
              src={Assets.SharedIcons.icon_blue_arrow_up}
            />
          </button>
        </div>
        <div className='credits-or-we-owe-inventory__table__box'>
          <div className={`credits-or-we-owe-inventory__table__container-primary ${isCollapsedCredits ? 'collapsed' : ''}`}>
            <div className="grid-x credits-or-we-owe-inventory__table__subtitle__content">
              <div className='grid-x align-middle credits-or-we-owe-inventory__table__container-subtitle__second'>
                <span className='credits-or-we-owe-inventory__table__subtitle'>Nombre del autorizado</span>
              </div>
              <div className='grid-x align-middle credits-or-we-owe-inventory__table__container-subtitle__first'>
                <span className='credits-or-we-owe-inventory__table__subtitle'>Valor</span>
              </div>
            </div>
            <div className='credits-or-we-owe-inventory__table__content-info-scroll--mod'>
              {totalCredits?.providerList?.map((Credits, index) => {
                return (
                  <div key={index} className='grid-x align-middle credits-or-we-owe-inventory__table__content-label'>
                    <div className='credits-or-we-owe-inventory__table__input-credits'>
                      <Input
                        disabled
                        prefix={<></>}
                        placeholder='Descripción'
                        value={Credits?.name}
                        className='credits-or-we-owe-inventory__table__input-credits__input'
                      />
                    </div>
                    <div className='credits-or-we-owe-inventory__table__input-price-disabled--mod'>
                      <NumericFormat
                        disabled
                        type='text'
                        prefix={"$ "}
                        placeholder='$ 0'
                        thousandSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        className='input-price'
                        thousandsGroupStyle="thousand"
                        value={Credits.credit - Credits?.payment}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='grid-x align-middle justify-content-between credits-or-we-owe-inventory__table__total__content'>
            <div className='grid-x align-middle justify-content-end credits-or-we-owe-inventory__table__total__text__content'>
              <span className='credits-or-we-owe-inventory__table__total__text'>Total</span>
            </div>
            <div className='credits-or-we-owe-inventory__table__total__content-value'>
              <NumericFormat
                disabled
                type='text'
                prefix={"$ "}
                placeholder='$ 0'
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                className='input-price'
                value={totalAmount}
                thousandsGroupStyle="thousand"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ HeadquartersReducer }) => {
  const { generalSummary, changesGeneralSummary, totalCredits, totalCashClosingExpenses, generalAdjustment, isReloadGeneralSummary } = HeadquartersReducer;

  const handleTotal = (data) => {
    let totalPrice = 0;
    data.forEach(object => {
      totalPrice += (parseFloat(object?.tax || object?.value) ? parseFloat(object?.tax || object?.value) : 0);
    });
    totalPrice = parseFloat(totalPrice.toFixed(2));
    return totalPrice;
  };

  const dataTables = generalSummary?.dataTables;
  const orderTypes = generalSummary?.orderTypes;

  let treasuryPortfoliosData = {
    data: [],
    total: 0
  }

  if (generalSummary?.portfoliosList) {
    treasuryPortfoliosData.data = [generalSummary?.portfoliosList]
  }

  let weMustData = {
    total: 0,
    data: []
  }

  let provisionsData = {
    data: [],
    total: 0
  };

  if (orderTypes && dataTables &&  orderTypes?.length) {
    const idFilterTreasuryPortfolios = orderTypes[2]
    const idFilterWeMust = orderTypes[3]

    treasuryPortfoliosData.id = idFilterTreasuryPortfolios
    weMustData.id = idFilterWeMust

    const treasuryPortfolios = dataTables[idFilterTreasuryPortfolios]
    const weMust = dataTables[idFilterWeMust]

    if(treasuryPortfolios){
      treasuryPortfoliosData = {
        ...treasuryPortfoliosData,
        ...treasuryPortfolios,
        data: [
          ...treasuryPortfoliosData?.data,
          ...treasuryPortfolios?.data
        ]
      }
    }

    if(weMust){
      weMustData = {
        ...weMustData,
        ...weMust
      }
    }

    if (generalAdjustment?.organizeTaxesByType) {
      generalAdjustment?.organizeTaxesByType.map((item) => (
        provisionsData.data.push({
          description: item.title,
          value: handleTotal(item.data)
        })
      ));
    };

    if (generalAdjustment?.consumptionProvisions?.consumptionProvisionByMonths) {
      generalAdjustment?.consumptionProvisions?.consumptionProvisionByMonths.map((item) => (
        provisionsData.data.push({
          description: item.name,
          value: handleTotal(item.consumptionProvisionByMonths)
        })
      ));
    };

    if (generalAdjustment?.ivaProvisions?.ivaProvisionByMonths) {
      provisionsData.data.push({
        description: 'Provisión del IVA',
        value: handleTotal(generalAdjustment?.ivaProvisions?.ivaProvisionByMonths)
      })
    };

    provisionsData.total = handleTotal(provisionsData.data);
  }

  if (treasuryPortfoliosData?.data?.length > 0) {
    let total = 0
    treasuryPortfoliosData.data.forEach((item) => { total += item.value})
    treasuryPortfoliosData.total = total
  }

  return {
    provisionsData,
    treasuryPortfoliosData,
    weMustData,
    changesGeneralSummary,
    generalSummary,
    totalCredits,
    totalCashClosingExpenses,
    isReloadGeneralSummary
  };
};

const mapStateToPropsActions = {
  setStateHeadquartersReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(CreditsOrWeOweInventoryComponent);