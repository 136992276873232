import * as ExcelJS from "exceljs";
import dayjs from "dayjs";

export const dataPayment = [
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
  {
    date: "",
    conceptDescription: "",
    PaymentMethod: undefined,
    price: 0,
  },
];



export const dataExcelDetailPage = (summaryByHeadquarterArray) => {

  const workbook = new ExcelJS.Workbook();
  const startDate = summaryByHeadquarterArray[0]?.date.slice(3,10); 
  const endDate = summaryByHeadquarterArray[summaryByHeadquarterArray.length - 1]?.date.slice(3,10); 
  const headquarters = summaryByHeadquarterArray[0]?.historicalPortfolios?.name;
  const fileName = `${startDate} al ${endDate} ${headquarters}.xlsx`; 
 
  
  summaryByHeadquarterArray.forEach((summaryByHeadquarter) => {
    
    const worksheet = workbook.addWorksheet(`Reporte ${summaryByHeadquarter?.date?.slice(3,10)}`);
    
    worksheet.getRow(3).values = ['Total Abonos:', summaryByHeadquarter?.amountMonthCashReceived || 0];
    worksheet.getRow(4).values = ['Total Cobros:', summaryByHeadquarter?.amountMonthCashOutflow || 0];
    worksheet.getRow(5).values = ['Total a la fecha:', summaryByHeadquarter?.amountMonthCashOutflow - summaryByHeadquarter?.amountMonthCashReceived || 0];
    
    worksheet.getCell('A2').font = { bold: true, size: 12 }; 
    worksheet.getCell('A3').font = { bold: true, size: 12 };
    worksheet.getCell('A3').fill = { type: "pattern", pattern: "solid", fgColor: { argb: "C8FFD8" } };
    worksheet.getCell('A4').font = { bold: true, size: 12 }; 
    worksheet.getCell('A4').fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFCBC8" } };
    worksheet.getCell('A5').font = { bold: true, size: 12 }; 

    worksheet.mergeCells("A7:D7"); 
    worksheet.getCell("A7").value = "Abonos"; 
    worksheet.getCell("A7").font = { bold: true, size: 14 };
    worksheet.getCell("A7").alignment = { horizontal: "center", vertical: "middle"};
    worksheet.getCell("A7").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "C8FFD8" } };
   
    worksheet.mergeCells("F7:K7"); 
    worksheet.getCell("F7").value = "Cobros y créditos";
    worksheet.getCell("F7").font = { bold: true, size: 14 };
    worksheet.getCell("F7").alignment = { horizontal: "center", vertical: "middle" };
    worksheet.getCell("F7").fill = { type: "pattern", pattern: "solid", fgColor: { argb: "FFCBC8" } };

    worksheet.getRow(8).values = [
      "Fecha",
      "Concepto",
      "Tipo Pago",
      "Valor",
      "",
      "Fecha",
      "Concepto",
      "Categoría",
      "Sub-Categoría",
      "Tipo Pago",
      "Valor",
    ];
    worksheet.getRow(8).font = { bold: true, size: 12 };
    worksheet.getRow(8).alignment = { horizontal: "center", vertical: "middle" };

    summaryByHeadquarter?.cashReceived?.forEach((item, index) => {
      const rowIndex = index + 9; 
      worksheet.getRow(rowIndex).getCell(1).value = item?.dailyTransactionTransaction?.date ? dayjs(item?.dailyTransactionTransaction?.date).format('YYYY/MM/DD') : '';
      worksheet.getRow(rowIndex).getCell(2).value = item?.concept || ''; 
      worksheet.getRow(rowIndex).getCell(3).value = item?.paymentMethodDailyTransactionTransaction?.name || ''; 
      worksheet.getRow(rowIndex).getCell(4).value = item?.price || ''; 
    });

    summaryByHeadquarter?.cashOutflow?.forEach((item, index) => {
      const rowIndex = index + 9; 
      worksheet.getRow(rowIndex).getCell(6).value = item?.dailyTransactionTransaction?.date ? dayjs(item?.dailyTransactionTransaction?.date).format('YYYY/MM/DD') : ''; 
      worksheet.getRow(rowIndex).getCell(7).value = item?.concept || ''; 
      worksheet.getRow(rowIndex).getCell(8).value = item?.expenseSubcategoryTransaction?.expenseCategoryExpenseSubcategory?.name || ''; 
      worksheet.getRow(rowIndex).getCell(9).value = item?.expenseSubcategoryTransaction?.name || ''; 
      worksheet.getRow(rowIndex).getCell(10).value = item?.paymentMethodDailyTransactionTransaction?.name || ''; 
      worksheet.getRow(rowIndex).getCell(11).value = item?.price || ''; 
    });
  
    worksheet.getColumn(4).numFmt = '$#,##0.00'; 
    worksheet.getColumn(11).numFmt = '$#,##0.00'; 
    worksheet.getCell("B3").numFmt = '$#,##0.00'; 
    worksheet.getCell("B4").numFmt = '$#,##0.00'; 
    worksheet.getCell("B5").numFmt = '$#,##0.00'; 

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellLength = cell.value ? cell.value.toString().length : 10;
        if (cellLength > maxLength) {
          maxLength = cellLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}`;
    a.click();
    URL.revokeObjectURL(url);
  });

  return workbook;
};

